import React, { useState } from "react";

import { Backdrop, Flex, ModalContainer, ModalHeader, ModalTabs, ModalWrapper, TXPart, Tab } from "./styled";
import { MdClose } from "react-icons/md";

interface DetailingModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const tabList = [{ name: "All" }, { name: "Deposits" }, { name: "Withdrawals" }];

export default function DetailingModal({ isOpen, handleClose }: DetailingModalProps) {
  const [tabName, setTabName] = useState("all");

  return isOpen ? (
    <>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer style={{ height: "414px", width: "500px" }}>
          <ModalHeader>
            <span>Detail</span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <ModalTabs>
            {tabList.map((tab, index) => (
              <Tab
                key={`${tab.name}_${index}`}
                selected={tab.name.toLocaleLowerCase() === tabName}
                onClick={() => setTabName(tab.name.toLocaleLowerCase())}
              >
                {tab.name}
              </Tab>
            ))}
          </ModalTabs>
          {tabName === "withdrawals" ? (
            <Flex direction="column" alignItems="center" justifyContent="center" style={{ height: "100%" }}>
              <div
                style={{
                  fontSize: "20px",
                  color: "#000",
                  fontWeight: "700",
                  marginBottom: "15px",
                }}
              >
                There is nothing
              </div>
              <div
                style={{
                  fontSize: "14px",
                  color: "#000",
                  maxWidth: "230px",
                }}
              >
                You don’t have a single operation to display it.
              </div>
            </Flex>
          ) : (
            <Flex direction="column">
              <p
                style={{
                  color: "#6a7690a6",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Awaiting
              </p>
              <TXPart style={{ backgroundColor: "#edf0f7" }}>
                <div
                  style={{
                    fontSize: "15px",
                    color: "#000",
                    fontWeight: "600",
                    alignContent: "flex-start",
                  }}
                >
                  FK Wallet
                </div>
                <div>
                  <div style={{ color: "#7586a4", fontSize: "13px" }}>January 24, 2024 | 01:52 AM</div>
                  <div
                    style={{
                      color: "#000",
                      fontWeight: 600,
                      textAlign: "right",
                    }}
                  >
                    2500,00 ₽
                  </div>
                </div>
              </TXPart>
              <p
                style={{
                  color: "#6a7690a6",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                Completed
              </p>
            </Flex>
          )}
        </ModalContainer>
      </ModalWrapper>
    </>
  ) : (
    <></>
  );
}
