import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getUser, getUserBalance, updateUser } from '../../API/account';

type InitialStateType = {
    name: string;
    birthday: string;
    email: string;
    verified: boolean;
    balance: number;
    role: string;
}

const initialState: InitialStateType = {
    name: '',
    birthday: '',
    email: '',
    verified: false,
    balance: 0,
    role: ''
}

export const account = createSlice({
    name: 'account',
    initialState,
    reducers: {
    },
    extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.birthday = action.payload.birthday;
            state.balance = action.payload.balance;
            state.verified = action.payload.verified;
            state.role = action.payload.role;
        });
        builder.addCase(getUser.rejected, (state, action) => {
            state.name = '';
            state.email = '';
            state.birthday = '';
            state.balance = 0;
            state.verified = false;
            state.role = '';
        })
        builder.addCase(updateUser.fulfilled, (state, action) => {
            state.name = action.payload.name;
            state.birthday = action.payload.birthday;
        })
        builder.addCase(getUserBalance.fulfilled, (state, action) => {
            state.balance = action.payload.balance;
        })
    },
})

// export const { setLogout, cleanError } = account.actions;

export default account.reducer;