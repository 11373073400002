import styled from "styled-components";

export const PokerWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const PokerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 960px;
    margin: 15px auto;
    @media screen and (max-width: 1199px) {
        width: calc(100% - 30px);
        padding: 0 15px 15px 15px;
	}
`

export const PokerSlider = styled.div`
    width: 100%;
    position: relative;
`