import React, { useContext, useState } from "react";

import ReactCountryFlag from "react-country-flag";

import { MdClose } from "react-icons/md";

import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { UserContext } from "../../Providers/UserContext";
import { withdraw, WithdrawResponse } from "../../API/payment";

import Spinner from "../Spinner";

import {
  Backdrop,
  Flex,
  Input,
  ModalContainer,
  ModalHeader,
  ModalTabs,
  ModalWrapper,
  PaymentTools,
  Tab,
  Tool,
  WithdrawButton,
} from "./styled";

import BankImg from "../../assets/image/payment/bank.png";

interface WithdrawalModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function WithdrawalModal({ isOpen, handleClose }: WithdrawalModalProps) {
  const dispatch = useTypedDispatch();
  const { balance } = useTypedSelector((state) => state.account);
  const { id } = useTypedSelector((state) => state.auth);
  const payment_list = [
    {
      name: "Bank",
      image: BankImg,
      type: "bank",
    },
  ];
  // const tabList = [{ name: "All" }, { name: "Banks" }, { name: "Electronic money" }];
  const { currentCountry, countryCode } = useContext(UserContext);
  const [toolIndex, setToolIndex] = useState(payment_list[0]);
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDiable, setDisable] = useState(true);

  const handleWithdraw = async () => {
    setLoading(true);
    const data = {
      amount: amount,
      customer_name: id,
    };

    await dispatch(withdraw(data))
      .then((result) => {
        setLoading(false);
        window.open((result.payload as WithdrawResponse).url, "_self");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleAmount = (value: string) => {
    setAmount(value);
    if (value !== "") {
      if (Number(value) <= balance) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else {
      setDisable(true);
    }
  };

  return isOpen ? (
    <>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer style={{ width: "680px", padding: "0" }}>
          <Flex direction="row">
            <PaymentTools style={{ height: "640px" }}>
              <Flex alignItems="center" style={{ gap: "10px" }}>
                <ReactCountryFlag
                  countryCode={countryCode}
                  svg
                  cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                  title={countryCode}
                  style={{ height: "1.5em", width: "1.5em", borderRadius: "50%" }}
                />
                {/* <div style={{ width: "18px", height: "18px", backgroundColor: "#6a7690", borderRadius: "50%" }} /> */}
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: "11px", fontWeight: 400, color: "#6a769065" }}>Payment methods for the region</div>
                  <div style={{ fontSize: "11px", fontWeight: 600, color: "#6a7690" }}>{currentCountry}</div>
                </div>
              </Flex>
              {/* <ModalTabs>
                {tabList.map((tab, index) => (
                  <Tab
                    key={`${tab.name}_${index}`}
                    selected={tab.name.toLocaleLowerCase() === tabName}
                    style={{ borderRadius: "10px", padding: "8px 10px" }}
                    onClick={() => setTabName(tab.name.toLocaleLowerCase())}
                  >
                    {tab.name}
                  </Tab>
                ))}
              </ModalTabs> */}
              <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "10px", paddingTop: "10px" }}>
                {payment_list.map((value) => (
                  <Tool selected={toolIndex.type === value.type} onClick={() => setToolIndex(value)}>
                    <img src={value.image} alt={value.name} />
                  </Tool>
                ))}
              </div>
            </PaymentTools>
            <div style={{ padding: "20px", width: "100%" }}>
              <ModalHeader style={{ paddingBottom: "10px" }}>
                <span style={{ fontSize: "20px", fontWeight: 700 }}>Withdrawal</span>
                <button onClick={handleClose}>
                  <MdClose size={15} color="#0006" />
                </button>
              </ModalHeader>
              <Flex direction="column" style={{ gap: "15px" }}>
                <div style={{ color: "#070c19", fontSize: "12px", textAlign: "left" }}>
                  One withdrawal limits: <br /> 2 000 ₽ - 50 000 ₽
                </div>
                <div>
                  <Flex direction="row" alignItems="center" style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px" }}>
                    <Input
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => handleAmount(e.target.value)}
                      value={amount}
                      style={{ width: "100%", height: "43px", padding: "0 15px 0 10px" }}
                    />
                  </Flex>
                  {Number(amount) > balance && (
                    <p style={{ color: "red", margin: 0, textAlign: "left", marginLeft: "20px" }}>Not enough amount</p>
                  )}
                </div>
                {/* <Flex direction="row" alignItems="center" style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px" }}>
                  <Input type="number" placeholder="Bank card number" style={{ width: "100%", height: "43px" }} />
                  <div style={{ backgroundColor: "#888787", borderRadius: "50%", width: "5px", height: "5px" }} />
                </Flex>
                <Flex direction="row" alignItems="center" justifyContent="center" style={{ gap: "7px" }}>
                  {tempAmountList.map((temp, index) => (
                    <Flex
                      key={`${temp.amount}_${index}`}
                      style={{
                        backgroundColor: "#EDF0F7",
                        padding: "8px 10px",
                        fontSize: "12px",
                        color: "#070c1980",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <div>{temp.amount} ₽</div>
                    </Flex>
                  ))}
                </Flex> */}
                {loading ? (
                  <WithdrawButton>
                    <Spinner />
                  </WithdrawButton>
                ) : (
                  <WithdrawButton onClick={handleWithdraw} disabled={isDiable}>
                    Withdraw
                  </WithdrawButton>
                )}
              </Flex>
            </div>
          </Flex>
        </ModalContainer>
      </ModalWrapper>
    </>
  ) : (
    <></>
  );
}
