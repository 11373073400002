import styled from "styled-components";

export const MobileNavFooterContainer = styled.div`
  display: none;
  align-items: center;
  background-color: #090f1e;
  border-top: 1px solid #1f2841;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  font-size: 11px;
  /* margin-bottom: 50px; */
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    cursor: pointer;
  }
  @media screen and (max-width: 1199px) {
    display: flex;
  }
`;

export const MobileFooterContainer = styled.div`
  padding: 0 15px;
  margin-bottom: 150px;
  display: none;
  @media screen and (max-width: 1199px) {
    display: flex;
    flex-direction: column;
  }
`;

export const FooterNavigationSectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  position: relative;
`;

export const FooterNavigationSectionContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FooterNavigationSectionItems = styled.div`
  display: flex;
  div {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 121px;
    span {
      text-align: start;
      font-weight: 600;
      font-size: 10px;
      line-height: 13px;
      margin-bottom: 10px;
    }
  }
`;

export const FooterNavigationSectionTitle = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 13px;
  font-variant: small-caps;
  text-transform: lowercase;
  color: #5c72a380;
  padding-bottom: 10px;
  text-align: start;
`;

export const FooterNavigationSectionBtn = styled.div`
  background: rgba(92, 114, 163, 0.3);
  backdrop-filter: blur(13.5914px);
  border-radius: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  position: absolute;
  right: 0;
`;

export const ApplicationSectionContainer = styled.div`
  display: flex;
`;

export const MobileSupportSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(107.15deg, #1e283f 0%, #141b2e99 100%);
  padding: 10px;
  border-radius: 12px;
  margin-top: 15px;
`;

export const MobileSupportSectionMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileSupportSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const MobileSupportSectionTitle = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
`;

export const MobileSupportSectionSubTitle = styled.span`
  font-size: 8px;
  line-height: 9px;
  color: #ffffff80;
  margin-top: 3px;
`;

export const MobileSupportSectionContent = styled.div`
  display: flex;
`;

export const MobileSupportSectionBtns = styled.div`
  display: flex;
`;

export const MobileSupportSectionBtn = styled.div`
  padding: 5px 20px;
  border-radius: 290486px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  background-image: linear-gradient(107.15deg, #0095ff 0%, #0855c4 100%);
  box-shadow: 0 6px 22px #056dda4d;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.06px;
  text-shadow: 0 3px 5px rgba(9, 15, 30, 0.2);
`;

export const MobileSupportSectionIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(107.15deg, #0095ff 0%, #0855c4 100%);
  box-shadow: 0 6px 22px #056dda4d;
  border-radius: 50%;
  padding: 5px;
  margin-left: 10px;
`;

export const MobileSupportSectionContactBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4e5d864d;
  border-radius: 6px;
  margin-left: 10px;
  border: none;
`;

export const MobileSupportSectionContactKip = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(92, 114, 163, 0.5);
  display: flex;
  justify-content: space-between;
`;

export const MobileSupportSectionContactNames = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  span {
    margin-bottom: 8px;
    color: #8692aca6;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
  }
`;

export const MobileSupportSectionContactValues = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
  }
`;

export const MobileSportPromotionSectionContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const MobileFooterSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #5c72a380;
  margin: 15px 0;
`;

export const MobileSocialSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MobileSocialSectionTitle = styled.span`
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  color: #94a6cd80;
`;

export const MobileSocialSecitonBtns = styled.div`
  display: flex;
  width: 100%;
`;

export const MobileTelegramBtn = styled.button`
  background: linear-gradient(240.2deg, #0fb1d6 0%, #08c 61.34%);
  box-shadow: 0 5px 16px #4ca2f64d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 8px;
  padding: 0 5px;
  flex: 1;
  border: none;
  color: white;
  width: 25%;
  margin: 0 5px;
  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    margin: 0 5px;
    @media screen and (max-width: 434px) {
      display: none;
    }
  }
`;

export const MobileInstagramBtn = styled.button`
  background: linear-gradient(214.99deg, #7e2bf4 7.65%, #ed146e 51.93%, #ffc90c 95.29%);
  box-shadow: 0 5px 16px #ba4d654d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 8px;
  padding: 0 5px;
  flex: 1;
  border: none;
  color: white;
  width: 25%;
  margin: 0 5px;
  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    margin: 0 5px;
    @media screen and (max-width: 434px) {
      display: none;
    }
  }
`;

export const MobileFacebookBtn = styled.button`
  background: linear-gradient(135deg, #82a4e9 0%, #4267b2 100%);
  box-shadow: 0 5px 16px #7396dc4d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 8px;
  padding: 0 5px;
  flex: 1;
  border: none;
  color: white;
  width: 25%;
  margin: 0 5px;
  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    margin: 0 5px;
    @media screen and (max-width: 434px) {
      display: none;
    }
  }
`;

export const MobileXBtn = styled.button`
  background: #fff;
  box-shadow: 0 5px 16px #b9b9b94d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  border-radius: 8px;
  padding: 0 5px;
  flex: 1;
  border: none;
  width: 25%;
  margin: 0 5px;
  color: black;
  span {
    font-weight: 600;
    font-size: 10px;
    line-height: 13px;
    margin: 0 5px;
    @media screen and (max-width: 434px) {
      display: none;
    }
  }
`;
