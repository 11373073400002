import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  NavigationBodyContainer,
  RuleNavigationSupportContainer,
  RulesBody,
  RulesItems,
  RulesNavigation,
  RulesNavigationList,
  RulesTitle,
  RulesWrapper,
  SupportContainerBtns,
  SupportContainerSubTitle,
  SupportContainerTitle,
  SupportImage,
} from "./styled";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import { FaPhone } from "react-icons/fa6";
import { BsChatFill } from "react-icons/bs";
import SupportImg from "../../assets/image/img-support.png";
import UserAgreement from "./UserAgreement";
import Terms from "./Terms";

const Rules: React.FC = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [tag, setTag] = useState(1);

  const rules_content = [
    {
      title: "User Agreement",
      link: "/rules/user-agreement",
      tag: 1,
    },
    {
      title: "General Terms and Conditions",
      link: "/rules/terms-condition",
      tag: 2,
    },
    {
      title: "Privacy Policy",
      link: "/rules/privacy",
      tag: 3,
    },
    {
      title: "Rules on sports",
      link: "/rules/sports",
      tag: 4,
    },
    {
      title: "Responsible gaming",
      link: "/rules/responsible-gaming",
      tag: 5,
    },
    {
      title: "Refund policy",
      link: "/rules/refund",
      tag: 6,
    },
    {
      title: "Risk Disclosure",
      link: "/rules/risk",
      tag: 7,
    },
    {
      title: "KYC & AML",
      link: "/rules/kyc",
      tag: 8,
    },
    {
      title: "Cancellation policy",
      link: "/rules/cancellation-policy",
      tag: 9,
    },
  ];

  const handleRuleTag = (item: any) => {
    setTag(item.tag);
    navigate(item.link);
  };

  return (
    <React.Fragment>
      <Header />
      <RulesWrapper>
        <RulesNavigation>
          <RuleNavigationSupportContainer>
            <SupportContainerTitle>Support 24/7</SupportContainerTitle>
            <SupportContainerSubTitle>
              <div>Contact us if you still</div>
              <div>have any questions</div>
            </SupportContainerSubTitle>
            <SupportContainerBtns>
              <button>
                <FaPhone color="white" />
              </button>
              <button>
                <BsChatFill color="white" />
              </button>
            </SupportContainerBtns>
            <SupportImage src={SupportImg} alt="support-image" />
          </RuleNavigationSupportContainer>
          <NavigationBodyContainer>
            <RulesNavigationList>
              <RulesTitle>rules</RulesTitle>
              <RulesItems>
                {rules_content.map((item, key) => (
                  <div key={key} onClick={() => handleRuleTag(item)} className={item.tag === tag ? "rule-active" : ""}>
                    {item.title}
                  </div>
                ))}
              </RulesItems>
            </RulesNavigationList>
            <RulesNavigationList>
              <RulesTitle>promotions and bonuses</RulesTitle>
              <RulesItems>
                <div>Deposit bonus up to 500%</div>
                <div>Express bonus</div>
                <div>Up to 30% cashback in the casino</div>
                <div>Free Spins for deposit</div>
                <div>Rules of wagering bonus accounts</div>
                <div>Games rules from Spribe</div>
                <div>Jackpot up to €2,500</div>
                <div>Poker RakeBack up to 50%</div>
              </RulesItems>
            </RulesNavigationList>
          </NavigationBodyContainer>
        </RulesNavigation>
        <RulesBody>
          {tag === 1 && <UserAgreement />}
          {tag === 2 && <Terms />}
        </RulesBody>
      </RulesWrapper>
      <Footer />
    </React.Fragment>
  );
};

export default Rules;
