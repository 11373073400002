import styled from "styled-components";

export const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(92deg, #1e283f, rgba(20, 27, 46, 0.6));
  border-radius: 10px;
  position: sticky;
  height: 47px;
  padding: 0 10px;
  top: 10px;
  z-index: 2;
`;

export const HeaderItemsLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const HeaderItemsRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const LogoBtn = styled.img`
  height: 30px;
  cursor: pointer;
  padding-right: 10px;
`;

export const ProvideMenuBar = styled.ul`
  display: flex;
  align-content: center;
  justify-content: center;
  height: 47px;
  gap: 20px;
  list-style: none;
  padding-left: 15px;
  li {
    cursor: pointer;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
  }
`;

export const LoginBtn = styled.div`
  background-image: linear-gradient(0deg, transparent 0%, transparent 100%);
  background-color: #1f2841;
  border: none;
  padding: 7px 15px;
  color: white;
  border-radius: 7px;
  font-size: 15px;
  cursor: pointer;
  &:hover {
    background-color: #202735;
    opacity: 0.8;
  }
`;

export const RegisterBtn = styled.button`
  background-image: linear-gradient(70deg, #31bc69 -8%, #089e4e 96%);
  border-style: none;
  padding: 7px 10px;
  color: white;
  border-radius: 7px;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const RegisterBtnIcon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-image: linear-gradient(210deg, rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0.06) 48%, #fff 133%);
`;

export const HeaderBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-start;
  label {
    color: #ffffff80;
    line-height: 1.3;
    font-size: 10px;
  }
  span {
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;
  }
`;

export const DropDownTrigger = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: #1f2841;
  border-radius: 20px 8px 8px 20px;
  padding: 3px 8px 3px 3px;
  position: relative;
`;

export const HeaderUserAvatar = styled.div`
  align-items: center;
  border-radius: 50%;
  background-color: #2c3756;
  display: flex;
  font-size: 10px;
  justify-content: center;
  height: 29px;
  width: 29px;
`;

export const HeaderDepositBtn = styled.button`
  background-image: linear-gradient(70deg, #31bc69 -8%, #089e4e 96%);
  border-style: none;
  color: #fff;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s;
  border-radius: 8px;
  padding: 10px 40px;
`;

export const DropDownContent = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 9px 15px #0000001a;
  background-color: #fff;
  position: absolute;
  border-radius: 8px;
  margin-top: 260px;
  left: -15px;
  padding: 0.5rem 0;
  z-index: 10;
  div {
    white-space: nowrap;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 12px;
    padding: 8px 15px;
    color: #444752;
    &:hover {
      background-color: #94949442;
    }
  }
`;
