import React from 'react';

export const Bonus: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" aria-hidden="true" role="img" className="icon icon-profile-percentage sm BoxButton_icon_T35Ci BoxButton_icon_T35Ci">
            <defs>
                <linearGradient id="percentage_svg__a" x1="0%" y1="99.314%" y2=".686%">
                    <stop offset="0%" stopColor="#0095FF"></stop>
                    <stop offset="100%" stopColor="#0854C3"></stop>
                </linearGradient>
            </defs>
            <path fill="url(#percentage_svg__a)" d="M11.195 1.796l-.103.136.087.032a4.807 4.807 0 00-1.528-.332l-.247-.006a4.808 4.808 0 00-4.78 4.316l-.01.154.09-.02a4.807 4.807 0 00-1.465.546l-.218.132a4.808 4.808 0 00-1.684 6.204l.079.141.064-.065a4.807 4.807 0 00-.938 1.252l-.111.23a4.808 4.808 0 001.937 6.129l.143.078.018-.091a4.807 4.807 0 00-.111 1.56l.029.245a4.808 4.808 0 004.952 4.116l.156-.01-.035-.087c.173.495.426.959.75 1.372l.167.204a4.808 4.808 0 006.368.782l.138-.102-.076-.055a4.83 4.83 0 001.373.75l.243.078a4.808 4.808 0 005.79-2.79l.059-.153-.094-.005c.522.048 1.05.01 1.56-.112l.239-.063a4.808 4.808 0 003.37-5.488l-.032-.152-.083.046a4.807 4.807 0 001.252-.937l.184-.202a4.808 4.808 0 00-.132-6.4l-.126-.13-.044.084c.26-.456.445-.95.546-1.466l.042-.244A4.808 4.808 0 0025.42 6.13l-.15-.035.007.095a4.807 4.807 0 00-.332-1.529l-.1-.234a4.808 4.808 0 00-5.901-2.55l-.153.056.057.075a4.807 4.807 0 00-7.654-.21zm4.727 1.644c.15.107.28.238.387.387a3.003 3.003 0 003.333 1.118l.215-.076a1.682 1.682 0 012.3 1.478 3.003 3.003 0 002.211 2.746l.21.049a1.682 1.682 0 011.219 2.323l-.083.164a3.003 3.003 0 00.368 3.496l.159.164a1.682 1.682 0 01-.232 2.613l-.157.093a3.003 3.003 0 00-1.58 3.152l.043.212a1.682 1.682 0 01-1.607 2.073l-.183-.007c-1.3-.12-2.52.614-3.032 1.798l-.079.201a1.682 1.682 0 01-2.623.77 3.003 3.003 0 00-3.502-.14l-.196.14a1.682 1.682 0 01-2.553-.6l-.07-.17a3.003 3.003 0 00-2.895-2.011l-.216.012a1.682 1.682 0 01-1.823-1.886l.033-.18a3.003 3.003 0 00-1.348-3.257l-.188-.107a1.682 1.682 0 01-.515-2.572l.125-.134a3.003 3.003 0 00.633-3.458l-.106-.202a1.682 1.682 0 01.958-2.442l.177-.045a3.003 3.003 0 002.403-2.58l.019-.215a1.682 1.682 0 012.126-1.536l.174.058c1.21.48 2.583.124 3.408-.861l.14-.18a1.682 1.682 0 012.347-.388z"></path>
            <path fill="#FFF" d="M11.57 21.244c.436 0 .698-.167.992-.619l3.332-5.07 3-4.53c.158-.239.253-.461.253-.699 0-.547-.42-.936-.984-.936-.531 0-.777.198-1.07.642l-3.19 4.88-3.127 4.697c-.142.223-.238.413-.238.73 0 .564.516.905 1.032.905zm-1.389-5.3c1.793 0 2.944-1.238 2.944-3.301 0-2.087-1.166-3.253-2.944-3.253-1.77 0-2.943 1.166-2.943 3.26 0 2.072 1.158 3.294 2.943 3.294zm0-1.675c-.389 0-.674-.428-.674-1.618 0-1.206.278-1.587.674-1.587.397 0 .675.38.675 1.587 0 1.19-.278 1.618-.675 1.618zm9.371 6.975c1.793 0 2.944-1.238 2.944-3.3 0-2.088-1.167-3.254-2.944-3.254-1.77 0-2.944 1.166-2.944 3.261 0 2.071 1.159 3.293 2.944 3.293zm0-1.674c-.389 0-.674-.429-.674-1.619 0-1.206.277-1.587.674-1.587s.674.381.674 1.587c0 1.19-.277 1.619-.674 1.619z"></path>
        </svg>
    );
};
