import React from "react";
import { RuleBodyText } from "./styled";

const Terms: React.FC = () => {
    return (
        <React.Fragment>
            <RuleBodyText>
                <ol>
                    <b><li>Main provisions</li></b>
                    <ol>
                        <li>
                            Bet - agreement related to a win as executed between the client
                            and the betting company according to the set rules, in this case,
                            the outcome of that agreement shall depend on the event concerning
                            which it is unknown whether it will occur or not. Bets from the
                            clients shall be accepted subject to the conditions of the betting
                            company.
                        </li>
                        <li>
                            Outcome - a result of the event (events) on which the bet was
                            made.
                        </li>
                        <li>
                            Client - a person who makes a bet in the betting company on the
                            outcome of the event.
                        </li>
                        <li>
                            Line - the aggregate of the events, any possible outcomes of those
                            events, coefficients of the possible outcomes of those events,
                            their date and time after which the betting company shall stop the
                            acceptance of bets on the outcomes of the mentioned events.
                        </li>
                        <li>
                            Bet cancellation - an event upon which no settlement and payment
                            of win shall be performed. In case of a “bet cancellation”,
                            according to conditions hereof, the deal between the organizer and
                            the client shall be considered as not executed and a money refund
                            shall be performed to such bet.
                        </li>
                        <li>
                            Regular time - match length following the rules to conduct the
                            competition in this kind of sports, which shall include the time
                            added by the official. Regular time shall not include the
                            additional time, overtime, series of penalties, penalty shots,
                            etc.
                        </li>
                        <li>
                            All bets shall be calculated based on information provided by the
                            processing center.
                        </li>
                        <li>
                            In case of winning accrual, the betting participant shall be
                            obliged to check the correctness of the accrued winning and in
                            case of non-consent with the accrued winning to respectively
                            notify the betting company has indicated in the application their
                            account number, date, time, event, amount, chosen outcome of the
                            event, coefficient as well as the reasons of non-consent with the
                            accrued winning. All claims related to the accrued winnings shall
                            be accepted within 10 (ten) days.
                        </li>
                        <li>
                            A bet made by the client on any particular outcome of the event
                            shall be winning if all outcomes were correctly predicted
                            mentioned in such bet.
                        </li>
                        <li>
                            Conditions of acceptance of bets (coefficients, head starts,
                            totals, limitations of the maximum bet, etc.) may be amended after
                            any bet, whereas the conditions of the earlier made bets shall
                            remain unchanged. Before the execution of the agreement, the
                            client must clarify all amendments to the current line
                        </li>
                        <li>
                            Bets made on the events the outcome of which shall be known at the
                            moment of the bet may be calculated with the coefficient «1».
                        </li>
                        <li>
                            According to this Agreement, in case of discrepancies arising
                            between the client (participant of the agreement) and the betting
                            company regarding the issues related to fulfillment and
                            implementation of the betting agreement executed between the
                            client (participant of the agreement) and the betting company,
                            including the issues of winning payment, the outcome of events,
                            winning coefficients, any issues of other essential conditions of
                            the executed agreement as well as regarding the issues of
                            acceptance of the agreement as void or ineffective, the parties
                            shall accept the obligatory complaint procedure to settle the
                            arisen discrepancies (prejudicial procedure).
                        </li>
                        <li>
                            The claim shall be made within 10 (ten) days from the day when the
                            person has learned or should have learned about the breach of
                            their right. All arguments and facts must be attached to the
                            claim, confirming and substantiating the made claims. In case of
                            the absence of reasons sufficient to substantiate the stated
                            claims, the claim shall be subject to return without review.
                        </li>
                        <li>
                            In case of staff errors while accepting the bets (obvious wrong
                            types in the offered list of events, incompliance of coefficients
                            in the line and the bet, etc.), in case of deviation from these
                            Rules while accepting the bet as well as in case of other
                            arguments confirming the incorrectness of bets the betting company
                            shall remain entitled to declare the bets made on such conditions
                            as invalid. Payment upon such bets shall be made with coefficient
                            «1».
                        </li>
                        <li>
                            In case of suspicion in the unsportsmanlike nature of matches, we
                            shall be entitled to block the bets on the sports event until the
                            conclusion of the international organization and to acknowledge
                            the bets as invalid in case the fact of the unsportsmanlike
                            competition shall be confirmed. Payment upon such bets shall be
                            made with coefficient «1». The administration shall not be obliged
                            to provide the betting participants with any proof or conclusions.
                        </li>
                        <li>
                            In case of any obvious error in our coefficients, such bet shall
                            be calculated following the final result on the actual coefficient
                            complying with that marker.
                        </li>
                        <li>
                            The betting company shall not accept any claims regarding various
                            readings in case of transliteration (translation from foreign
                            languages) of the team names, player names, venues of
                            competitions. All information given in the tournament name shall
                            have an auxiliary nature. Any possible errors in that information
                            shall not be the reason to repay the bets.
                        </li>
                        <li>
                            We shall remain entitled to close the betting account and to
                            cancel all bets made on that account if it will be determined that
                            the betting participant at the moment of the bets possessed the
                            information about the result of the event. In this case, the
                            administration of the betting company shall not be obliged to
                            provide the betting participants with any proof or conclusions.
                        </li>
                    </ol>
                    <b><li>Main rules of bet acceptance</li></b>
                    <ol>
                        <li>
                            The betting company shall be entitled to limit the maximum bet,
                            coefficients on any particular events as well as limit or increase
                            the maximum bet, coefficients to any particular client without
                            notification and explanation of reasons.
                        </li>
                        <li>
                            Acceptance of any repeated bets on the same outcome or combination
                            of outcomes from the same player may be limited upon the decision of
                            the betting company.
                        </li>
                        <li>
                            The bet shall be considered as accepted after registration thereof on the server and issue of the online confirmation. No registered bets shall be subject to cancellation or amendment.
                        </li>
                        <li>
                            The bets shall be only accepted in the amount which shall not exceed the current balance on the client account. After the bet registration, the bet amount shall be debited from the account. After the bet calculation, the winning amount shall be credited to the client's account.
                        </li>
                        <li>
                            The bets shall be accepted before the beginning of the event; the date, time of the beginning of the events and any related comments, mentioned in the line, shall be approximate. In case if for any reason the bet was made after the actual beginning of the event such bet shall be void. An exception shall be the bet on the live events, i.e. bets made in the course of the match. Such bets shall be valid until the end of the match.
                        </li>
                        <li>
                            Bets LINE and LIVE shall not be edited or deleted except for any special cases provided for by these rules.
                        </li>
                        <li>
                            In case if a bet shall be subject to cancellation then regarding a single bet the repayment of money funds shall be performed. In the expresses, in case of cancellation of a bet related to any one or several events the winning calculation on those events shall not be performed.
                        </li>
                        <li>
                            In case of any wrong calculation of bets (e.g., because of any erroneously entered results) such bets shall be recalculated. In this case, the bets made between the wrong calculation and recalculation shall be valid. In case if after recalculation the player's balance shall be negative they may not make any bets until the account will be replenished.
                        </li>
                        <li>
                            All sports events shall be considered as postponed and canceled only in case of availability of information from the official documents of organizations conducting the sports events, official sites of the sporting federations, sites of the sports clubs and other sources of the sports information and based on those data the sports events shall be adjusted mentioned in the line.
                        </li>
                        <li>
                            The bet shall be subject to cancellation if the client has knowingly misled the staff (employees of the betting company) having provided them with false information and demands regarding the acceptance of bets, payment of winning, result of the event and other information and demands having such nature. The above-mentioned cases shall be applied also to any minors below 18 years of age as well as their parents.
                        </li>
                        <li>
                            A bet shall be subject to cancellation if the bet was made on a known outcome (the event occurred but the result was not reflected in the system).
                        </li>
                    </ol>
                    <b><li>Types of bets</li></b>
                    <ol>
                        <li>
                            Single – a bet on a certain outcome of an event. The winning on the single bet shall be equal to the product of the bet amount and coefficient set for this outcome.
                        </li>
                        <li>
                            Express – a bet on several independent outcomes of events. The winning on the express shall be equal to the product of the bet amount and coefficients of all outcomes constituting the express. Losing on one of the outcomes of express shall mean loss of the whole express.
                        </li>
                    </ol>
                    <b><li>Limitations of inclusion of certain outcomes of the event</li></b>
                </ol>
            </RuleBodyText>
        </React.Fragment>
    );
};

export default Terms;
