import React, { useContext, useEffect, useState } from "react";

import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

import { UserContext } from "../../Providers/UserContext";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { deposit, DepositResponse } from "../../API/payment";

import Header from "../../layout/Header";
import Spinner from "../../components/Spinner";
import { CryptoPaymentContainer, Flex, Input, Tool, WithdrawButton } from "../../components/Modals/styled";

import VoucherImg from "../../assets/image/payment/voucher.png";
import oneVoucherImg from "../../assets/image/payment/1Voucher.png";
import kazangVoucherImg from "../../assets/image/payment/Kazang-voucher.png";
import ottVoucherImg from "../../assets/image/payment/OTT-Voucher.png";
import eftImg from "../../assets/image/payment/eft.png";
import creditcardImg from "../../assets/image/payment/credit_card.png";
import snapscanImg from "../../assets/image/payment/snap-scan.png";
import zapperImg from "../../assets/image/payment/zapper.png";
import ETHImg from "../../assets/image/payment/ETH.webp";
import BTCImg from "../../assets/image/payment/BTC.webp";
import USDTImg from "../../assets/image/payment/USDT.webp";
import USDCImg from "../../assets/image/payment/USDC.webp";
import CryptoPayment from "../../components/CryptoPayment";

const Deposit: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { id } = useTypedSelector((state) => state.auth);
  const [toolIndex, setToolIndex] = useState({
    name: "Cryptocurrency",
    image: "",
    type: "crypto",
  });
  const { currentCountry, countryCode } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [isCrypto, setIsCrypto] = useState(true);
  const [amount, setAmount] = useState("");
  const [coin, setCoin] = useState("");
  const [isShow, setShow] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");

  const payment_list = [
    {
      name: "Bluvoucher",
      image: VoucherImg,
      type: "bluvoucher",
    },
    {
      name: "1Voucher ",
      image: oneVoucherImg,
      type: "onevoucher",
    },
    {
      name: "Kazang Voucher",
      image: kazangVoucherImg,
      type: "kazangvoucher",
    },
    {
      name: "OTT Vouchers",
      image: ottVoucherImg,
      type: "ott_voucher",
    },
    {
      name: "EFT",
      image: eftImg,
      type: "eft",
    },
    {
      name: "Credit Card",
      image: creditcardImg,
      type: "credit_card",
    },
    {
      name: "Snapscan",
      image: snapscanImg,
      type: "snapscan",
    },
    {
      name: "Zapper",
      image: zapperImg,
      type: "zapper",
    },
  ];

  const crypto_payment_list = [
    {
      name: "ETH",
      icon: ETHImg,
    },
    {
      name: "Bitcoin",
      icon: BTCImg,
    },
    {
      name: "Tether",
      icon: USDTImg,
    },
    {
      name: "USDC",
      icon: USDCImg,
    },
  ];

  useEffect(() => {
    window.open(paymentUrl, "_self");
  }, [paymentUrl]);

  const handleDeposit = async () => {
    setLoading(true);
    const data = {
      amount: Number(amount),
      payment_type: toolIndex.type,
      customer_reference: id,
      merchant_reference: id,
    };

    await dispatch(deposit(data))
      .then((result) => {
        setLoading(false);
        window.open((result.payload as DepositResponse).url, "_self");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleAmount = (value: string) => {
    setAmount(value);
  };

  const handleContinue = () => {
    setShow(true);
  };

  const handleCrypto = (value: string) => {
    if (amount) {
      setCoin(value);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Flex direction="column" style={{ padding: "15px", textAlign: "left" }}>
        <Flex direction="row" alignItems="center" style={{ marginBottom: "8px" }}>
          {isShow ? (
            <Flex
              direction="row"
              alignItems="center"
              style={{ gap: "6px", margin: "5px 0", cursor: "pointer", flex: 1 }}
              onClick={() => setShow(false)}
            >
              <AiOutlineLeft style={{ width: "13px", height: "13px" }} />
              <div style={{ fontSize: "13px" }}>Back</div>
            </Flex>
          ) : (
            <Flex
              direction="row"
              alignItems="center"
              style={{ gap: "6px", margin: "5px 0", cursor: "pointer", flex: 1 }}
              onClick={() => navigate("/")}
            >
              <AiOutlineLeft style={{ width: "13px", height: "13px" }} />
              <div style={{ fontSize: "13px" }}>Main</div>
            </Flex>
          )}
          <div style={{ fontSize: "14px", fontWeight: 600 }}>Deposit</div>
          <div style={{ flex: 1 }} />
        </Flex>
        <div style={{ backgroundColor: "#f7f7f7", padding: "20px", borderRadius: "16px", height: "420px" }}>
          {isShow ? (
            coin !== "" ? (
              <CryptoPayment coin={coin} amount={amount} handleBack={() => setCoin("")} setPaymentUrl={setPaymentUrl} />
            ) : (
              <CryptoPaymentContainer>
                <Flex
                  direction="row"
                  alignItems="center"
                  style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px", marginTop: "10px" }}
                >
                  <Input
                    type="number"
                    placeholder="Amount"
                    onChange={(e) => handleAmount(e.target.value)}
                    value={amount}
                    style={{ width: "100%", height: "43px", padding: "0 15px 0 10px" }}
                  />
                </Flex>
                <nav>
                  {crypto_payment_list.map((item, key) => (
                    <div key={key} onClick={() => handleCrypto(item.name)}>
                      <img src={item.icon} alt={item.name} />
                      <span>{item.name}</span>
                    </div>
                  ))}
                </nav>
              </CryptoPaymentContainer>
            )
          ) : (
            <>
              <Flex alignItems="center" style={{ gap: "10px", marginBottom: "15px" }}>
                <div style={{ width: "18px", height: "18px", borderRadius: "50%" }}>
                  <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                    title={countryCode}
                    style={{ height: "1.5em", width: "1.5em", borderRadius: "50%" }}
                  />
                </div>
                <Flex direction="column" style={{ textAlign: "left", gap: "5px" }}>
                  <div style={{ fontSize: "11px", fontWeight: 400, color: "#6a769065" }}>Payment methods for the region</div>
                  <div style={{ fontSize: "11px", fontWeight: 600, color: "#6a7690" }}>{currentCountry}</div>
                </Flex>
              </Flex>
              <div style={{ fontSize: "9px", color: "#090f1e", opacity: 0.3 }}>All methods</div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: "10px", paddingTop: "10px" }}>
                  <Tool
                    selected={isCrypto}
                    onClick={() => {
                      setIsCrypto(true);
                      setToolIndex({
                        name: "Cryptocurrency",
                        image: "",
                        type: "crypto",
                      });
                    }}
                  >
                    <div>
                      <nav>
                        {crypto_payment_list.map((item, key) => (
                          <img key={key} src={item.icon} alt={item.name} />
                        ))}
                      </nav>
                      <span>Cryptocurrency</span>
                    </div>
                  </Tool>
                  {payment_list.map((value, key) => (
                    <Tool
                      selected={toolIndex.type === value.type}
                      onClick={() => {
                        setToolIndex(value);
                        setIsCrypto(false);
                        setAmount("");
                      }}
                    >
                      <img src={value.image} alt={value.name} />
                    </Tool>
                  ))}
                </div>
                {(toolIndex.type === "eft" ||
                  toolIndex.type === "credit_card" ||
                  toolIndex.type === "snapscan" ||
                  toolIndex.type === "zapper") && (
                  <Flex
                    direction="row"
                    alignItems="center"
                    style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px", marginTop: "15px" }}
                  >
                    <Input
                      type="number"
                      placeholder="Amount"
                      onChange={(e) => handleAmount(e.target.value)}
                      value={amount}
                      style={{ width: "100%", height: "43px", padding: "0 15px 0 10px" }}
                    />
                  </Flex>
                )}
                <Flex direction="column" style={{ paddingTop: "15px" }}>
                  {toolIndex.type !== "crypto" ? (
                    loading ? (
                      <WithdrawButton>
                        <Spinner />
                      </WithdrawButton>
                    ) : (
                      <WithdrawButton onClick={handleDeposit}>Deposit</WithdrawButton>
                    )
                  ) : loading ? (
                    <WithdrawButton>
                      <Spinner />
                    </WithdrawButton>
                  ) : (
                    <WithdrawButton onClick={handleContinue}>Continue</WithdrawButton>
                  )}
                </Flex>
              </div>
            </>
          )}
        </div>
      </Flex>
    </React.Fragment>
  );
};

export default Deposit;
