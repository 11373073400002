import React from 'react';

export const Support: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-profile-support sm BoxButton_icon_T35Ci BoxButton_icon_T35Ci">
            <defs>
                <linearGradient x1="0%" y1="100%" y2="0%" id="support_svg__a">
                    <stop stopColor="#0095FF" offset="0%"></stop>
                    <stop stopColor="#0854C3" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path d="M21.243 10.038c1.619.612 2.782 2.166 2.757 4.026 0 2.165-1.619 3.974-3.693 4.254-.809 2.65-3.186 4.612-6.044 4.765a1.066 1.066 0 01-1.062.917h-2.402a1.083 1.083 0 01-1.088-1.096v-1.248c0-.611.48-1.095 1.088-1.095H13.2c.531 0 .961.382 1.062.866a5.073 5.073 0 004.274-3.058h-.329a.737.737 0 01-.733-.738v-7.134c0-.408.329-.739.733-.739h.708v-.459c0-3.847-3.085-6.955-6.904-6.955-3.818 0-6.904 3.108-6.904 6.955v.459h.709c.404 0 .733.331.733.739v7.134a.737.737 0 01-.733.738H4.274C1.922 18.37 0 16.433 0 14.064a4.317 4.317 0 012.782-4.026V9.3c0-5.12 4.147-9.3 9.23-9.3 5.084 0 9.231 4.178 9.231 9.3v.738zM4 12a2 2 0 100 4h.5v-4H4zm16 0h-.5v4h.5a2 2 0 100-4z" fill="url(#support_svg__a)"></path>
            <path d="M10.596 9.783c0-.407.177-.815.506-1.095a1.37 1.37 0 011.163-.306c.557.102 1.037.56 1.113 1.121a1.395 1.395 0 01-.96 1.605c-.81.255-1.341 1.02-1.341 1.911v.79a.91.91 0 101.82 0v-.79c0-.076.051-.153.076-.153a3.283 3.283 0 002.2-3.694c-.227-1.325-1.29-2.395-2.604-2.624a3.136 3.136 0 00-2.656.713A3.254 3.254 0 008.75 9.758c0 .51.405.917.91.917a.88.88 0 00.936-.892z" fill="#FFF"></path>
            <circle fill="#FFF" cx="12.012" cy="16.51" r="1"></circle>
        </svg>
    );
};
