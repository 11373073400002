import React from 'react';
import Header from '../../layout/Header';
import { BonusWrapper, PermanentBonus, PermanentBonusTitle, PermantentBonusCard } from './styled';
import Footer from '../../layout/Footer';
import PromotionBonus from '../../components/PromotionBonus';

const Bonus: React.FC = () => {
    return (
        <React.Fragment>
            <Header />
            <BonusWrapper>
                <PermanentBonus>
                    <PermanentBonusTitle>Permanent bonuses</PermanentBonusTitle>
                    <PermantentBonusCard></PermantentBonusCard>
                </PermanentBonus>
                <PromotionBonus />
            </BonusWrapper>
            <Footer />
        </React.Fragment>
    )
}

export default Bonus