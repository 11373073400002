import styled from "styled-components";

type Props = {
  width?: string;
};

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  margin-bottom: 70px;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const FooterLogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  @media screen and (max-width: 1199px) {
    margin-bottom: 15px;
  }
`;

export const FooterLogoSeparator = styled.div`
  flex: 1;
  margin-left: 20px;
  margin-right: 18px;
  height: 1px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 9.92%, rgba(0, 0, 0, 0) 100%);
`;

export const FooterNavSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterLeftSection = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 50px;
`;

export const ContactSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 150px;
`;

export const ContactSectionTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`;

export const ContactSectionSubtitle = styled.span`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: #8692aca6;
  margin-top: 8px;
  text-align: start;
`;

export const ContactSectionPhone = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
  cursor: pointer;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-image: linear-gradient(144deg, #0095ff -27%, #0855c4 151%, #0855c4 151%);
    font-size: 13px;
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  a {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #ffffffe6;
    margin-left: 10px;
    white-space: nowrap;
    text-decoration: none;
  }
`;

export const NavigationSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const NavigationSectionTitle = styled.span`
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #5a647a;
  margin-bottom: 40px;
`;

export const NavigationSectionContent = styled.div`
  display: table-row-group;
`;

export const NavigationSectionContentRow = styled.div`
  display: table-row;
  /* gap: 20px; */
`;

export const NavigationSectionContentCellName = styled.div`
  display: table-cell;
  color: #8692aca6;
  font-size: 13px;
  line-height: 16px;
  padding-right: 20px;
  text-align: start;
  padding-bottom: 10px;
`;

export const NavigationSectionContentCell = styled.div`
  display: table-cell;
  font-size: 13px;
  line-height: 16px;
  text-align: start;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InformationItem = styled.span`
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  text-align: start;
`;

export const CategoryList = styled.div`
  display: flex;
  gap: 20px;
`;

export const CategoryListColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  span {
    font-size: 13px;
    line-height: 16px;
    text-align: start;
    color: white;
    cursor: pointer;
  }
`;

export const FooterRightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const FooterMobileAppContainer = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  width: ${(props) => (props.width ? props.width : "170px")};
  margin: 0 5px;
`;

export const FooterMobileAppContent = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const FooterMobileAppText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  text-align: start;
  span {
    font-size: 8px;
    line-height: 10px;
    color: #ffffff80;
  }
  p {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    margin: 0;
  }
`;

export const FooterMiddleSeparator = styled.div`
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 29.12%, rgba(255, 255, 255, 0) 100%);
  width: 82%;
  height: 1px;
  margin: 40px 0 23px;
`;

export const SocialList = styled.ul`
  display: flex;
  gap: 10px;
  margin: 0;
  padding: 0;
`;

export const TelegramItem = styled.li`
  background: linear-gradient(240.2deg, #0fb1d6 0%, #08c 61.34%);
  box-shadow: 0 5px 16px #4ca2f64d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
`;

export const InstagramItem = styled.div`
  background: linear-gradient(214.99deg, #7e2bf4 7.65%, #ed146e 51.93%, #ffc90c 95.29%);
  box-shadow: 0 5px 16px #ba4d654d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
`;

export const FackbookItem = styled.div`
  background: linear-gradient(135deg, #82a4e9 0%, #4267b2 100%);
  box-shadow: 0 5px 16px #7396dc4d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
`;

export const TwitterItem = styled.div`
  background: #fff;
  box-shadow: 0 5px 16px #b9b9b94d;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
`;

export const SportPromotions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
`;

export const ChangeDeviceSection = styled.div`
  display: flex;
  gap: 10px;
`;

export const FooterPromotionSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const FooterBtns = styled.button`
  border: none;
  background: linear-gradient(92.58deg, #1e283f 0%, rgba(20, 27, 46, 0.6) 99.71%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
`;

export const FooterSeparator = styled.div`
  background-color: #fff3;
  width: 100%;
  height: 1px;
  margin: 23px 0;
`;
