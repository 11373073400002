import React from "react";
import Header from "../../layout/Header";
import { PokerContainer, PokerSlider, PokerWrapper } from "./styled";
import ImageSlider from "../../components/ImageSlider";
import Footer from "../../layout/Footer";
import PromotionBonus from "../../components/PromotionBonus";
import MobileFooter from "../../layout/MobileFooter";
import MobileNavFooter from "../../layout/MobileFooter/MobileNavFooter";

const Poker: React.FC = () => {
  return (
    <React.Fragment>
      <PokerWrapper>
        <Header />
        <PokerContainer>
          <PokerSlider>
            <ImageSlider />
          </PokerSlider>
          <PromotionBonus />
        </PokerContainer>
        <MobileFooter />
        <MobileNavFooter />
        <Footer />
      </PokerWrapper>
    </React.Fragment>
  );
};

export default Poker;
