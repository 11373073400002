import React from "react";
import { HistoryBody, HistoryContainer, HistoryHeader, HistoryTitle, HistoryWrapper } from "./styled";
import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import MobileNavFooter from "../../layout/MobileFooter/MobileNavFooter";

const History: React.FC = () => {
  return (
    <React.Fragment>
      <HistoryWrapper>
        <Header />
        <HistoryContainer>
          <HistoryHeader>
            <HistoryTitle>Bets history</HistoryTitle>
            <></>
          </HistoryHeader>
          <HistoryBody>You have no bets history</HistoryBody>
        </HistoryContainer>
        <Footer />
        <MobileNavFooter />
      </HistoryWrapper>
    </React.Fragment>
  );
};

export default History;
