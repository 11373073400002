import React from 'react';

export const Check: React.FC = () => {
    return (
        <svg data-v-09a2bd8c="" width="19" height="19" viewBox="2 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-ok-with-underlay sm bonus-checked-icon bonus-checked-icon">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.95 18.185a2.224 2.224 0 00-2.9 0c-1.382 1.187-3.528.299-3.666-1.52a2.224 2.224 0 00-2.05-2.049c-1.817-.138-2.706-2.284-1.519-3.667a2.224 2.224 0 000-2.898c-1.187-1.383-.298-3.53 1.52-3.667a2.224 2.224 0 002.049-2.05C6.522.517 8.668-.372 10.05.815a2.224 2.224 0 002.898 0c1.383-1.187 3.53-.298 3.667 1.52a2.224 2.224 0 002.05 2.049c1.817.138 2.706 2.284 1.519 3.667a2.224 2.224 0 000 2.898c1.187 1.383.299 3.53-1.52 3.667a2.224 2.224 0 00-2.049 2.05c-.138 1.817-2.284 2.706-3.667 1.519z" fill="url(#ok-with-underlay_svg__paint0_linear)"></path>
            <path d="M8 9.668L10.535 12 15 8" filter="url(#ok-with-underlay_svg__filter0_d)" stroke="#fff" strokeWidth="1.69" strokeLinecap="round" strokeLinejoin="round" fill="transparent"></path>
            <defs>
                <linearGradient id="ok-with-underlay_svg__paint0_linear" x1="17.5" y1="4.5" x2="6" y2="16" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#089E4E"></stop>
                    <stop offset="1" stopColor="#31BC69"></stop>
                </linearGradient>
                <filter id="ok-with-underlay_svg__filter0_d" x=".155" y="3.155" width="22.69" height="19.69" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
                    <feOffset dy="3"></feOffset>
                    <feGaussianBlur stdDeviation="3.5"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"></feBlend>
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"></feBlend>
                </filter>
            </defs>
        </svg>
    );
};
