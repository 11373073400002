import React, { useEffect, useState } from "react";
import {
  Backdrop,
  ChangePasswordText,
  CustomInput,
  FromContainer,
  ModalContainer,
  ModalHeader,
  ModalSubTitle,
  ModalWrapper,
  PasswordChangeBtn,
  SaveBtn,
} from "./styled";
import { MdClose, MdInfo } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useTypedSelector, useTypedDispatch } from "../../store/typeHooks";
import "react-phone-number-input/style.css";
import { Tooltip } from "react-tooltip";
import { updateUser } from "../../API/account";
import { toast } from "react-toastify";

interface SettingProps {
  isOpen: boolean;
  handleClose: () => void;
  handleEmail: () => void;
}

interface BonusProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface ConfirmProps {
  isOpen: boolean;
  handleClose: () => void;
}

interface TelegramProps {
  isOpen: boolean;
  handleClose: () => void;
}

export const SettingModal: React.FC<SettingProps> = ({ isOpen, handleClose, handleEmail }) => {
  const dispatch = useTypedDispatch();
  const { verified, id } = useTypedSelector((state) => state.auth);
  const { email, name, birthday } = useTypedSelector((state) => state.account);
  const [userinfo, setUserinfo] = useState({
    email: "",
    name: "",
    birthday: "",
  });
  const [isChange, setChange] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const handleEmailConfrim = () => {
    handleEmail();
  };

  const handleSave = () => {
    if (isChange) {
      if (!currentPassword) {
        toast.warn("Input current password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      if (newPassword.length < 4) {
        toast.warn("Password must be at least 4 letters", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      if (newPassword !== repeatPassword) {
        toast.warn("Password different", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      const data = {
        id,
        name: userinfo.name,
        birthday: userinfo.birthday,
        currentPassword,
        newPassword,
      };
      dispatch(updateUser(data));
    } else {
      const data = {
        id,
        name: userinfo.name,
        birthday: userinfo.birthday,
      };
      dispatch(updateUser(data));
    }
  };

  useEffect(() => {
    if (name || email || birthday) {
      setUserinfo({
        email,
        name,
        birthday,
      });
    }
  }, [name]);

  return isOpen ? (
    <React.Fragment>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader>
            <span>Settings</span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <ModalSubTitle>Personal data</ModalSubTitle>
          <FromContainer>
            <CustomInput>
              <label>Name</label>
              <input value={userinfo.name} onChange={(e) => setUserinfo({ ...userinfo, name: e.target.value })} />
            </CustomInput>
            <CustomInput>
              <label>Birthday</label>
              <input type="date" value={userinfo.birthday} onChange={(e) => setUserinfo({ ...userinfo, birthday: e.target.value })} />
            </CustomInput>
            <CustomInput flag={!verified} onClick={handleEmailConfrim}>
              <label>Email</label>
              <input value={userinfo.email} onChange={(e) => setUserinfo({ ...userinfo, email: e.target.value })} />
              {!verified && <MdInfo color="#FF9E00" size={20} id="email-confirm" />}
            </CustomInput>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "10px",
              }}
            >
              {!isChange ? (
                <CustomInput>
                  <label>Password</label>
                  <input value={"password"} type="password" />
                </CustomInput>
              ) : (
                <ChangePasswordText>Change password</ChangePasswordText>
              )}
              {!isChange ? (
                <PasswordChangeBtn onClick={() => setChange(true)}>Change</PasswordChangeBtn>
              ) : (
                <PasswordChangeBtn onClick={() => setChange(false)}>Cancel</PasswordChangeBtn>
              )}
            </div>
            {isChange && (
              <>
                <CustomInput>
                  <input
                    placeholder="Current Password"
                    type={visible ? "text" : "password"}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  {visible ? (
                    <AiOutlineEyeInvisible onClick={() => setVisible(false)} />
                  ) : (
                    <AiOutlineEye onClick={() => setVisible(true)} />
                  )}
                </CustomInput>
                <CustomInput>
                  <input
                    placeholder="Enter new Password"
                    type={visible ? "text" : "password"}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  {visible ? (
                    <AiOutlineEyeInvisible onClick={() => setVisible(false)} />
                  ) : (
                    <AiOutlineEye onClick={() => setVisible(true)} />
                  )}
                </CustomInput>
                <CustomInput>
                  <input
                    placeholder="Repeat new Password"
                    type={visible ? "text" : "password"}
                    value={repeatPassword}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                  />
                  {visible ? (
                    <AiOutlineEyeInvisible onClick={() => setVisible(false)} />
                  ) : (
                    <AiOutlineEye onClick={() => setVisible(true)} />
                  )}
                </CustomInput>
              </>
            )}
            <SaveBtn onClick={handleSave}>Save</SaveBtn>
          </FromContainer>
        </ModalContainer>
      </ModalWrapper>
      <Tooltip anchorSelect="#email-confirm" place="bottom" style={{ zIndex: 20 }}>
        E-mail is not confirmed
      </Tooltip>
    </React.Fragment>
  ) : (
    <></>
  );
};

export const BonusModal: React.FC<BonusProps> = ({ isOpen, handleClose }) => {
  return isOpen ? (
    <React.Fragment>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader>
            <span>Bonus Code</span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <ModalSubTitle>Active Bonus Code and receive money on balance</ModalSubTitle>
          <FromContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "10px",
              }}
            >
              <CustomInput>
                <input placeholder="Bonus Code" />
              </CustomInput>
              <PasswordChangeBtn>Activate</PasswordChangeBtn>
            </div>
          </FromContainer>
        </ModalContainer>
      </ModalWrapper>
    </React.Fragment>
  ) : (
    <></>
  );
};

export const ConfirmModal: React.FC<ConfirmProps> = ({ isOpen, handleClose }) => {
  const { email } = useTypedSelector((state) => state.auth);
  return isOpen ? (
    <React.Fragment>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader>
            <span>Confirm your email</span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <ModalSubTitle>This is required to make sure you can deposit, store and withdraw your money safely</ModalSubTitle>
          <FromContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "10px",
              }}
            >
              <CustomInput>
                <input value={email} />
              </CustomInput>
            </div>
            <PasswordChangeBtn>Confirm</PasswordChangeBtn>
          </FromContainer>
        </ModalContainer>
      </ModalWrapper>
    </React.Fragment>
  ) : (
    <></>
  );
};

export const TelegramModal: React.FC<TelegramProps> = ({ isOpen, handleClose }) => {
  return isOpen ? (
    <React.Fragment>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader>
            <span></span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <FromContainer>
            <span style={{color: "#000"}}>Click the button below to log in using your Telegram account:</span>
            <script
              async
              src="https://telegram.org/js/telegram-widget.js?22"
              data-telegram-login="slotAuthBot"
              data-size="medium"
              data-auth-url="https://slot-backend-c9f4948d8bc0.herokuapp.com/auth/telegram"
              data-request-access="write"
            ></script>
          </FromContainer>
        </ModalContainer>
      </ModalWrapper>
    </React.Fragment>
  ) : (
    <></>
  );
};
