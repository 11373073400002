import React from 'react';

export const Heat: React.FC = () => {
    return (
        <svg data-v-dcb2f604="" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" aria-hidden="true" role="img" className="icon icon-casino-category-hot sm hotpopular-button-icon hotpopular-button-icon">
            <defs>
                <linearGradient id="hot_svg__b" x1="77.691%" x2="-43.982%" y1="0%" y2="181.227%">
                    <stop offset="0%" stopColor="#FB2099"></stop>
                    <stop offset="100%" stopColor="#303595"></stop>
                </linearGradient>
                <filter id="hot_svg__a" width="381%" height="381%" x="-140.5%" y="-140.5%" filterUnits="objectBoundingBox">
                    <feOffset in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="7.5"></feGaussianBlur>
                    <feColorMatrix in="shadowBlurOuter1" result="shadowMatrixOuter1" values="0 0 0 0 0.816203773 0 0 0 0 0.00751322834 0 0 0 0 0.106696017 0 0 0 1 0"></feColorMatrix>
                    <feMerge>
                        <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
                        <feMergeNode in="SourceGraphic"></feMergeNode>
                    </feMerge>
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd" filter="url(#hot_svg__a)">
                <path fill="none" d="M0 0h21v21H0z"></path>
                <path fill="#FFF" fillRule="nonzero" d="M10.183 16.625c-.094 0-5.013-1.094-4.932-5.336.078-4.032 5.69-7.789 5.69-7.789s-.12 1.788.207 2.598c.328.81.62 1.247 1.357 1.854.092-.295.298-.607.712-1.022.533 1.086 1.376 2.458 2.003 3.297 1.376 2.687.037 5.135-3.61 6.398-.964-.046 3.506-3.216-.67-5.455.723 2.24-3.223 3.23-.757 5.455z"></path>
                <path fill="url(#hot_svg__b)" d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm0-1.75a8.75 8.75 0 110-17.5 8.75 8.75 0 010 17.5z"></path>
            </g>
        </svg>
    );
};