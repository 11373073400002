import React from 'react';
import { PromotionLandingBonus, PromotionBonusTitle, PromotionBonusCards } from './styled';

const PromotionBonus: React.FC = () => {
    return (
        <React.Fragment>
            <PromotionLandingBonus>
                <PromotionBonusTitle>
                    <span>Offers and promotions</span>
                    <label>23</label>
                </PromotionBonusTitle>
                <PromotionBonusCards>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </PromotionBonusCards>
            </PromotionLandingBonus>
        </React.Fragment>
    )
}

export default PromotionBonus;