import styled from "styled-components";

export const CryptoPaymentItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CryptoPaymentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #edf0f7;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 5px;
  text-align: left;
  cursor: pointer;
  div {
    p {
      color: #090f1e;
      font-size: 13px;
      font-weight: 500;
      margin: 0 !important;
    }
    span {
      color: "#6a7690";
      font-size: 12px;
    }
  }
`;

export const CryptoPaymentBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
`;

export const BackBtn = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: #f3f6fb;
`;

export const ContinueBtn = styled.button`
  width: 100%;
  height: 100%;
  background-image: linear-gradient(70deg, #31bc69 -8%, #089e4e 96%);
  border: none;
  box-shadow: 0 6px 18px #0ea1514d;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: #97aee133;
  padding: 12px;
  border-radius: 15px;
  max-width: 204px;
  nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #070c19;
    margin-bottom: 10px;
    img {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }
    span {
      font-size: 12px;
      margin-left: 3px;
    }
  }
  div {
    padding: 20px;
    margin-bottom: 10px;
    background: #fff;
    border-radius: 15px;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #070c19;
    font-weight: 600;
    margin-bottom: 10px;
  }
  span {
    font-size: 15px;
    line-height: 15px;
    word-break: break-all;
    color: #858687;
  }
`;

export const CopyBtn = styled.button`
  background: linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%);
  box-shadow: 0 6px 18px #1178df4d;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  font-size: 16px;
  /* line-height: 18px; */
  padding: 15px;
  margin-top: 20px;
  color: white;
  font-weight: 600;
  width: 100%;
`;

export const MinDepositAmount = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e9ebef;
  border-radius: 15px;
  box-shadow: 0 4px 8px -2px #0e10110d;
  color: #070c19;
  margin-top: 15px;
  padding: 15px;
  span {
    font-size: 12px;
    /* line-height: 16px; */
    margin-bottom: 4px;
    opacity: 0.5;
    text-align: center;
  }
  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
      margin: 0;
      margin-left: 5px;
    }
  }
  div {
    background-color: #dc8b441a;
    border-radius: 10px;
    padding: 10px;
    span {
      color: #dc8b44;
      opacity: 1;
    }
  }
`;
