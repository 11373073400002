import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MdClose } from "react-icons/md";

import { IframeBody, IframeHeader, MobileIframeWrapper } from "./styled";

const Play = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <React.Fragment>
      <MobileIframeWrapper style={{ position: "relative" }}>
        <IframeHeader>
          <p>{location.state.gameName}</p>
          <MdClose size={30} style={{ cursor: "pointer" }} onClick={() => navigate("/casino")} />
        </IframeHeader>
        <IframeBody>
          <iframe
            style={{ width: "100%", height: "100%" }}
            scrolling="no"
            frameBorder="no"
            title="gameIframe"
            id="gameIFrame"
            src={location.state.url}
          />
        </IframeBody>
      </MobileIframeWrapper>
    </React.Fragment>
  );
};

export default Play;
