import React from "react";

export const Warning: React.FC = () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      className="icon icon-attention sm CryptoDepositPaymentInfo_iconAttention_ASJAg CryptoDepositPaymentInfo_iconAttention_ASJAg"
    >
      <path
        fill="#DC8B44"
        d="M14.233 9.973L9.12 1.413A1.855 1.855 0 007.5.5c-.678 0-1.282.339-1.62.913L.766 9.973a1.868 1.868 0 00-.015 1.9c.34.605.958.958 1.636.958h10.224c.693 0 1.297-.353 1.636-.957a1.869 1.869 0 00-.015-1.9zm-6.733.855a.792.792 0 01-.796-.796c0-.442.354-.795.796-.795.442 0 .796.353.796.795a.792.792 0 01-.796.796zm.855-6.055l-.28 3.285a.574.574 0 01-.62.516.567.567 0 01-.515-.516l-.28-3.285a.858.858 0 011.71-.148c-.015.044-.015.103-.015.148z"
      ></path>
    </svg>
  );
};
