import styled from "styled-components";

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 15px;
`;

export const MainWallet = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  background: linear-gradient(107.15deg, #1e283f, rgba(20, 27, 46, 0.6));
  border-radius: 10px;
  gap: 15px;
`;

export const ProfileHeaderBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-left: 15px;
  ::before {
    background: linear-gradient(to top, #00ff86, #00a858);
    box-shadow: 2px 0 5px #00ff864d;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    border-radius: 10px;
  }
  label {
    font-size: 11px;
    line-height: 1.36;
    color: #94a6cd;
  }
  span {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const ProfileHeaderBtns = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
`;

export const ProfileWithdrawBtn = styled.button`
  background-color: #97aee11c;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 15px;
  cursor: pointer;
`;

export const ProfileDepositBtn = styled.button`
  background-image: linear-gradient(79deg, #31bc69 -8%, #089e4e 93%);
  box-shadow: 0 6px 18px #0ea1514d;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 15px;
  cursor: pointer;
`;

export const ProfileBonusBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-left: 15px;
  ::before {
    background: linear-gradient(to top, #ff7b99, #c04df8);
    box-shadow: 2px 0 5px #ff7b994d;
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    border-radius: 10px;
  }
  label {
    font-size: 11px;
    line-height: 1.36;
    color: #94a6cd;
  }
  span {
    font-size: 17px;
    font-weight: 500;
  }
`;

export const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProfileBoxBtn = styled.div`
  background-color: #131a2e;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  cursor: pointer;
`;

export const BoxBtnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoxtBtnHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  gap: 10px;
  span {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.15px;
    overflow-wrap: break-word;
  }
  label {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.24px;
    color: #5c72a3;
  }
`;

export const BoxBtnBody = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  background-color: #1e273d;
  border-radius: 12px;
  input {
    font-size: 14px;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    background-color: #1e273d;
    border: none;
    border-radius: 12px;
    outline: none;
    color: white;
  }

  span {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color: #096dff;
    padding: 0 10px;
    background-color: #1e273d;
    border-radius: 0 12px 12px 0;
  }
`;

export const ExitBtn = styled.div`
  display: flex;
  flex-direction: row;
  span {
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
  }
`;
