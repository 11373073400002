import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { ErrorType } from ".";
import { toast } from "react-toastify";

type ProfileType = {
  name: string;
  birthday: string;
  id: string;
  newPassword?: string;
  currentPassword?: string;
};

type GetUserInfoType = {
  name: string;
  birthday: string;
  email: string;
  verified: boolean;
  balance: number;
  role: string;
  id: string;
  provider: string;
  socialID: string;
  _id: string;
};

type GetBalanceReponse = {
  balance: number;
};

// type GetBalanceRequest = {
//   accountid: string;
//   device: string;
//   gamemodel: string;
//   gamesessionid: string;
//   gametype: string;
//   gpgameid: string;
//   gpid: string;
//   nogsgameid: string;
//   product: string;
// }

export const routes = {
  update: "/api/users/update",
  get: "/api/users/userinfo",
  balance: "/api/users/getBalance",
};

export const updateUser = createAsyncThunk<
  GetUserInfoType,
  ProfileType,
  {
    rejectValue: ErrorType;
  }
>("/account/update", async (updateData, { rejectWithValue }) => {
  try {
    const response = await api.put(`${routes.update}`, updateData);
    toast.success("Success update user", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const getUser = createAsyncThunk<
  GetUserInfoType,
  String,
  {
    rejectValue: ErrorType;
  }
>("/auth/getUser", async (token, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.get}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (e: any) {
    localStorage.removeItem("success");
    localStorage.removeItem("token");
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const getUserBalance = createAsyncThunk<
  GetBalanceReponse,
  String,
  {
    rejectValue: ErrorType;
  }
>("/account/balance", async (token, { rejectWithValue }) => {
  try {
    const response = await api.get(`${routes.balance}`, {
      headers: {
        Authorization: `${token}`,
      },
    });
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
