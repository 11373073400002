import styled from "styled-components";

export const HistoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const HistoryContainer = styled.div`
    margin: 0 15px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: #141b2e;
    padding: 15px;
    gap: 15px;
`

export const HistoryHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const HistoryTitle = styled.span`
    font-size: 25px;
    font-weight: 700;
    letter-spacing: .3px;
`

export const HistoryBody = styled.div`
    
`