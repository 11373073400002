import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTypedSelector, useTypedDispatch } from "../../store/typeHooks";
import { setLogout } from "../../store/slices/auth";
import {
  BoxBtnBody,
  BoxBtnHeader,
  BoxtBtnHeaderTitle,
  ExitBtn,
  MainWallet,
  ProfileBonusBalance,
  ProfileBoxBtn,
  ProfileContainer,
  ProfileDepositBtn,
  ProfileHeaderBalance,
  ProfileHeaderBtns,
  ProfileSection,
  ProfileWithdrawBtn,
  ProfileWrapper,
} from "./styled";
import Header from "../../layout/Header";
import MobileFooter from "../../layout/MobileFooter";
import MobileNavFooter from "../../layout/MobileFooter/MobileNavFooter";
import { MobileFooterSeparator } from "../../layout/MobileFooter/styled";
import { Support } from "../../assets/svg/support";
import { Bonus } from "../../assets/svg/bonus";
import { Favourite } from "../../assets/svg/favourite";
import { History } from "../../assets/svg/history";
import { Detail } from "../../assets/svg/detail";
import { Setting } from "../../assets/svg/setting";
import { IoExitOutline } from "react-icons/io5";

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { balance } = useTypedSelector((state) => state.account);
  // const [balance, setBalance] = useState(0);
  const [isActive, setActive] = useState(false);

  const profileSection = [
    {
      title: "Support",
      content: "If you have any question, we will help you",
      tag: "support",
      svg: <Support />,
    },
    {
      title: "Bonus Code",
      content: "Activate Bonus Code and receive money on balance",
      tag: "bonus",
      svg: <Bonus />,
    },
    {
      title: "Favourites",
      content: "Events, tournaments and games you add to favorites",
      tag: "favourites",
      svg: <Favourite />,
    },
    {
      title: "Betting history",
      content: "All your recent bids",
      tag: "history",
      svg: <History />,
    },
    {
      title: "Details",
      content: "All operations that affected the change in balance",
      tag: "detail",
      svg: <Detail />,
    },
    {
      title: "Settings",
      content: "Ability to hide balance and edit personal data",
      tag: "setting",
      svg: <Setting />,
    },
  ];

  const handleInput = (e: any) => {
    if (e.target.value.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const handleLogOut = () => {
    localStorage.removeItem("success");
    localStorage.removeItem("token");
    navigate("/");
    dispatch(setLogout());
  };

  const handleProfileSection = (tag: string) => {
    if (tag === "support") {
    }
    if (tag === "favourite") {
    }
    if (tag === "history") {
      navigate("/history");
    }
    if (tag === "detail") {
      navigate("/detailing");
    }
    if (tag === "setting") {
      navigate("/profile/edit");
    }
    if (tag === "favourites") {
      navigate("/favourites");
    }
  };

  return (
    <React.Fragment>
      <ProfileWrapper>
        <Header />
        <ProfileContainer>
          <MainWallet>
            <ProfileHeaderBalance>
              <label>Main wallet</label>
              <span>
                {balance &&
                  balance.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
              </span>
            </ProfileHeaderBalance>
            <ProfileHeaderBtns>
              <ProfileWithdrawBtn onClick={() => navigate("/money/withdrawal")}>Withdrawal</ProfileWithdrawBtn>
              <ProfileDepositBtn onClick={() => navigate("/deposit")}>Deposit</ProfileDepositBtn>
            </ProfileHeaderBtns>
            <ProfileBonusBalance>
              <label>Bonus casino</label>
              <span>0.00</span>
            </ProfileBonusBalance>
          </MainWallet>
          <MobileFooterSeparator />
          <ProfileSection>
            {profileSection.map((item, index) => (
              <ProfileBoxBtn key={index} onClick={() => handleProfileSection(item.tag)}>
                <BoxBtnHeader>
                  <BoxtBtnHeaderTitle>
                    <span>{item.title}</span>
                    <label>{item.content}</label>
                  </BoxtBtnHeaderTitle>
                  {item.svg}
                </BoxBtnHeader>
                {item.tag === "bonus" && (
                  <BoxBtnBody>
                    <input placeholder="Bonus Code" onChange={(e) => handleInput(e)} />
                    {isActive && <span>Activate</span>}
                  </BoxBtnBody>
                )}
              </ProfileBoxBtn>
            ))}
          </ProfileSection>
          <MobileFooterSeparator />
          <ExitBtn onClick={handleLogOut}>
            <IoExitOutline size={20} />
            <span>Exit</span>
          </ExitBtn>
        </ProfileContainer>
        <MobileFooter />
        <MobileNavFooter />
      </ProfileWrapper>
    </React.Fragment>
  );
};

export default Profile;
