import React, { useState } from "react";
import { useTypedSelector } from "../../store/typeHooks";
import {
  Backdrop,
  CustomInput,
  ForgotPasswordText,
  FromContainer,
  LoginButton,
  ModalContainer,
  ModalHeader,
  ModalSubTitle,
  ModalWrapper,
  RegisterAdv,
  SocialLoginContainer,
  SocialBtnList,
} from "./styled";
import { MdClose } from "react-icons/md";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import GoogleSvg from "../../assets/svg/social/google.svg";
import MailruSvg from "../../assets/svg/social/mailru.svg";
import YandexSvg from "../../assets/svg/social/yandex.svg";
import XSvg from "../../assets/svg/social/x.svg";
import TelegramSvg from "../../assets/svg/social/telegram.svg";
import { googleAuthService, mailruAuthService, twitterAuthService, yandexAuthService } from "../../service";
import Spinner from "../Spinner";

interface LoginProps {
  isOpen: boolean;
  handleClose: () => void;
  handleLogin: () => void;
  handleRegister: () => void;
  userData: {
    email: string;
    password: string;
  };
  setUserData: (type: string, value: any) => void;
}

export const LoginModal: React.FC<LoginProps> = ({ isOpen, handleClose, handleLogin, handleRegister, userData, setUserData }) => {
  const { loading } = useTypedSelector((state) => state.auth);
  const [visible, setVisible] = useState(true);

  const social_list = [
    {
      title: "google",
      url: GoogleSvg,
    },
    {
      title: "mailru",
      url: MailruSvg,
    },
    {
      title: "yandex",
      url: YandexSvg,
    },
    {
      title: "twitter",
      url: XSvg,
    },
    {
      title: "telegram",
      url: TelegramSvg,
    },
  ];

  const register = () => {
    handleClose();
    handleRegister();
  };

  const handleSocialLogin = (provider: string) => {
    if (provider === "google") {
      googleAuthService();
    }
    if (provider === "mailru") {
      mailruAuthService();
    }
    if (provider === "yandex") {
      yandexAuthService();
    }
    if (provider === "twitter") {
      twitterAuthService();
    }
    if (provider === "telegram") {
      window.open("https://majestic-kringle-d4ea11.netlify.app/", "_self");
    }
  };

  return isOpen ? (
    <React.Fragment>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader>
            <span>Login</span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <ModalSubTitle>Welcome to 1win</ModalSubTitle>
          <SocialLoginContainer>
            <SocialBtnList>
              {social_list.map((item, key) => (
                <li
                  key={key}
                  className={`${item.title}`}
                  onClick={() => handleSocialLogin(item.title)}
                  // flag={socialProvider === item.title ? true : false}
                >
                  <img src={item.url} alt={item.title} />
                </li>
              ))}
            </SocialBtnList>
          </SocialLoginContainer>
          <FromContainer>
            <CustomInput>
              <input placeholder="E-mail" value={userData.email} onChange={(e) => setUserData("email", e.target.value)} />
            </CustomInput>
            <CustomInput>
              <input
                placeholder="Password"
                type={visible ? "password" : "text"}
                value={userData.password}
                onChange={(e) => setUserData("password", e.target.value)}
              />
              {visible ? <AiOutlineEye onClick={() => setVisible(false)} /> : <AiOutlineEyeInvisible onClick={() => setVisible(true)} />}
            </CustomInput>
            <ForgotPasswordText>Forgot password?</ForgotPasswordText>
            {loading ? (
              <LoginButton>
                <Spinner />
              </LoginButton>
            ) : (
              <LoginButton onClick={handleLogin}>Login</LoginButton>
            )}
            <RegisterAdv>
              Still no account?
              <span onClick={register}>Register</span>
            </RegisterAdv>
          </FromContainer>
        </ModalContainer>
      </ModalWrapper>
    </React.Fragment>
  ) : (
    <></>
  );
};
