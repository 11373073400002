// export const BASE_ROUTE = 'http://localhost:5000'
export const BASE_ROUTE = process.env.REACT_APP_BACKEND_DOMAIN;

export const API_ROUTES = {
  login: BASE_ROUTE + "/api/users/signin",
  register: BASE_ROUTE + "/api/users/signup",
  googleAuth: BASE_ROUTE + "/auth/google",
  mailruAuth: BASE_ROUTE + "/auth/mailru",
  yandexAuth: BASE_ROUTE + "/auth/yandex",
  googleAuthSuccess: BASE_ROUTE + "/auth/google/success",
  googleLogin: BASE_ROUTE + "/login/google",
  twitterAuth: BASE_ROUTE + "/auth/twitter",
};
