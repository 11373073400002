import React from "react";
import { QRCodeCanvas } from "qrcode.react";

import { CopyBtn, MinDepositAmount, QRCodeContainer } from "./styled";

// icon
import { Warning } from "../../assets/svg/warning";

// coin image
import ETHImg from "../../assets/image/payment/ETH.webp";
import BTCImg from "../../assets/image/payment/BTC.webp";
import USDTImg from "../../assets/image/payment/USDT.webp";
import USDCImg from "../../assets/image/payment/USDC.webp";

interface PaymentProps {
  coin: string;
  qrData: string;
  network: string;
}

const Payment = ({ coin, qrData, network }: PaymentProps) => {
  return (
    <React.Fragment>
      <QRCodeContainer>
        {coin === "Bitcoin" && (
          <nav>
            <img src={BTCImg} alt="btc" />
            <p>
              BTC
              <span>Bitcoin Blockchain</span>
            </p>
          </nav>
        )}
        {coin === "ETH" && (
          <nav>
            <img src={ETHImg} alt="eth" />
            <p>
              {coin}
              <span>ERC-20</span>
            </p>
          </nav>
        )}
        {coin === "USDC" && (
          <nav>
            <img src={USDCImg} alt="usdc" />
            <p>
              USDC
              <span>Solana</span>
            </p>
          </nav>
        )}
        {coin === "Tether" && (
          <nav>
            <img src={USDTImg} alt="usdc" />
            <p>
              USDT
              <span>{network}</span>
            </p>
          </nav>
        )}

        <div>
          <QRCodeCanvas value={qrData} size={140} bgColor={"#ffffff"} fgColor={"#000000"} />
        </div>
        <p>Top-up address</p>
        <span>bc1q2cqmzhk06sjpk7hev0nr53h7cdceupk0u9y4sr</span>
      </QRCodeContainer>
      <CopyBtn>Copy address</CopyBtn>
      <MinDepositAmount>
        <span>Minimum deposit</span>
        <nav>
          <Warning />
          {coin === "Bitcoin" && <p>0.00023 BTC</p>}
          {coin === "ETH" && <p>0.0045 ETH</p>}
          {coin === "USDC" && <p>10 USDC</p>}
          {coin === "Tether" && <p>10 USDT</p>}
        </nav>
        <div>
          <span>Please note that if you deposit less than the current limit, your funds will be permanently lost</span>
        </div>
      </MinDepositAmount>
    </React.Fragment>
  );
};

export default Payment;
