import styled from "styled-components";

export const AddEventButton = styled.div`
  font-size: 14px;
  margin-top: 30px;
  padding: 8px 15px;
  background-image: linear-gradient(to bottom right, #0095ff, #0855c4);
  box-shadow: 0 6px 22px #056dda4d;
  border-radius: 12px;
  cursor: pointer;
`;
