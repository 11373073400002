import styled from "styled-components";

export const MobileHeaderContainer = styled.div`
  display: none;
  flex-direction: column;
  padding: 0 15px;
  @media screen and (max-width: 1199px) {
    display: flex;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  svg {
    cursor: pointer;
  }
`;

export const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const HeaderBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  label {
    color: #94a6cdb3;
    font-size: 11px;
    line-height: 1.2;
    margin-bottom: 1px;
    white-space: nowrap;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
`;

export const MobileDepositBtn = styled.div`
  border-radius: 8px;
  box-shadow: 0 6px 18px #0ea1514d;
  background-image: linear-gradient(248deg, #009746 100%, #38c172 0%);
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.15px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`;

export const MobileProfileBtn = styled.div`
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  font-size: 12px;
  background-image: linear-gradient(0deg, transparent 0%, transparent 100%);
  background-color: #141b2e;
`;

export const HeaderBtns = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  button {
    border-radius: 10px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    color: white;
    padding: 10px 0;
  }
`;

export const MobileLoginBtn = styled.button`
  background-image: linear-gradient(to bottom right, #0095ff, #0855c4);
  box-shadow: 0 6px 22px #056dda4d;
  flex-grow: 0.67;
  margin-right: 10px;
  &:hover {
    background-color: #1564d8;
    box-shadow: 0 2px 14px #1564d8;
  }
`;

export const MobileRegisterBtn = styled.button`
  background-image: linear-gradient(80deg, #31bc69 -8%, #089e4e 93%);
  box-shadow: 0 6px 18px #0ea1514d;
  overflow: hidden;
  position: relative;
  transform: translateZ(0);
  flex-grow: 1;
`;

export const MobileNavigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  section {
    align-items: center;
    display: flex;
    overflow-x: scroll;
    margin-top: 10px;
  }
  a {
    align-items: center;
    border-radius: 290486px;
    color: #fff;
    display: flex;
    flex: none;
    font-size: 14px;
    font-weight: 600;
    height: 24px;
    line-height: 1;
    padding: 2px 10px;
    white-space: nowrap;
  }
`;

export const DrawerMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const DrawerMenuHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DrawerMenuUser = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const DrawerMenuAvatar = styled.div`
  border-radius: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1f2841;
  width: 45px;
  height: 45px;
`;

export const DrawerUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 20px;
    font-weight: 700;
  }
  label {
    line-height: 25px;
    font-size: 11px;
    color: #94a6cd;
  }
`;

export const DrawerMenuBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const DrawerMenuList = styled.div`
  display: flex;
  flex-direction: column;
  div {
    padding: 9px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    div {
      width: 30px;
      background-color: #7388b6;
    }
    span {
      font-size: 14px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
`;

export const DrawerMenuListDivier = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, rgba(211, 222, 255, 0.2), rgba(235, 241, 255, 0));
  margin: 15px 0;
`;
