import React, { createContext, useState } from "react";

type Props = {
  children: React.ReactNode;
};

type UserContextType = {
  games: Array<any>;
  setGames: React.Dispatch<React.SetStateAction<Array<any>>>;
  netentGame: Array<any>;
  setNetentGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  playtechGame: Array<any>;
  setPlaytechGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  amaticGame: Array<any>;
  setAmaticGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  novomaticGame: Array<any>;
  setNovomaticGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  ainsworthGame: Array<any>;
  setAinsworthGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  quickspinGame: Array<any>;
  setQuickspinGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  aristocratGame: Array<any>;
  setAristocratGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  egtGame: Array<any>;
  setEgtGame: React.Dispatch<React.SetStateAction<Array<any>>>;
  isMobile: Boolean;
  setMobile: React.Dispatch<React.SetStateAction<Boolean>>;
  isOrder: Boolean;
  setOrder: React.Dispatch<React.SetStateAction<Boolean>>;
  gameSession: String;
  setGameSession: React.Dispatch<React.SetStateAction<String>>;
  userId: String;
  setUserId: React.Dispatch<React.SetStateAction<String>>;
  currentCountry: String;
  setCurrentCountry: React.Dispatch<React.SetStateAction<String>>;
  countryCode: string;
  setCountryCode: React.Dispatch<React.SetStateAction<string>>;
};

export const UserContext = createContext<UserContextType>({
  games: [],
  setGames: () => {},
  netentGame: [],
  setNetentGame: () => {},
  playtechGame: [],
  setPlaytechGame: () => {},
  amaticGame: [],
  setAmaticGame: () => {},
  novomaticGame: [],
  setNovomaticGame: () => {},
  ainsworthGame: [],
  setAinsworthGame: () => {},
  quickspinGame: [],
  setQuickspinGame: () => {},
  aristocratGame: [],
  setAristocratGame: () => {},
  egtGame: [],
  setEgtGame: () => {},
  isMobile: true,
  setMobile: () => {},
  isOrder: false,
  setOrder: () => {},
  gameSession: "",
  setGameSession: () => {},
  userId: "",
  setUserId: () => {},
  currentCountry: "",
  setCurrentCountry: () => {},
  countryCode: "",
  setCountryCode: () => {},
});

const UserProvider: React.FC<Props> = ({ children }) => {
  const [games, setGames] = useState<Array<any>>([]);
  const [netentGame, setNetentGame] = useState<Array<any>>([]);
  const [playtechGame, setPlaytechGame] = useState<Array<any>>([]);
  const [amaticGame, setAmaticGame] = useState<Array<any>>([]);
  const [novomaticGame, setNovomaticGame] = useState<Array<any>>([]);
  const [ainsworthGame, setAinsworthGame] = useState<Array<any>>([]);
  const [quickspinGame, setQuickspinGame] = useState<Array<any>>([]);
  const [aristocratGame, setAristocratGame] = useState<Array<any>>([]);
  const [egtGame, setEgtGame] = useState<Array<any>>([]);
  const [isMobile, setMobile] = useState<Boolean>(true);
  const [isOrder, setOrder] = useState<Boolean>(false);
  const [gameSession, setGameSession] = useState<String>("");
  const [userId, setUserId] = useState<String>("");
  const [currentCountry, setCurrentCountry] = useState<String>("");
  const [countryCode, setCountryCode] = useState<string>("");

  return (
    <UserContext.Provider
      value={{
        games,
        setGames,
        netentGame,
        setNetentGame,
        playtechGame,
        setPlaytechGame,
        amaticGame,
        setAmaticGame,
        novomaticGame,
        setNovomaticGame,
        ainsworthGame,
        setAinsworthGame,
        quickspinGame,
        setQuickspinGame,
        aristocratGame,
        setAristocratGame,
        egtGame,
        setEgtGame,
        isMobile,
        setMobile,
        isOrder,
        setOrder,
        gameSession,
        setGameSession,
        userId,
        setUserId,
        currentCountry,
        setCurrentCountry,
        countryCode,
        setCountryCode,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
