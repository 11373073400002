import React, { useContext, useEffect } from "react";
import { UserContext } from "../../Providers/UserContext";

const ChatwootWidget: React.FC = () => {
  const { isMobile } = useContext(UserContext);
  useEffect(() => {
    const loadChatwoot = () => {
      return new Promise<void>((resolve, reject) => {
        const BASE_URL = "https://chatwoot-artemis-2-8f21394d960e.herokuapp.com";
        const script = document.createElement("script");
        script.src = BASE_URL + "/packs/js/sdk.js";
        script.defer = true;
        script.async = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error("Chatwoot SDK failed to load."));
        document.head.appendChild(script);
      });
    };
    if (!isMobile) {
      loadChatwoot()
        .then(() => {
          window.chatwootSDK.run({
            websiteToken: "R4fxuL1XB9Kps4Tn71Q7XKe3",
            baseUrl: "https://chatwoot-artemis-2-8f21394d960e.herokuapp.com",
            launcherTitle: "", // Customize the launcher title
            position: "right", // Position of the widget
            locale: "en", // Language
          });
        })
        .catch((error) => {
          console.error("Error loading Chatwoot script:", error);
        });
    }
  }, [isMobile]);

  return null;
};

export default ChatwootWidget;
