import React from 'react';
import { RuleBodyText, RulesBodyTitle } from './styled';

const UserAgreement: React.FC = () => {
    return (
        <React.Fragment>
            <RulesBodyTitle>
                User Agreement
            </RulesBodyTitle>
            <RuleBodyText>
                <b>User Agreement</b>
                <b>PLEASE READ THESE AGREEMENTS CAREFULLY BEFORE ACCEPTING THEM.</b>
                <p>When using the Services, you acknowledge the following:</p>
                <ol type='1'>
                    <li>you enter into a binding agreement with us and accept these Terms and Conditions;</li>
                    <li>you agree with any other terms, policies, and regulations applicable to you and published on our Website.</li>
                </ol>
                <b>When using Betting Services, you also acknowledge and confirm that:</b>
                <ol>
                    <li>you have reached a minimum legal age allowing you to use our Betting Services in your state, province or country of residence;</li>
                    <li>you are not located at Restricted Territories;</li>
                    <li>you are 18 years or older;</li>
                    <li>you are playing for yourself and with your own money;</li>
                    <li>this is your first/only registration;</li>
                    <li>you have not self-excluded from any gambling website in the past 12 months;</li>
                    <li>you have read and accept the terms and conditions;</li>
                    <li>you declare that all information provided by you to 1win is correct and can be verified and you understand that providing any form of incorrect information will void all winnings of my plays on 1win;</li>
                    <li>you are aware and acknowledge that 1win sets certain limitations to the maximum prizes that can be won per day/week/month and/or can be withdrawn per day/week/month. </li>
                </ol>
                <p>We promote responsible gambling among our users. However, we would like you to note that any use of the Services is at your sole option, discretion and risk. By using Betting Services, you acknowledge that you are fully aware of the risk of losing money when gambling and that you are fully responsible for any such loss. By using our Betting Services, you agree that you have not and shall not have any claims whatsoever against us or any of our partners, directors, officers or employees concerning your gambling activities and losses.</p>
                <p>By visiting any section of the Website or by the opening of a betting account you agree with all clauses of the User Agreement (hereinafter referred to as the Agreement), Privacy Policy, conditions of the advertisement activity, betting rules, bonuses and special offers available on the Website. Before acceptance of conditions of the Agreement, all clauses thereof must be thoroughly reviewed. If you express a non-consent with this Agreement you must leave or stop using the Website. Any subsequent use of the Website shall be considered as acceptance of all following clauses of this Agreement by you.</p>
            </RuleBodyText>
        </React.Fragment>
    )
}

export default UserAgreement;