import React from 'react';

export const Favourite: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-profile-favorite sm BoxButton_icon_T35Ci BoxButton_icon_T35Ci">
            <defs>
                <linearGradient x1="0%" y1="100%" y2="0%" id="favorite_svg__a">
                    <stop stopColor="#0095FF" offset="0%"></stop>
                    <stop stopColor="#0854C3" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path d="M12.5 18.662l-3.797 1.996a2.25 2.25 0 01-3.264-2.372l.725-4.227-3.072-2.994A2.25 2.25 0 014.34 7.227l4.245-.617 1.898-3.846a2.25 2.25 0 014.036 0l1.898 3.846 4.245.617a2.25 2.25 0 011.247 3.838l-3.072 2.994.725 4.227a2.25 2.25 0 01-3.264 2.372L12.5 18.662z" stroke="url(#favorite_svg__a)" strokeWidth="2.5" fill="none"></path>
            <path d="M7 22l-2.909 1.53a1 1 0 01-1.45-1.055l.555-3.239-2.354-2.294a1 1 0 01.555-1.706l3.252-.472 1.454-2.947a1 1 0 011.794 0l1.454 2.947 3.252.472a1 1 0 01.555 1.706l-2.354 2.294.556 3.24a1 1 0 01-1.451 1.053L7 22z" fill="#FFF"></path>
        </svg>
    );
};
