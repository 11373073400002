import styled from "styled-components";

export const PromotionLandingBonus = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const PromotionBonusTitle = styled.div`
    display: flex;
    align-items: center;
    span{
        font-weight: 700;
        font-size: 16px;
        line-height: 1.3;
    }
    label {
        font-weight: 400;
        font-size: 14px;
        line-height: .86;
        letter-spacing: .23px;
        color: #7388b6;
        margin-left: 10px;
    }
`

export const PromotionBonusCards = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px,1fr));
    grid-gap: 15px;
    width: 100%;
    div {
        background-color: #323a4ed9;
        border-radius: 12px;
        color: #fff;
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        cursor: pointer;
        overflow: hidden;
        will-change: transform;
        white-space: pre-line;
        height: 200px;
    }
`