import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { loginUser, AxiosResponseLogin, registerUser } from "../../API/auth";
import { getUser } from "../../API/account";

type InitialStateType = AxiosResponseLogin & {
  isLogin: boolean;
  isRegister: boolean;
  loading: boolean;
  error: string | null;
};

const initialState: InitialStateType = {
  id: "",
  email: "",
  verified: false,
  provider: "",
  isLogin: false,
  isRegister: false,
  loading: false,
  error: "",
  socialID: "",
};

export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogout: (state) => {
      state.isLogin = false;
      state.isRegister = false;
      state.id = "";
      state.email = "";
      state.provider = "";
      state.socialID = "";
    },
    cleanError: (state) => {
      state.error = "";
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<InitialStateType>) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isLogin = true;
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.provider = action.payload.provider;
      state.verified = action.payload.verified;
      state.socialID = action.payload.socialID;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isLogin = true;
      state.id = action.payload._id;
      state.email = action.payload.email;
      state.provider = action.payload.provider;
      state.verified = action.payload.verified;
      // state.socialID = action.payload.socialID;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.isLogin = false;
      state.id = "";
      state.email = "";
      state.provider = "";
      state.verified = false;
    });
    builder.addCase(registerUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.isRegister = true;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setLogout, cleanError } = auth.actions;

export default auth.reducer;
