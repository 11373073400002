export const category_list = [
  {
    name: "New Games",
    path: "news",
  },
  {
    name: "NETENT",
    path: "netent_desktop",
  },
  {
    name: "NETENT2",
    path: "netent",
  },
  {
    name: "AMATIC",
    path: "amatic",
  },
  {
    name: "AMATIC2",
    path: "amatic2",
  },
  {
    name: "NOVOMATIC",
    path: "novomatic_desktop",
  },
  {
    name: "NOVOMATIC2",
    path: "novomatic",
  },
  {
    name: "Ainsworth",
    path: "ainsworth_desktop",
  },
  {
    name: "Quickspin",
    path: "quickspin_desktop",
  },
  {
    name: "Aristocrat",
    path: "aristocrat_desktop",
  },
  {
    name: "ARISTOCRAT2",
    path: "aristocrat",
  },
  {
    name: "EGT",
    path: "egt_desktop",
  },
  {
    name: "PlayNGo",
    path: "playngo",
  },
  {
    name: "River",
    path: "river",
  },
  {
    name: "PragmaticPlay",
    path: "pragmaticplay",
  },
  {
    name: "Scientific",
    path: "scientific",
  },
  {
    name: "Yggdrasil",
    path: "yggdrasil",
  },
  {
    name: "Zeppelin",
    path: "zeppelin",
  },
];

export const mobile_category_list = [
  {
    name: "New Games",
    path: "news",
  },
  {
    name: "NETENT",
    path: "netent_mobile",
  },
  {
    name: "NETENT2",
    path: "netent",
  },
  {
    name: "PLAYTECH",
    path: "playtech_mobile",
  },
  {
    name: "AMATIC",
    path: "amatic",
  },
  {
    name: "AMATIC2",
    path: "amatic2",
  },
  {
    name: "NOVOMATIC",
    path: "novomatic_mobile",
  },
  {
    name: "NOVOMATIC2",
    path: "novomatic",
  },
  {
    name: "Ainsworth",
    path: "ainsworth_mobile",
  },
  {
    name: "ARISTOCRAT2",
    path: "aristocrat",
  },
  {
    name: "Quickspin",
    path: "quickspin_mobile",
  },
  {
    name: "Aristocrat",
    path: "aristocrat_mobile",
  },
  {
    name: "EGT",
    path: "egt_desktop",
  },
  {
    name: "PlayNGo",
    path: "playngo",
  },
  {
    name: "River",
    path: "river",
  },
  {
    name: "PragmaticPlay",
    path: "pragmaticplay",
  },
  {
    name: "Scientific",
    path: "scientific",
  },
  {
    name: "Yggdrasil",
    path: "yggdrasil",
  },
  {
    name: "Zeppelin",
    path: "zeppelin",
  },
];
