import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineLeft } from "react-icons/ai";

import Header from "../../layout/Header";
import { Flex, ModalTabs, Tab } from "../../components/Modals/styled";
import { tabList } from "../../components/Modals/DetailingModal";

const Detail: React.FC = () => {
  const navigate = useNavigate();
  const [tabName, setTabName] = useState("all");

  return (
    <React.Fragment>
      <Header />
      <Flex direction="column" style={{ padding: "15px", textAlign: "left" }}>
        <Flex
          direction="row"
          alignItems="center"
          style={{ gap: "6px", margin: "5px 0", cursor: "pointer" }}
          onClick={() => navigate("/profile")}
        >
          <AiOutlineLeft style={{ width: "13px", height: "13px" }} />
          <div style={{ fontSize: "13px" }}>Profile</div>
        </Flex>
        <div style={{ fontSize: "24px", fontWeight: 700, paddingTop: "10px", paddingBottom: "5px" }}>Details</div>
        <ModalTabs style={{ padding: "10px 0" }}>
          {tabList.map((tab, index) => (
            <Tab
              key={`${tab.name}_${index}`}
              selected={tab.name.toLocaleLowerCase() === tabName}
              style={{ fontSize: "14px", backgroundColor: "transparent", color: "white", padding: "2px 10px" }}
              onClick={() => setTabName(tab.name.toLocaleLowerCase())}
            >
              {tab.name}
            </Tab>
          ))}
        </ModalTabs>
        <Flex direction="column" style={{ marginTop: "25px", gap: "25px" }}>
          <div>
            <div style={{ fontSize: "16px", fontWeight: 700 }}>Awaiting</div>
            <Flex direction="row" justifyContent="space-between" style={{ padding: "15px 0" }}>
              <Flex direction="row" alignItems="center" style={{ fontSize: "15px", fontWeight: 600, gap: "10px" }}>
                <svg
                  width="23"
                  height="21"
                  viewBox="0 0 23 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fk_wallet"
                  className="PaymentIcon_mono_omb3O PaymentIcon_icon_tinGf"
                  style={{ height: "32px" }}
                >
                  <g className="fk-wallet_svg__mono">
                    <path
                      d="M15.418 10.223L22.61 0h-2.153c-1.053 0-2.047.507-2.632 1.342l-6.88 9.773c-.584.882-.945 1.342-.945 3.483V21h2.748v-9.115l5.604 7.97A2.75 2.75 0 0020.593 21H23l-7.582-10.777zM6.36 8H3.242C2.125 8 .008 11.101.008 13.778h.008a5.825 5.825 0 00-.016.384V21h2.692V10.859h-.09v-.01H6.36c.353 0 .64-.354.64-.788V8.788C7 8.354 6.713 8 6.36 8zM2.971 4.478v-.473c0-1.169.863-1.579 1.799-1.579v-.009h8.313C14.446 2.417 16 1.33 16 0H4.78C1.68 0 0 1.061 0 3.88V9v-.027c.1-.116 2.971-1.686 2.971-4.495z"
                      fill="#fff"
                    ></path>
                  </g>
                  <g className="fk-wallet_svg__color">
                    <path
                      d="M15.418 10.223L22.61 0h-2.153c-1.053 0-2.047.507-2.632 1.342l-6.88 9.773c-.584.882-.945 1.342-.945 3.483V21h2.748v-9.115l5.604 7.97A2.75 2.75 0 0020.593 21H23l-7.582-10.777z"
                      fill="url(#fk-wallet_svg__a)"
                    ></path>
                    <path
                      d="M6.36 8H3.242C2.125 8 .008 11.101.008 13.778h.008a5.825 5.825 0 00-.016.384V21h2.692V10.859h-.09v-.01H6.36c.353 0 .64-.354.64-.788V8.788C7 8.354 6.713 8 6.36 8z"
                      fill="url(#fk-wallet_svg__b)"
                    ></path>
                    <path
                      d="M2.971 4.478v-.473c0-1.169.863-1.579 1.799-1.579v-.009h8.313C14.446 2.417 16 1.33 16 0H4.78C1.68 0 0 1.061 0 3.88V9v-.027c.1-.116 2.971-1.686 2.971-4.495z"
                      fill="url(#fk-wallet_svg__c)"
                    ></path>
                    <defs>
                      <linearGradient id="fk-wallet_svg__a" x1="8.408%" x2="77.524%" y1="-4.846%" y2="87.599%">
                        <stop offset="0%" stopColor="#1C78FF"></stop>
                        <stop offset="100%" stopColor="#9815FF"></stop>
                      </linearGradient>
                      <linearGradient id="fk-wallet_svg__b" x1="19.763%" x2="101.543%" y1="-7.781%" y2="152.902%">
                        <stop offset="0%" stopColor="#1C78FF"></stop>
                        <stop offset="100%" stopColor="#9815FF"></stop>
                      </linearGradient>
                      <linearGradient id="fk-wallet_svg__c" x1="15.605%" x2="152.23%" y1="33.669%" y2="98.518%">
                        <stop offset="0%" stopColor="#1C78FF"></stop>
                        <stop offset="100%" stopColor="#9815FF"></stop>
                      </linearGradient>
                    </defs>
                  </g>
                </svg>
                <div>FK Wallet</div>
              </Flex>
              <div>
                <div style={{ fontSize: "11px", color: "#5c72a3" }}>January 31, 2024 | 12:06 AM</div>
                <div style={{ fontSize: "13px", fontWeight: 600, textAlign: "right" }}>2 500,00 ₽</div>
              </div>
            </Flex>
          </div>
          <div>
            <div style={{ fontSize: "16px", fontWeight: 700 }}>Completed</div>
          </div>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default Detail;
