import styled from "styled-components";

export const BonusWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 960px;
    margin: auto;
    padding-top: 20px;
`

export const PermanentBonus = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const PermanentBonusTitle = styled.span`
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;
    display: flex;
    align-items: center;
`

export const PermantentBonusCard = styled.div`
    position: relative;
`