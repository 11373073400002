import React from 'react';
import Header from '../../layout/Header';

const FreeMoney: React.FC = () => {

    return (
        <React.Fragment>
            <Header />
        </React.Fragment>
    )
}

export default FreeMoney;