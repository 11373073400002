import axios from "axios";
import { API_ROUTES } from "../config/api-routes";

export const registerService = async (email: string, password: string, phoneNumber: string, location: string) => {
  const result = await axios.post(API_ROUTES.register, {
    email,
    password,
    phoneNumber,
    location,
  });
  return result.data;
};

export const googleAuthService = () => {
  window.open(API_ROUTES.googleAuth, "_self");
};

export const mailruAuthService = () => {
  window.open(API_ROUTES.mailruAuth, "_self");
};

export const yandexAuthService = () => {
  window.open(API_ROUTES.yandexAuth, "_self");
};

export const twitterAuthService = async () => {
  window.open(API_ROUTES.twitterAuth, "_self");
};
