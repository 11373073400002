import React from 'react';

export const Setting: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-profile-settings-double sm BoxButton_icon_T35Ci BoxButton_icon_T35Ci">
            <defs>
                <linearGradient x1="0%" y1="100%" y2="0%" id="settings-double_svg__a">
                    <stop stopColor="#0095FF" offset="0%"></stop>
                    <stop stopColor="#0854C3" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path d="M9.379 25.343a3.45 3.45 0 01-1.134-2.738.925.925 0 00-.971-.97 3.45 3.45 0 01-2.489-6.009.925.925 0 000-1.373 3.45 3.45 0 012.489-6.008.925.925 0 00.971-.971 3.45 3.45 0 016.008-2.489.925.925 0 001.373 0 3.45 3.45 0 016.008 2.489.925.925 0 00.971.971 3.45 3.45 0 012.489 6.008.925.925 0 000 1.373 3.45 3.45 0 01-2.489 6.008.925.925 0 00-.97.971 3.45 3.45 0 01-6.009 2.489.925.925 0 00-1.373 0 3.45 3.45 0 01-4.874.249zm3.23-1.733a3.14 3.14 0 014.66 0 1.237 1.237 0 002.154-.892 3.14 3.14 0 013.295-3.295 1.237 1.237 0 00.892-2.153 3.14 3.14 0 010-4.66 1.237 1.237 0 00-.892-2.153 3.14 3.14 0 01-3.295-3.296 1.237 1.237 0 00-2.153-.892 3.14 3.14 0 01-4.66 0 1.237 1.237 0 00-2.153.892 3.14 3.14 0 01-3.296 3.296 1.237 1.237 0 00-.892 2.152 3.14 3.14 0 010 4.66 1.237 1.237 0 00.892 2.154 3.14 3.14 0 013.296 3.295 1.237 1.237 0 002.152.892z" fill="url(#settings-double_svg__a)" transform="rotate(22.5 20.98 5.899)"></path>
            <path d="M11.918 15.918a4 4 0 100-8 4 4 0 000 8zm0-2a2 2 0 110-4 2 2 0 010 4z" fill="#FFF"></path>
        </svg>
    );
};
