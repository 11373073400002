import React from 'react';

export const Pocker: React.FC = () => {
    return (
        <svg data-v-05b89ab6="" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-home-poker sm margin square section-icon VIconSvgGradient_icon_WJLeK section-icon section-icon VIconSvgGradient_icon_WJLeK section-icon">
            <rect width="40" height="40" rx="8" fill="url(#poker_svg__paint0_linear_3_4314)"></rect>
            <path fillRule="evenodd" clipRule="evenodd" d="M9.2 12.807c-.14-.886-.21-1.328-.091-1.693a1.6 1.6 0 01.581-.8c.311-.226.754-.296 1.639-.436l7.587-1.202c.885-.14 1.327-.21 1.693-.092a1.6 1.6 0 01.8.582c.226.31.296.753.436 1.638l2.104 13.284c.14.885.21 1.328.091 1.693a1.6 1.6 0 01-.58.8c-.312.226-.754.296-1.64.436l-7.587 1.202c-.885.14-1.327.21-1.692.091a1.6 1.6 0 01-.8-.58c-.226-.312-.296-.754-.436-1.64L9.2 12.808zm10.783-1.167c.09.563-.274 1.088-.811 1.173-.537.085-1.045-.302-1.134-.864-.09-.563.274-1.088.811-1.173.537-.085 1.045.302 1.134.864zM14.3 26.12c.537-.086.9-.611.811-1.174-.089-.563-.597-.95-1.134-.865-.537.085-.9.61-.811 1.173.09.563.597.95 1.134.865zm4.565-8.699l-1.861-1.857a.939.939 0 00-.237-.172c-.485-.248-1.093-.04-1.359.463l-1.284 2.43c-.211.4-.146.883.16 1.188l1.861 1.858c.07.07.15.127.237.172.485.248 1.093.04 1.36-.463l1.283-2.431a1.01 1.01 0 00-.16-1.188z" fill="#fff"></path>
            <path fillRule="evenodd" clipRule="evenodd" d="M17.58 29.387l.177.035 7.537 1.486c.88.173 1.319.26 1.688.155a1.6 1.6 0 00.822-.55c.237-.303.324-.742.497-1.622l2.602-13.195c.173-.88.26-1.319.155-1.689a1.6 1.6 0 00-.551-.82c-.302-.238-.742-.325-1.62-.498l-5.018-.99c.117.415.203.96.325 1.727l1.703 10.756c.21 1.327.316 1.991.137 2.54a2.4 2.4 0 01-.871 1.2c-.467.338-1.13.443-2.458.653l-5.059.802-.066.01zm11.665-13.913c.11-.559-.233-1.097-.767-1.203-.534-.105-1.055.263-1.166.822-.11.56.233 1.098.767 1.203.534.105 1.055-.263 1.166-.822z" fill="#fff"></path>
            <defs>
                <linearGradient id="poker_svg__paint0_linear_3_4314" x1="24.629" y1="39.7" x2="5.17" y2="-1.671" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#32C5FF"></stop>
                    <stop offset=".431" stopColor="#43B0FB"></stop>
                    <stop offset="1" stopColor="#B620E0"></stop>
                </linearGradient>
            </defs>
        </svg>
    );
};