import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Providers/UserContext";
import {
  BannerSection,
  BannerSectionItem,
  BarContainer,
  BonuseLinkCircleText,
  BonuseLinkHeader,
  BonuseLinkSubHeader,
  BoxContainer,
  CardContent,
  CardHeader,
  CardMessage,
  CardSvg,
  FreeMoneyBtn,
  FreeMoneyCoin,
  FreeMoneyHeader,
  FreeMoneyLink,
  FreeMoneySubHeader,
  GameCard,
  GameCardContainer,
  HomeSectionGameList,
  HomeSectionGames,
  HomeSectionHeader,
  HomeSectionHeaderContent,
  HomeSectionHeaderTitle,
  ImageSlide,
  LandingContainer,
  LandingContent,
  LandingHeader,
  LandingPromos,
  MobileHomeContainer,
  MobileHomeSectionContainer,
  MobilePromotionLinkContainer,
  PromoCard,
  PromotionBtn,
  SectionCardContent,
  SectionCardHeader,
  SectionCardLeft,
  SectionCardLeftContent,
  SectionCardRight,
} from "./styled";

import Header from "../../layout/Header";
import Footer from "../../layout/Footer";
import MobileNavFooter from "../../layout/MobileFooter/MobileNavFooter";
import MobileFooter from "../../layout/MobileFooter";
// import { RegisterModal } from "../../components/Modals/RegisterModal";
import ImageSlider from "../../components/ImageSlider";

import { Win } from "../../assets/svg/win";
import { Pocker } from "../../assets/svg/pocker";
import CashBackImg from "../../assets/image/box/bonus-banner-cashback-casino.avif";
import BonusImg from "../../assets/image/box/bonus-banner-deposit.avif";
import PockerBannerbg from "../../assets/image/home-poker-banner.webp";
import presentLight from "../../assets/image/present-with-light.bd57fb068-151.png";
import { PromotionIcon } from "../../assets/svg/promotion";
import { category_list, mobile_category_list } from "../../config/category";
import axios from "axios";
import { useTypedSelector } from "../../store/typeHooks";

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const { games, setGames, netentGame, setNetentGame, isMobile, isOrder } = useContext(UserContext);
  const { isLogin } = useTypedSelector((state) => state.auth);

  useEffect(() => {
    if (isOrder) {
      getData();
    }
  }, [isOrder, isMobile]);

  const getData = async () => {
    let data: any[] = [];
    let list = isMobile ? mobile_category_list : category_list;
    for (let idx = 0; idx < list.length; idx++) {
      const element = list[idx];
      await axios
        .get(`https://report.b-cdn.net/reports/get_games?software=${element.path}`)
        // eslint-disable-next-line no-loop-func
        .then((res: any) => {
          if (res.data.games) {
            data = [...data, ...res.data.games];
            if (element.name === "NETENT") {
              setNetentGame(res.data.games);
            }
          }
        })
        .catch((err) => console.log(err));
    }
    setGames(data);
  };

  const Promos_card = [
    {
      header: "TVBET",
      message: "Live Games 24/7",
      svg: "",
    },
    {
      header: "Сasino",
      message: "Over 3000 games",
      svg: "",
    },
    {
      header: "Live-Games",
      message: "Live dealers",
      svg: "",
    },
    {
      header: "Poker",
      message: "Free Tournaments",
      svg: "",
    },
  ];

  const game_card = ["", "", "", ""];

  const casino_games = ["", "", "", "", "", "", "", "", "", "", "", ""];

  // const handleRegisterModal = () => {
  //     setRegisterModal(false)
  // }

  const handleGamePlay = (games: any, provider: string) => {
    if (isLogin) {
      navigate(`/casino/play/${games[1]}`, { state: { gameItem: games, provider } });
    } else {
      navigate("/", { state: { modal: "login" } });
    }
  };

  return (
    <LandingContainer>
      <Header />
      <LandingHeader>
        <ImageSlide>
          <ImageSlider />
        </ImageSlide>
        <BoxContainer imgurl={CashBackImg}>
          <span>Cashback up to 30% on casinos</span>
          <button onClick={() => navigate("/casino")}>Go to casino</button>
        </BoxContainer>
        <BoxContainer imgurl={BonusImg}>
          <span>Bonus + 500%</span>
          {!isLogin ? (
            <button onClick={() => navigate("/", { state: { modal: "register" } })}>Registration</button>
          ) : (
            <button onClick={() => navigate("/", { state: { modal: "deposit" } })}>Get bonus</button>
          )}
        </BoxContainer>
      </LandingHeader>
      <LandingContent>
        <LandingPromos>
          {Promos_card.map((item, index) => (
            <PromoCard key={index}>
              <CardContent>
                <CardHeader>{item.header}</CardHeader>
                <CardMessage>{item.message}</CardMessage>
              </CardContent>
              <CardSvg></CardSvg>
            </PromoCard>
          ))}
        </LandingPromos>
        <BannerSection>
          <BannerSectionItem>
            <BarContainer bartype={1} />
            <SectionCardHeader>
              <SectionCardLeft>
                <Win />
                <span>1win games</span>
              </SectionCardLeft>
              <SectionCardRight>All</SectionCardRight>
            </SectionCardHeader>
            <GameCardContainer>
              {game_card.map((games, index) => (
                <GameCard key={index}></GameCard>
              ))}
            </GameCardContainer>
          </BannerSectionItem>
          <BannerSectionItem>
            <BarContainer bartype={2} />
            <img src={PockerBannerbg} style={{ position: "absolute", right: 0, height: "100%" }} alt="pocker" />
            <SectionCardHeader>
              <SectionCardLeft>
                <Pocker />
                <span>Poker</span>
              </SectionCardLeft>
              <SectionCardRight></SectionCardRight>
            </SectionCardHeader>
            <SectionCardContent>
              <span>Come in, play and win</span>
              <button onClick={() => navigate("/poker")}>Get bonus</button>
            </SectionCardContent>
          </BannerSectionItem>
        </BannerSection>
        <BannerSection>
          <BannerSectionItem>
            <BarContainer bartype={3} />
            <SectionCardHeader>
              <SectionCardLeft>
                <SectionCardLeftContent>
                  <p>Casino</p>
                  <span>{games.length}</span>
                </SectionCardLeftContent>
              </SectionCardLeft>
              <SectionCardRight onClick={() => navigate("/casino")}>All</SectionCardRight>
            </SectionCardHeader>
            <GameCardContainer>
              {netentGame.map(
                (games, index) =>
                  index < 12 && (
                    <GameCard key={index} onClick={() => handleGamePlay(games, "NETENT")}>
                      <img
                        src={`https://eu-static.casinomodules.co/images/netent_${isMobile ? "mobile" : "desktop"}/${games[1]}.png`}
                        width="100%"
                        height="100%"
                        style={{ borderRadius: "12px" }}
                        alt={games[1]}
                      />
                    </GameCard>
                  )
              )}
            </GameCardContainer>
          </BannerSectionItem>
          <BannerSectionItem>
            <BarContainer bartype={4} />
            <SectionCardHeader>
              <SectionCardLeft>
                <SectionCardLeftContent>
                  <p>Livegames</p>
                  <span>326</span>
                </SectionCardLeftContent>
              </SectionCardLeft>
              <SectionCardRight>All</SectionCardRight>
            </SectionCardHeader>
            <GameCardContainer>
              {casino_games.map((games, index) => (
                <GameCard key={index}></GameCard>
              ))}
            </GameCardContainer>
          </BannerSectionItem>
        </BannerSection>
      </LandingContent>
      <MobilePromotionLinkContainer>
        <FreeMoneyBtn>
          <FreeMoneyCoin />
          <FreeMoneyLink />
          <FreeMoneyHeader>Free money</FreeMoneyHeader>
          <FreeMoneySubHeader>Free cash!</FreeMoneySubHeader>
        </FreeMoneyBtn>
        <PromotionBtn>
          <PromotionIcon width="25px" height="25px" />
          <BonuseLinkCircleText>
            <BonuseLinkHeader>Promotions</BonuseLinkHeader>
            <BonuseLinkSubHeader>and bonuses</BonuseLinkSubHeader>
          </BonuseLinkCircleText>
          <img src={presentLight} alt="bonuse" />
        </PromotionBtn>
      </MobilePromotionLinkContainer>
      <div style={{ padding: "0 15px" }}>
        <MobileHomeContainer>
          <MobileHomeSectionContainer>
            <HomeSectionHeader>
              <HomeSectionHeaderTitle>1win games</HomeSectionHeaderTitle>
              <HomeSectionHeaderContent>
                <label>All</label>
                <span>13 games</span>
              </HomeSectionHeaderContent>
            </HomeSectionHeader>
            <HomeSectionGameList>
              {[...new Array(10)].map((item: any, key: any) => (
                <HomeSectionGames key={key}>
                  <div></div>
                  <span>Lucky Jet</span>
                </HomeSectionGames>
              ))}
            </HomeSectionGameList>
          </MobileHomeSectionContainer>
          <MobileHomeSectionContainer>
            <HomeSectionHeader>
              <HomeSectionHeaderTitle>Casino</HomeSectionHeaderTitle>
              <HomeSectionHeaderContent>
                <label onClick={() => navigate("/casino")}>All</label>
                <span>{games?.length} games</span>
              </HomeSectionHeaderContent>
            </HomeSectionHeader>
            <HomeSectionGameList>
              {netentGame.map(
                (item: any, key: number) =>
                  key < 12 && (
                    <HomeSectionGames key={key} onClick={() => handleGamePlay(games, "NETENT")}>
                      <div>
                        <img
                          src={`https://eu-static.casinomodules.co/images/netent_mobile/${item[1]}.png`}
                          width="100%"
                          height="100%"
                          style={{ borderRadius: "12px" }}
                          alt={item[1]}
                        />
                      </div>
                      <span>{item[2]}</span>
                    </HomeSectionGames>
                  )
              )}
            </HomeSectionGameList>
          </MobileHomeSectionContainer>
          <MobileHomeSectionContainer>
            <HomeSectionHeader>
              <HomeSectionHeaderTitle>Live-games</HomeSectionHeaderTitle>
              <HomeSectionHeaderContent>
                <label>All</label>
                <span>3954 games</span>
              </HomeSectionHeaderContent>
            </HomeSectionHeader>
            <HomeSectionGameList>
              {[...new Array(10)].map((item: any, key: any) => (
                <HomeSectionGames key={key}>
                  <div></div>
                  <span>Lucky Jet</span>
                </HomeSectionGames>
              ))}
            </HomeSectionGameList>
          </MobileHomeSectionContainer>
          <MobileHomeSectionContainer>
            <HomeSectionHeader>
              <HomeSectionHeaderTitle>Games</HomeSectionHeaderTitle>
              <HomeSectionHeaderContent>
                <label>All</label>
                <span>150 games</span>
              </HomeSectionHeaderContent>
            </HomeSectionHeader>
            <HomeSectionGameList>
              {[...new Array(10)].map((item: any, key: any) => (
                <HomeSectionGames key={key}>
                  <div></div>
                  <span>Lucky Jet</span>
                </HomeSectionGames>
              ))}
            </HomeSectionGameList>
          </MobileHomeSectionContainer>
        </MobileHomeContainer>
      </div>
      <Footer />
      <MobileFooter />
      <MobileNavFooter />
      {/* <RegisterModal isOpen={isRegisterModal} handleClose={handleRegisterModal} /> */}
    </LandingContainer>
  );
};

export default Landing;
