import styled from "styled-components";

type Props = {
    imgurl?: string
}

export const ImageSlideContent = styled.div<Props>`
    background: url(${(props) => props.imgurl}) no-repeat;
    border-radius: 12px;
    width: 100%;
    height: 20vw;
    background-size: 100% 100%;
    cursor: pointer;
    @media screen and (max-width: 1199px) {
		height: 30vw;
	}
`

export const SlideBtns = styled.div`
    background-color: #fff3;
    position: absolute;
    bottom: 10px;
    right: 15px;
    border-radius: 12px;
    display: flex;
    padding: 5px;
    z-index: 1;
    gap: 10px;
    @media screen and (max-width: 1199px) {
		display: none;
	}
`

export const SlideBtn = styled.button`
    border: none;
    background-color: #ffffff80;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
`