import styled from "styled-components";

type Props = {
  flag?: boolean;
};

type EmailProp = {
  flag?: boolean;
};

interface SelectAmountProp {
  color?: boolean;
}

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.95);
  z-index: 2;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: inherit;
  outline: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 15px 15px 8px 8px;
  width: 390px;
  gap: 10px;
  @media screen and (max-width: 1199px) {
    width: 90%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    color: black;
    font-size: 23px;
    font-weight: 600;
  }
  button {
    border: none;
    background-color: #edf0f7;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

export const ModalSubTitle = styled.span`
  font-size: 12px;
  line-height: 1.29;
  color: #070c19;
  white-space: pre-line;
  text-align: left;
  font-weight: 400;
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const SocialBtnList = styled.ul`
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding: 0;
  li {
    align-items: center;
    /* background-color: #2370b3; */
    border-radius: 9px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow: hidden;
    font-size: 10px;
    font-weight: 600;
    height: 33px;
    width: 33px;
    margin-right: 20px;
  }
  img {
    width: 20px;
    height: 20px;
  }
`;

export const LoginTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: #edf0f7;
  border-radius: 10px;
  padding: 3px;
  gap: 5px;
`;

export const TabButton = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #070c19;
  width: 100%;
  padding: 10px;
  font-size: 13px;
  gap: 10px;
  cursor: pointer;
`;

export const FromContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
  gap: 10px;
`;

export const CustomInput = styled.div<EmailProp>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #edf0f7;
  border-radius: 10px;
  gap: 10px;
  padding: 0 15px;
  width: calc(100% - 30px);
  color: #070c19cc;
  cursor: default;
  cursor: ${(props) => (props.flag ? "pointer" : "default")};
  input {
    background-color: #edf0f7;
    border: none;
    outline: none;
    width: 100%;
    font-size: 12px;
    padding: 15px 0;
    color: #070c19cc;
    border-radius: 10px;
    cursor: ${(props) => (props.flag ? "pointer" : "default")};
  }
  svg {
    cursor: pointer;
  }
  label {
    color: #6a7690b3;
    font-size: 11px;
    white-space: nowrap;
    cursor: ${(props) => (props.flag ? "pointer" : "default")};
  }
`;

export const ChangePasswordText = styled.div`
  padding-left: 16px;
  display: flex;
  align-items: center;
  font-size: 13px;
  position: relative;
  width: calc(100% - 30px);
  color: #070c19cc;
  border-left: solid;
  border-width: 3px;
  border-color: #108de7;
`;

export const ForgotPasswordText = styled.span`
  color: #6a7690a6;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  align-self: flex-end;
`;

export const LoginButton = styled.button`
  background: linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%);
  box-shadow: 0 6px 18px #1178df4d;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  font-size: 15px;
  line-height: 18px;
  padding: 15px;
  margin-top: 10px;
  color: white;
  font-weight: 600;
  width: 100%;
`;

export const RegisterAdv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 5px;
  font-size: 11px;
  color: #6a7690a6;
  span {
    font-weight: 600;
    color: #1077de;
    cursor: pointer;
    line-height: 13px;
  }
`;

export const RegisterButton = styled.button<Props>`
  background: linear-gradient(267.05deg, #089e4e 0%, #31bc69 99.28%);
  box-shadow: 0 6px 18px #0ea1514d;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: none;
  font-size: 15px;
  line-height: 18px;
  padding: 15px;
  color: white;
  font-weight: 600;
  opacity: ${(props) => (props.flag ? 1 : 0.5)};
  width: 100%;
  cursor: pointer;
`;

export const RulesAcceptCheckBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  line-height: 13px;
  color: #9ea6b7;
  input {
    border-radius: 10px;
    width: 15px;
    height: 15px;
    background: #edf0f7;
  }
  span {
    margin-right: 3px;
  }
  a {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #1077de;
    }
  }
`;

export const PasswordChangeBtn = styled.button`
  font-size: 15px;
  line-height: 18px;
  background: linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%);
  box-shadow: 0 6px 18px #1178df4d;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  color: white;
  padding: 15px;
`;

export const SaveBtn = styled.button`
  background: linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%);
  box-shadow: 0 6px 18px #1178df4d;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 15px;
  border: none;
  color: #fff;
  padding: 15px;
  cursor: pointer;
  /* pointer-events: none; */
`;

export const ModalTabs = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

interface TabsProps {
  selected: boolean;
}

export const Tab = styled.div<TabsProps>`
  cursor: pointer;
  padding: 4px 10px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  color: ${(props) => (props.selected ? "#fff" : "#090F1E")};
  background: ${(props) => (props.selected ? "linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%)" : "#fff")};
`;

interface FlexProps {
  direction?: "row" | "column";
  alignItems?: "center" | "start" | "end" | "flex-start" | "flex-end" | "self-start" | "self-end";
  justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
`;

export const TXPart = styled.div`
  padding: 14px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
`;

export const PaymentTools = styled.div`
  height: 640ox;
  /* min-width: 200px;
  width: 290px; */
  padding: 20px;
  background-color: #edf0f7;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Tool = styled.div<TabsProps>`
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: ${(props) => (props.selected ? "linear-gradient(90.77deg, #0095ff 0.96%, #0855c4 99.87%)" : "#ffffff")};
  color: ${(props) => (props.selected ? "#ffffffb3" : "#090F1E")};
  border-radius: 10px;
  cursor: pointer;
  nav {
    display: flex;
    gap: 2px;
    img {
      width: 18px !important;
      height: 18px !important;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
    span {
      font-size: 12px;
      font-weight: 500;
      word-break: break-word;
    }
  }
  img {
    width: 120px;
    align-items: center;
    display: flex;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 120px;
    }
  }
`;

export const Input = styled.input`
  background-color: transparent;
  outline: none;
  border: none;
`;

export const WithdrawButton = styled.button`
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 6px 18px #1178df4d;
  background-image: linear-gradient(102deg, #0095ff -2%, #0855c4 148%);
  border: none;
  color: white;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const ChangePasswordButton = styled.button`
  font-size: 13px;
  font-weight: 600;
  height: 30px;
  width: max-content;
  border-radius: 10px;
  padding: 0 15px;
  background-image: linear-gradient(102deg, #0095ff -2%, #0855c4 148%);
  border: none;
  color: white;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 18px #1178df4d;
  }
`;

export const SaveButton = styled.button`
  margin-top: 30px;
  font-size: 13px;
  font-weight: 600;
  height: 36px;
  border-radius: 10px;
  padding: 0 15px;
  background-image: linear-gradient(102deg, #0095ff -2%, #0855c4 148%);
  border: none;
  color: white;
  cursor: pointer;
  &:hover {
    box-shadow: 0 6px 18px #1178df4d;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: none;
  }
`;

export const ModalAdditional = styled.div`
  margin-top: 3px;
  border-radius: 8px 8px 15px 15px;
  background-color: #fff;
  width: 390px;
  padding: 0 20px;
  @media screen and (max-width: 1199px) {
    width: 90%;
  }
`;

export const BonusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const CryptoPaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  nav {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 10px;
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      background-color: #edf0f7;
      padding: 10px;
      border-radius: 10px;
      height: 60px;
      cursor: pointer;
      img {
        width: 25px;
        height: 25px;
      }
      span {
        color: #000;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`;

export const AmountSelect = styled.div<SelectAmountProp>`
  border: 1px solid #aaaaaf;
  border-radius: 8px;
  padding: 5px 9px;
  color: ${(props) => (props.color ? "white" : "black")};
  background-color: ${(props) => (props.color ? "#333352" : "white")};
  cursor: pointer;
`;
