import React from "react";
import { useNavigate } from "react-router-dom";
import { MobileNavFooterContainer } from "./styled";
import { RiFootballFill } from "react-icons/ri";
import { CiChat1 } from "react-icons/ci";
import { RiWindow2Fill } from "react-icons/ri";
import { MdCasino } from "react-icons/md";
import { TbTicket } from "react-icons/tb";

const MobileNavFooter: React.FC = () => {
  const navigate = useNavigate();

  const loadChatwoot = () => {
    return new Promise<void>((resolve, reject) => {
      const BASE_URL = "https://chatwoot-artemis-2-8f21394d960e.herokuapp.com";
      const script = document.createElement("script");
      script.src = BASE_URL + "/packs/js/sdk.js";
      script.defer = true;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Chatwoot SDK failed to load."));
      document.head.appendChild(script);
    });
  };

  const handleChat = () => {
    loadChatwoot()
      .then(() => {
        window.chatwootSDK.run({
          websiteToken: "R4fxuL1XB9Kps4Tn71Q7XKe3",
          baseUrl: "https://chatwoot-artemis-2-8f21394d960e.herokuapp.com",
          locale: "en", // Language
        });
        window.$chatwoot.toggle("open");
      })
      .catch((error) => {
        console.error("Error loading Chatwoot script:", error);
      });
  };

  return (
    <React.Fragment>
      <MobileNavFooterContainer>
        <div onClick={() => navigate("/")}>
          <RiWindow2Fill size={25} />
          <span>main</span>
        </div>
        <div>
          <RiFootballFill size={25} />
          <span>sport</span>
        </div>
        <div>
          <span style={{ background: "#0854c3", boxShadow: "0 1px 10px #083f92", borderRadius: "50px", padding: "10px" }}>
            <TbTicket size={25} style={{ transform: "rotate(90deg)" }} />
          </span>
        </div>
        <div onClick={() => navigate("/casino")}>
          <MdCasino size={25} />
          <span>casino</span>
        </div>
        <div onClick={handleChat}>
          <CiChat1 size={25} />
          <span>support chat</span>
        </div>
      </MobileNavFooterContainer>
    </React.Fragment>
  );
};

export default MobileNavFooter;
