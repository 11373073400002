import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CategoryList, CategoryListColumn, ChangeDeviceSection, ContactSectionContainer, ContactSectionPhone, ContactSectionSubtitle, ContactSectionTitle, FackbookItem, FooterBtns, FooterContainer, FooterLeftSection, FooterLogoContainer, FooterLogoSeparator, FooterMiddleSeparator, FooterMobileAppContainer, FooterMobileAppContent, FooterMobileAppText, FooterNavSection, FooterPromotionSection, FooterRightSection, FooterSeparator, InformationContainer, InformationItem, InstagramItem, NavigationSectionContainer, NavigationSectionContent, NavigationSectionContentCell, NavigationSectionContentCellName, NavigationSectionContentRow, NavigationSectionTitle, SocialList, SportPromotions, TelegramItem, TwitterItem } from './styled';
import LogoImg from '../../assets/logo.svg';
import { FaPhone } from "react-icons/fa6";
import { FaApple } from "react-icons/fa";
import { BiSolidHelpCircle } from "react-icons/bi";
import { Android } from '../../assets/svg/android';
import { PiDeviceMobileSpeaker } from "react-icons/pi";
import { BiArrowToTop, BiLogoTelegram, BiLogoInstagram, BiLogoFacebookCircle } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import uefaImg from '../../assets/image/footer/promotion/uefa.svg';
import ufcImg from '../../assets/image/footer/promotion/ufc.svg';
import wtaImg from '../../assets/image/footer/promotion/wta.svg';
import fibaImg from '../../assets/image/footer/promotion/fiba.svg';
import nhlImg from '../../assets/image/footer/promotion/nhl.svg';
import atpImg from '../../assets/image/footer/promotion/atp.svg';
import itfImg from '../../assets/image/footer/promotion/itf.svg';
import fifaImg from '../../assets/image/footer/promotion/fifa.svg';

const Footer: React.FC = () => {
    const navigate = useNavigate();

    const support_contact = [
        {
            name: "Technical support",
            mail: "support@1win.xyz"
        },
        {
            name: "Security service",
            mail: "security@1win.xyz"
        },
        {
            name: "Commercial offers",
            mail: "business@1win.xyz"
        },
        {
            name: "Affiliate program",
            mail: "partner@1win.xyz"
        }
    ]

    const information = [
        {
            name: "Rules",
            link: "/rules",
        },
        {
            name: "Promotions and bonuses",
            link: "/bonuses",
        },
        {
            name: "Mobile version",
            link: "",
        },
        {
            name: "Affiliate program",
            link: "",
        }
    ]

    const sport_promotions = [
        uefaImg,
        ufcImg,
        wtaImg,
        fibaImg,
        nhlImg,
        atpImg,
        itfImg,
        fifaImg
    ]

    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return (
        <FooterContainer>
            <FooterLogoContainer>
                <img src={LogoImg} alt='logo' width="48px" />
                <FooterLogoSeparator />
            </FooterLogoContainer>
            <FooterNavSection>
                <FooterLeftSection>
                    <ContactSectionContainer>
                        <ContactSectionTitle>24/7 support</ContactSectionTitle>
                        <ContactSectionSubtitle>Contact us if you still have any questions</ContactSectionSubtitle>
                        <ContactSectionPhone>
                            <button><FaPhone color='white' /></button>
                            <a href='#'>8 (800) 301 77-89</a>
                        </ContactSectionPhone>
                    </ContactSectionContainer>
                    <NavigationSectionContainer>
                        <NavigationSectionTitle>
                            Support contacts
                        </NavigationSectionTitle>
                        <NavigationSectionContent>
                            {
                                support_contact.map((item, index) => (
                                    <NavigationSectionContentRow key={index}>
                                        <NavigationSectionContentCellName>{item.name}</NavigationSectionContentCellName>
                                        <NavigationSectionContentCell>{item.mail}</NavigationSectionContentCell>
                                    </NavigationSectionContentRow>
                                ))
                            }
                        </NavigationSectionContent>
                    </NavigationSectionContainer>
                    <NavigationSectionContainer>
                        <NavigationSectionTitle>
                            information
                        </NavigationSectionTitle>
                        <InformationContainer>
                            {
                                information.map((item, index) => (
                                    <InformationItem key={index} onClick={() => navigate(item.link)}>{item.name}</InformationItem>
                                ))
                            }
                        </InformationContainer>
                    </NavigationSectionContainer>
                    <NavigationSectionContainer>
                        <NavigationSectionTitle>
                            Categories
                        </NavigationSectionTitle>
                        <CategoryList>
                            <CategoryListColumn>
                                <span onClick={() => navigate('/poker')}>Poker</span>
                                <span onClick={() => navigate('/casino')}>Casino</span>
                                <span onClick={() => navigate('/bonuses')}>Promotions</span>
                                <span onClick={() => navigate('/casino/list/3')}>Live-games</span>
                            </CategoryListColumn>
                        </CategoryList>
                    </NavigationSectionContainer>
                </FooterLeftSection>
                <FooterRightSection>
                    <FooterMobileAppContainer>
                        <FooterMobileAppContent>
                            <FaApple size={25} />
                            <FooterMobileAppText>
                                <span>Application</span>
                                <p>for iOS</p>
                            </FooterMobileAppText>
                        </FooterMobileAppContent>
                        <BiSolidHelpCircle color='gray' />
                    </FooterMobileAppContainer>
                    <FooterMobileAppContainer>
                        <FooterMobileAppContent>
                            <Android />
                            <FooterMobileAppText>
                                <span>Application</span>
                                <p>for Android</p>
                            </FooterMobileAppText>
                        </FooterMobileAppContent>
                        <BiSolidHelpCircle color='gray' />
                    </FooterMobileAppContainer>
                </FooterRightSection>
            </FooterNavSection>
            <FooterMiddleSeparator />
            <FooterPromotionSection>
                <SocialList>
                    <TelegramItem><BiLogoTelegram size={25} /></TelegramItem>
                    <InstagramItem><BiLogoInstagram size={25} /></InstagramItem>
                    <FackbookItem><BiLogoFacebookCircle size={25} /></FackbookItem>
                    <TwitterItem><BsTwitterX size={25} color='#000' /></TwitterItem>
                </SocialList>
                <SportPromotions>
                    {
                        sport_promotions.map((item, index) => (
                            <img src={item} key={index} alt='sport' height="25px" />
                        ))
                    }
                </SportPromotions>
                <ChangeDeviceSection>
                    <FooterBtns>
                        <PiDeviceMobileSpeaker color='white' size={25} />
                    </FooterBtns>
                    <FooterBtns onClick={toTop}>
                        <BiArrowToTop color='white' size={25} />
                    </FooterBtns>
                </ChangeDeviceSection>
            </FooterPromotionSection>
        </FooterContainer>
    )
}

export default Footer;