import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import { HiMenu } from "react-icons/hi";
import { toast } from "react-toastify";
import Drawer from "react-modern-drawer";
import {
  DropDownContent,
  DropDownTrigger,
  HeaderBalance,
  HeaderContainer,
  HeaderDepositBtn,
  HeaderItemsLeft,
  HeaderItemsRight,
  HeaderUserAvatar,
  HeaderWrapper,
  LoginBtn,
  LogoBtn,
  ProvideMenuBar,
  RegisterBtn,
  RegisterBtnIcon,
} from "./styled";
import LogoImg from "../../assets/logo.svg";
import LuckeyJetImg from "../../assets/svg/lucky-jet.svg";
import SpeedCashImg from "../../assets/svg/speed-and-cash.svg";
import AviatorImg from "../../assets/svg/aviator-game-logo.svg";
import JetXImg from "../../assets/svg/jetx.svg";
import { MdClose, MdOutlineAdd } from "react-icons/md";
import HeaderTop from "../../components/headerTop";
import { BonusModal, ConfirmModal, SettingModal } from "../../components/Modals";
import DetailingModal from "../../components/Modals/DetailingModal";
import WithdrawalModal from "../../components/Modals/WithdrawalModal";
import { LoginModal } from "../../components/Modals/LoginModal";
import { RegisterModal } from "../../components/Modals/RegisterModal";
import { HiUser } from "react-icons/hi2";
import { MdMoreVert, MdOutlineClose } from "react-icons/md";
import { googleAuthService, mailruAuthService, twitterAuthService, yandexAuthService } from "../../service";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { loginUser } from "../../API/auth";
import { getUser, getUserBalance } from "../../API/account";
import { setLogout } from "../../store/slices/auth";
import DepositModal from "../../components/Modals/DepositModal";
import { UserContext } from "../../Providers/UserContext";
import {
  DrawerMenu,
  DrawerMenuAvatar,
  DrawerMenuBody,
  DrawerMenuHeader,
  DrawerMenuList,
  DrawerMenuListDivier,
  DrawerMenuUser,
  DrawerUserInfo,
  HeaderBtns,
  HeaderContent,
  HeaderControls,
  MobileDepositBtn,
  MobileHeaderContainer,
  MobileLoginBtn,
  MobileNavigation,
  MobileProfileBtn,
  MobileRegisterBtn,
} from "../MobileHeader/styled";
import ChatWoot from "../../components/ChatWoot";
import axios from "axios";
import { BASE_ROUTE } from "../../config/api-routes";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const dropMenuRef = useRef<any>(null);
  const menuButtonRef = useRef<any>(null);
  const { isLogin, isRegister, id } = useTypedSelector((state) => state.auth);
  const { balance, email, name } = useTypedSelector((state) => state.account);
  const locate = useLocation();
  const { setMobile, setOrder, setCurrentCountry, countryCode, setCountryCode } = useContext(UserContext);
  const [isLoginModal, setLoginModal] = useState(false);
  const [isRegisterModal, setRegisterModal] = useState(false);
  const [isMenu, setMenu] = useState(false);
  const [isSettingModal, setSettingModal] = useState(false);
  const [isBonusModal, setBonusModal] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isDetailingModal, setIsDetailingModal] = useState(false);
  const [isWithdrawalModal, setIsWithdrawalModal] = useState(false);
  const [isDepositModal, setIsDepositModal] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isProfile, setPorifle] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [isChat, setChat] = useState(false);
  const updateData = useRef<ReturnType<typeof setInterval>>();

  const provider = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Casino",
      path: "/casino",
    },
    {
      name: "Live-games",
      path: "/casino/live-games",
    },
    {
      name: "luckeyjet",
      link: LuckeyJetImg,
      path: "/casino/luckeyjet",
    },
    {
      name: "speedcash",
      link: SpeedCashImg,
      path: "/casino/speedcash",
    },
    {
      name: "aviator",
      link: AviatorImg,
      path: "/casino/aviator",
    },
    {
      name: "jetx",
      link: JetXImg,
      path: "/casino/jetx",
    },
    {
      name: "Quick games",
      path: "/casino/quick",
    },
    {
      name: "Poker",
      path: "/poker",
    },
  ];

  const dropdown_item = [
    {
      title: "Bonus Code",
      tag: "bonus",
    },
    {
      title: "Withdrawal",
      tag: "withdrawal",
    },
    {
      title: "Bets History",
      tag: "history",
    },
    {
      title: "Settings",
      tag: "setting",
    },
    {
      title: "Detailing",
      tag: "detailing",
    },
    {
      title: "Log Out",
      tag: "logout",
    },
  ];

  const menuList = [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Casino",
      path: "/casino",
    },
    {
      name: "Live-games",
      path: "/casino/list/3",
    },
  ];

  const menuList2 = [
    {
      name: "Poker",
      path: "/poker",
    },
    {
      name: "Bonuses",
      path: "/casino/bonuses",
    },
  ];

  const updateAllData = () => {
    const token = localStorage.getItem("token") as string;
    updateData.current = setInterval(() => {
      dispatch(getUserBalance(token));
    }, 4000);
    axios.get(`${BASE_ROUTE}/api/payment/crypto/status`);
    dispatch(getUserBalance(token));
  };

  const startReceivingData = useCallback(updateAllData, []);
  const stopUpdating = () => clearInterval(updateData.current);

  useEffect(() => {
    if (isLogin) {
      startReceivingData();
    }
    return () => {
      stopUpdating();
    };
  }, [isLogin]);

  useEffect(() => {
    const userAgent = typeof window.navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        setCurrentCountry(data.country_name);
        setCountryCode(data.country_code);
      })
      .catch((error) => console.log(error));

    setMobile(mobile);
    setOrder(true);
  }, []);

  useEffect(() => {
    setLoginModal(false);
    const token = localStorage.getItem("token") as string;
    if (token) {
      getUserinfo(token);
    }
  }, [isLogin]);

  useEffect(() => {
    if (isRegister) {
      setRegisterModal(false);
      dispatch(setLogout());
    }
  }, [isRegister]);

  useEffect(() => {
    const params = new URLSearchParams(locate.search);
    const state: any = locate.state;

    if (locate.pathname.includes("casino/play")) {
      setChat(true);
    } else {
      setChat(false);
    }

    if (params.get("auth") === "true") {
      const token = params.get("token") as string;
      localStorage.setItem("success", "true");
      localStorage.setItem("token", token);
      getUserinfo(token);
    }

    if (params.get("success") === "true" && params.get("status") === "complete") {
      toast.success("Your payment has been successful", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (state?.modal === "login") {
      handleLogin();
    }

    if (state?.modal === "register") {
      handleRegister();
    }

    if (state?.modal === "deposit") {
      setIsDepositModal(true);
    }
  }, [locate]);

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (e: any) => {
    if (dropMenuRef.current && dropMenuRef.current.contains(e.target)) {
      return;
    } else {
      if (menuButtonRef.current && menuButtonRef.current.contains(e.target)) {
        return;
      } else {
        setMenu(false);
      }
    }
  };

  const handleProvide = (item: any) => {
    navigate(item.path);
  };

  const handleLoginModal = () => {
    setLoginModal(!isLoginModal);
    setUserData({
      email: "",
      password: "",
    });
  };

  const getUserinfo = (value: string) => {
    dispatch(getUser(value));
  };

  const handleLoginBtn = async () => {
    dispatch(loginUser(userData));
  };

  const handleRegisterBtn = async (provider: string) => {
    if (provider === "google") {
      googleAuthService();
    }
    if (provider === "mailru") {
      mailruAuthService();
    }
    if (provider === "yandex") {
      yandexAuthService();
    }
    if (provider === "twitter") {
      twitterAuthService();
    }
    if (provider === "telegram") {
      window.open("https://majestic-kringle-d4ea11.netlify.app/", "_self");
    }
  };

  const handleRegisterModal = () => {
    setRegisterModal(!isRegisterModal);
  };

  const handleSettingModal = () => {
    setSettingModal(false);
  };

  const handleBonusModal = () => {
    setBonusModal(false);
  };

  const handleConfrimModal = () => {
    setConfirmModal(false);
    setSettingModal(true);
  };

  const handleEmail = () => {
    setSettingModal(false);
    setConfirmModal(true);
  };

  const handleDropDownItem = (item: any) => {
    if (item.tag === "setting") {
      setSettingModal(true);
    }
    if (item.tag === "withdrawal") {
      setIsWithdrawalModal(true);
    }
    if (item.tag === "detailing") {
      setIsDetailingModal(true);
    }
    if (item.tag === "logout") {
      // setLogin(false);
      localStorage.removeItem("success");
      localStorage.removeItem("token");
      navigate("/");
      dispatch(setLogout());
    }
    if (item.tag === "bonus") {
      setBonusModal(true);
    }
    if (item.tag === "history") {
      navigate("/history");
    }
  };

  const handleUserData = (type: string, value: string) => {
    setUserData({ ...userData, [type]: value });
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleLogin = () => {
    setLoginModal(true);
    setUserData({
      email: "",
      password: "",
    });
  };

  const handleRegister = () => {
    setRegisterModal(true);
    setUserData({
      email: "",
      password: "",
    });
  };

  return (
    <React.Fragment>
      <HeaderWrapper>
        <HeaderTop />
        <HeaderContainer>
          <HeaderItemsLeft>
            <LogoBtn src={LogoImg} alt="logo" onClick={() => navigate("/")} />
            <ProvideMenuBar>
              {provider.map((item: any, key: number) =>
                item?.link !== undefined ? (
                  <li className={locate.pathname === item.path ? "menu-active" : ""} onClick={() => handleProvide(item)} key={key}>
                    <a>
                      <img src={item.link} alt={item.name} />
                    </a>
                  </li>
                ) : (
                  <li className={locate.pathname === item.path ? "menu-active" : ""} key={key} onClick={() => handleProvide(item)}>
                    <a>
                      <span>{item.name}</span>
                    </a>
                  </li>
                )
              )}
            </ProvideMenuBar>
          </HeaderItemsLeft>
          <HeaderItemsRight>
            {countryCode !== "" && (
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                // cdnSuffix="svg"
                title={countryCode}
                style={{ height: "1.5em", width: "1.5em" }}
              />
            )}
            {!isLogin ? (
              <>
                <LoginBtn onClick={handleLogin}>login</LoginBtn>
                <RegisterBtn onClick={handleRegister}>
                  <RegisterBtnIcon>
                    <MdOutlineAdd />
                  </RegisterBtnIcon>
                  Registration
                </RegisterBtn>
              </>
            ) : (
              <>
                <HeaderBalance>
                  <label>Balance</label>
                  <span>
                    {balance.toLocaleString("en-US", {
                      style: "currency",
                      currency: "ZAR",
                    })}
                  </span>
                </HeaderBalance>
                <DropDownTrigger ref={menuButtonRef} onClick={() => setMenu(!isMenu)}>
                  <HeaderUserAvatar>
                    <HiUser />
                  </HeaderUserAvatar>
                  {isMenu ? <MdOutlineClose /> : <MdMoreVert />}
                  {isMenu && (
                    <DropDownContent ref={dropMenuRef}>
                      {dropdown_item.map((item, key) => (
                        <div key={key} onClick={() => handleDropDownItem(item)}>
                          {item.title}
                        </div>
                      ))}
                    </DropDownContent>
                  )}
                </DropDownTrigger>
                <HeaderDepositBtn onClick={() => setIsDepositModal(true)}>Deposit</HeaderDepositBtn>
              </>
            )}
          </HeaderItemsRight>
        </HeaderContainer>
      </HeaderWrapper>
      <MobileHeaderContainer>
        <HeaderContent>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <LogoBtn src={LogoImg} alt="logo" onClick={() => navigate("/")} />
            {countryCode !== "" && (
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                // cdnSuffix="svg"
                title={countryCode}
                style={{ height: "1.25em", width: "1.25em" }}
              />
            )}
          </div>
          <HeaderControls>
            {isLogin && (
              <>
                <HeaderBalance>
                  <label>Balance</label>
                  <span>
                    {balance &&
                      balance.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ZAR",
                      })}
                  </span>
                </HeaderBalance>
                <MobileDepositBtn onClick={() => navigate("/deposit")}>Deposit</MobileDepositBtn>
                <MobileProfileBtn>
                  {isProfile ? (
                    <MdClose size={25} onClick={() => navigate(-1)} />
                  ) : (
                    <HiUser size={15} onClick={() => navigate("/profile")} />
                  )}
                </MobileProfileBtn>
              </>
            )}
            <HiMenu onClick={toggleDrawer} size={30} />
          </HeaderControls>
        </HeaderContent>
        {!isLogin && (
          <HeaderBtns>
            <MobileLoginBtn onClick={handleLogin}>Login</MobileLoginBtn>
            <MobileRegisterBtn onClick={handleRegister}>Registration</MobileRegisterBtn>
          </HeaderBtns>
        )}
        <MobileNavigation>
          <section>
            {provider.map((item: any, key: number) =>
              item.link !== undefined ? (
                <a onClick={() => handleProvide(item)} className={locate.pathname === item.path ? "mobile-menu-active" : ""} key={key}>
                  <img src={item.link} alt={item.name} />
                </a>
              ) : (
                <a onClick={() => handleProvide(item)} className={locate.pathname === item.path ? "mobile-menu-active" : ""} key={key}>
                  <span>{item.name}</span>
                </a>
              )
            )}
          </section>
        </MobileNavigation>
      </MobileHeaderContainer>
      <LoginModal
        isOpen={isLoginModal}
        handleClose={handleLoginModal}
        handleLogin={handleLoginBtn}
        handleRegister={handleRegisterModal}
        userData={userData}
        setUserData={handleUserData}
      />
      <RegisterModal
        isOpen={isRegisterModal}
        handleClose={handleRegisterModal}
        handleLogin={handleLoginModal}
        handleRegister={handleRegisterBtn}
        userData={userData}
        setUserData={handleUserData}
      />
      <SettingModal isOpen={isSettingModal} handleClose={handleSettingModal} handleEmail={handleEmail} />
      <WithdrawalModal isOpen={isWithdrawalModal} handleClose={() => setIsWithdrawalModal(false)} />
      <DepositModal isOpen={isDepositModal} handleClose={() => setIsDepositModal(false)} />
      <DetailingModal isOpen={isDetailingModal} handleClose={() => setIsDetailingModal(false)} />
      <BonusModal isOpen={isBonusModal} handleClose={handleBonusModal}></BonusModal>
      <ConfirmModal isOpen={isConfirmModal} handleClose={handleConfrimModal}></ConfirmModal>
      <Drawer open={isOpen} onClose={toggleDrawer} direction="right" className="drawer-container">
        <DrawerMenu>
          <DrawerMenuHeader>
            <DrawerMenuUser>
              <DrawerMenuAvatar>
                <HiUser />
              </DrawerMenuAvatar>
              {isLogin ? (
                <DrawerUserInfo>
                  <span>{name}</span>
                  <label>ID 53020781</label>
                </DrawerUserInfo>
              ) : (
                <RegisterBtn
                  onClick={() => {
                    setIsOpen(false);
                    setRegisterModal(true);
                  }}
                >
                  Registration
                </RegisterBtn>
              )}
            </DrawerMenuUser>
            <MdClose onClick={toggleDrawer} size={25} />
          </DrawerMenuHeader>
          <DrawerMenuBody>
            <DrawerMenuList>
              {menuList.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                    toggleDrawer();
                  }}
                >
                  <div />
                  <span>{item.name}</span>
                </div>
              ))}
            </DrawerMenuList>
            <DrawerMenuListDivier />
            <DrawerMenuList>
              {menuList2.map((item, index) => (
                <div
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                    toggleDrawer();
                  }}
                >
                  <div />
                  <span>{item.name}</span>
                </div>
              ))}
            </DrawerMenuList>
          </DrawerMenuBody>
        </DrawerMenu>
      </Drawer>
      <ChatWoot />
    </React.Fragment>
  );
};

export default Header;
