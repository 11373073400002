import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";

import Landing from "./pages/Landing";
import Casino from "./pages/Casino";
import Play from "./pages/Casino/play";
import Poker from "./pages/Poker";
import FreeMoney from "./pages/FreeMoney";
import Bonus from "./pages/Bonus";
import Rules from "./pages/Rules";
import History from "./pages/History";
import Profile from "./pages/Profile";
import Detail from "./pages/Detail";
import Deposit from "./pages/Deposit";
import Withdrawal from "./pages/Withdrawal";
import Setting from "./pages/Setting";
import Favourites from "./pages/Favourites";

function Routing() {
  return (
    <GoogleOAuthProvider clientId="975274840157-bck021ekhefp0fpfsalg9vhsr8mpk34b.apps.googleusercontent.com">
      <Router>
        <Routes>
          <Route path="/" Component={Landing} />
          <Route path="/casino/*" Component={Casino} />
          <Route path="/play/mobile" Component={Play} />
          <Route path="/poker" Component={Poker} />
          <Route path="/free-money" Component={FreeMoney} />
          <Route path="/bonuses" Component={Bonus} />
          <Route path="/rules/*" Component={Rules} />
          <Route path="/history" Component={History} />
          <Route path="/profile" Component={Profile} />
          <Route path="/profile/edit" Component={Setting} />
          <Route path="/deposit" Component={Deposit} />
          <Route path="/money/withdrawal" Component={Withdrawal} />
          <Route path="/detailing" Component={Detail} />
          <Route path="/favourites" Component={Favourites} />
          <Route path="/close.html" Component={Casino} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default Routing;
