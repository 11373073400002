import styled from "styled-components";

type Props = {
  imgurl?: string;
};

type ColorProps = {
  flag?: boolean;
};

export const CasinoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CasinoContainer = styled.div`
  padding: 15px;
  display: flex;
  gap: 15px;
`;

export const CasinoNavigation = styled.div`
  background-color: #141b2f;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  min-width: 270px;
  gap: 15px;
  height: calc(100vh - 135px);
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const CasinoNavigationSearch = styled.div`
  display: flex;
  border-radius: 10px;
  background-color: #1f2941;
  padding: 10px;
  margin: 15px 15px 0 15px;
  input {
    margin-left: 10px;
    width: 100%;
    background-color: #1f2941;
    outline: none;
    border: none;
    color: #7388b6;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.19px;
    &::placeholder {
      color: #7388b6;
    }
  }
`;

export const CasinoNavigationBody = styled.div`
  margin-bottom: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0 15px;
  &::-webkit-scrollbar-track {
    background: #141b2f;
  }
  &::-webkit-scrollbar {
    height: 0.4rem;
    width: 0.4rem;
  }
`;

export const CasinoNavigationBodyTitle = styled.span`
  color: #7388b6;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: -0.12px;
  opacity: 0.3;
  text-transform: uppercase;
  text-align: start;
`;

export const HotPopularBtns = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  button {
    align-items: center;
    border-radius: 8px;
    display: flex;
    border: none;
    width: 100%;
    padding: 10px 15px;
  }
`;

export const HotButton = styled.button`
  background: linear-gradient(250deg, #26223c, rgba(255, 23, 183, 0.4) 84%, #4b2b66);
`;

export const PopularButton = styled.button`
  background: linear-gradient(250deg, #1c2837, rgba(0, 168, 88, 0.3) 84%, #194353);
`;

export const HotPopularButtonText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-left: 10px;
  color: white;
  gap: 2px;
`;

export const BtnTextTitle = styled.span`
  font-size: 11px;
  font-weight: 600;
`;

export const BtnTextValue = styled.span`
  font-size: 10px;
  letter-spacing: 0.23px;
  opacity: 0.5;
`;

export const CasinoNavigationBodyList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CasinoNavigationBodyItem = styled.div<ColorProps>`
  align-items: center;
  display: flex;
  padding: 10px;
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  background-image: ${(props) => (props.flag ? "linear-gradient(260deg, #00ff86, #00a858 48%, #00a858);" : "")};
  &:not(:last-child)::before {
    background-color: #262e4880;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    right: 0;
    position: absolute;
  }
  &:hover {
    background-color: #1f2941;
  }
`;

export const BodyItemIcon = styled.div`
  width: 25px;
  height: 25px;
`;

export const BodyItemName = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.28px;
  margin-left: 12px;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BodyItemCount = styled.span`
  color: #7388b6;
  font-size: 10px;
  letter-spacing: 0.23px;
  margin-left: auto;
  text-align: right;
`;

export const CasinoMain = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

export const JackpotPanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  width: 100%;
  gap: 15px;
`;

export const JackpotPanel = styled.div`
  padding: 0 15px 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  color: #fff;
  opacity: 1;
  position: relative;
  z-index: 1;
  cursor: pointer;
  width: 30%;
  &::before {
    border-radius: 12px;
    z-index: -2;
    inset: 0;
    background: radial-gradient(circle at -65% 157%, #c5991e, #b68100 7%, #fff29d 66%, #b57f00 113%, #c7940f 138%);
    content: "";
    position: absolute;
  }
  &::after {
    border-radius: 10px;
    z-index: -1;
    inset: 2px;
    background: #090f1f;
    box-shadow: 0 9px 17px #d6b34700, inset 0 1px 29px #000;
    content: "";
    position: absolute;
  }
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const JackpotPanelTitle = styled.div`
  align-items: center;
  display: flex;
  margin-top: auto;
  span {
    font-size: 2vw;
    font-weight: 900;
    letter-spacing: -1.96px;
    text-shadow: 0 2px 87px #0c1820;
  }
`;

export const JackpotSlider = styled.div`
  position: relative;
  width: 70%;
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`;

export const JackpotImageSliderContent = styled.div<Props>`
  background: url(${(props) => props.imgurl}) no-repeat;
  border-radius: 12px;
  width: 100%;
  height: 20vw;
  background-size: 100% 100%;
  cursor: pointer;
  @media screen and (max-width: 1199px) {
    height: 30vw;
  }
`;

export const CasinoHome = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const CasinoHomeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const CasinoHomeSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CasinoHomeSectionHeaderLeft = styled.div`
  display: flex;
  align-items: flex-end;
  align-self: stretch;
  span {
    font-size: 25px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: -0.408px;
    color: #fff;
  }
  label {
    height: 17px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.288px;
    color: #fff;
    opacity: 0.5;
    margin-left: 10px;
  }
`;

export const CasinoHomeSectionHeaderRight = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  span {
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    color: #0085ff;
    cursor: pointer;
  }
`;

export const CasinoHomeSectionSlider = styled.div`
  width: 100%;
`;

export const CasinoHomeSectionSliderItem = styled.div`
  width: 100%;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  z-index: 2;
  height: 10vw;
  background-color: #7388b6;
  min-height: 150px;
  img {
    cursor: pointer;
  }
`;

export const IframeWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 135px);
  /* height: 100%; */
  background-color: #000;
`;

export const MobileIframeWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #000;
`;

export const IframeHeader = styled.div`
  min-height: 50px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  font-weight: bold;
  font-size: 17px;
  position: relative;
`;

export const IframeBody = styled.div`
  width: 100%;
  height: calc(100% - 56px);
  position: relative;
  position: absolute;
`;

export const GameListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
  @media screen and (max-width: 1630px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 1250px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (max-width: 1050px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media screen and (max-width: 890px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 320px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
