import React from "react";
import { Provider } from "react-redux";
import { IntlProvider } from "react-intl";

import Routing from "./Routing";
import store from "./store";
import UserProvider from "./Providers/UserContext";
import ChatWoot from "./components/ChatWoot";

import "./App.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

function App() {
  const locale = navigator.language.split("-")[0];

  return (
    <Provider store={store}>
      <div className="App">
        <IntlProvider locale={locale}>
          <UserProvider>
            <Routing />
          </UserProvider>
        </IntlProvider>
        <ChatWoot />
      </div>
    </Provider>
  );
}

export default App;
