import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth';
import accountReducer from './slices/account';

const store = configureStore({
    reducer: {
        auth: authReducer,
        account: accountReducer
    }
})

export default store


export type ActionWithPayload<T extends string, P> = { type: T; payload: P };
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;