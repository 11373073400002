import React from 'react';

export const Popular: React.FC = () => {
    return (
        <svg data-v-dcb2f604="" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" aria-hidden="true" role="img" className="icon icon-casino-category-popular sm hotpopular-button-icon hotpopular-button-icon">
            <defs>
                <linearGradient id="popular_svg__a" x1="85.499%" x2="50%" y1="0%" y2="100%">
                    <stop offset="0%" stopColor="#00FF86"></stop><stop offset="100%" stopColor="#00A858"></stop>
                </linearGradient>
            </defs>
            <path fill="none" d="M0 0h21v21H0z"></path>
            <path className="popular_svg__primary" fill="url(#popular_svg__a)" d="M10.5 21C16.299 21 21 16.299 21 10.5S16.299 0 10.5 0 0 4.701 0 10.5 4.701 21 10.5 21zm0-1.75a8.75 8.75 0 110-17.5 8.75 8.75 0 010 17.5z"></path>
            <path fill="#FFF" d="M14.077 12.25a.725.725 0 01.712.87l-.047.205a4.377 4.377 0 01-8.527-.19l-.003-.04-.001-.04c0-.445.36-.805.804-.805zm-6.9-6.455a.4.4 0 01.182.182l.412.836.923.134a.4.4 0 01.222.683l-.668.65.158.92a.4.4 0 01-.58.422L7 9.188l-.826.434a.4.4 0 01-.58-.422l.158-.92-.668-.65a.4.4 0 01.222-.683l.923-.134.412-.836a.4.4 0 01.536-.182zm7 0a.4.4 0 01.182.182l.412.836.923.134a.4.4 0 01.222.683l-.668.65.158.92a.4.4 0 01-.58.422L14 9.188l-.826.434a.4.4 0 01-.58-.422l.158-.92-.668-.65a.4.4 0 01.222-.683l.923-.134.412-.836a.4.4 0 01.536-.182z"></path>
        </svg>
    );
};