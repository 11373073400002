import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { ErrorType } from ".";
import { toast } from "react-toastify";

type LoginUserType = {
  email: string;
  password: string;
};

export type AxiosResponseLogin = {
  id: string;
  email: string;
  verified: boolean;
  provider: string;
  socialID: string;
};

export type UserMessage = {
  message: string;
};

export const routes = {
  login: "/api/users/signin",
  register: "/api/users/signup",
};

export const loginUser = createAsyncThunk<
  AxiosResponseLogin,
  LoginUserType,
  {
    rejectValue: ErrorType;
  }
>("/auth/loginUser", async (loginData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.login}`, loginData);
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("success", response.data.success);
    toast.success("Success Login", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return response.data;
  } catch (e: any) {
    const err = e.response.data.msg;
    toast.warning(err, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return rejectWithValue({
      message: err,
    } as ErrorType);
  }
});

export const registerUser = createAsyncThunk<
  UserMessage,
  LoginUserType,
  {
    rejectValue: ErrorType;
  }
>("auth/resetUser", async (registerData, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.register}`, registerData);
    toast.success("Success register user", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return response.data;
  } catch (e: any) {
    toast.warning(e.response.data.msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
