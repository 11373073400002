import styled from "styled-components";
import FreeMonenyLinkImg from "../../assets/image/free-money-link-image.png";
import FreeMoneyCoinImg from "../../assets/image/free-money-link-coin.png";

type Props = {
  imgurl?: string;
};

type BarProps = {
  bartype?: number;
};

export const LandingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const LandingHeader = styled.div`
  display: flex;
  gap: 15px;
  padding: 0 15px;
`;

export const ImageSlide = styled.div`
  width: 60%;
  position: relative;
  @media screen and (max-width: 1199px) {
    width: 100%;
  }
`;

export const BoxContainer = styled.div<Props>`
  background: url(${(props) => props.imgurl}) no-repeat;
  width: 20%;
  border-radius: 12px;
  background-size: 100% 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  span {
    width: 100%;
    line-height: 106%;
    white-space: pre-line;
    font-weight: 600;
    font-size: 35px;
    letter-spacing: 0.33px;
    text-shadow: 0 3px 5px rgba(9, 15, 30, 0.2);
    text-align: left;
  }
  button {
    width: 100%;
    background-color: #fff;
    border: none;
    box-shadow: 0 10px 35px #0003;
    border-radius: 10px;
    font-weight: 600;
    white-space: nowrap;
    mix-blend-mode: lighten;
    min-height: 45px;
    text-align: center;
    font-size: 23px;
    padding: 10px;
    cursor: pointer;
  }
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const LandingContent = styled.div`
  padding: 0 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const LandingPromos = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  width: 100%;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const PromoCard = styled.div`
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  background-image: linear-gradient(110deg, #1e283f, rgba(20, 27, 46, 0.6) 100%);
  border-radius: 12px;
  cursor: pointer;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CardHeader = styled.span`
  font-size: 24px;
  letter-spacing: 0.34px;
  font-weight: 600;
  line-height: 1;
`;

export const CardMessage = styled.span`
  font-size: 13px;
  letter-spacing: -0.21px;
  margin-top: 3px;
  max-width: 170px;
`;

export const CardSvg = styled.div`
  width: 60px;
  height: 60px;
  background: gray;
  border-radius: 12px;
`;

export const BannerSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
`;

export const BannerSectionItem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-image: linear-gradient(110deg, #1e283f, rgba(20, 27, 46, 0.6) 100%);
  width: 50%;
  padding: 0 25px;
  gap: 15px;
  position: relative;
  padding-bottom: 25px;
`;

export const BarContainer = styled.div<BarProps>`
  width: 100%;
  background-image: ${(props) =>
    props.bartype === 1
      ? "linear-gradient(90deg, rgb(27, 241, 255) 0%, rgb(8, 85, 196) 100%)"
      : props.bartype === 2
      ? "linear-gradient(90deg, rgb(141, 83, 233) 0%, rgb(0, 183, 255) 100%)"
      : props.bartype === 3
      ? "linear-gradient(90deg, rgb(0, 183, 255) 0%, rgb(199, 0, 255) 50%, rgb(255, 187, 0) 100%)"
      : "linear-gradient(90deg, rgb(255, 78, 223) 0%, rgb(255, 41, 88) 100%)"};
  height: 4px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  background-size: 200% auto;
  animation: 4s linear 0s infinite alternate notch-bg-slide-05b89ab6;
  will-change: background-position;
`;

export const SectionCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SectionCardLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  span {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.41px;
  }
  cursor: pointer;
`;

export const SectionCardLeftContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 10px;
  p {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
  }
  span {
    font-size: 12px !important;
    opacity: 0.5;
    letter-spacing: -0.29px;
  }
`;

export const SectionCardRight = styled.div`
  display: flex;
  align-items: center;
  color: #1a68db;
  cursor: pointer;
`;

export const CasinoFilterContent = styled.div`
  display: flex;
  width: 100%;
`;

export const SectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  button {
    background-image: linear-gradient(255deg, #00b7ff, #8d53e9);
    padding: 15px 25px;
    cursor: pointer;
    border: none;
    border-radius: 7px;
    font-size: 15px;
    color: white;
    font-weight: 600;
  }
`;

export const GameCardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
`;

export const GameCard = styled.div`
  background: gray;
  border-radius: 12px;
  height: 8rem;
  cursor: pointer;
  img {
    cursor: pointer;
  }
`;

export const MobilePromotionLinkContainer = styled.div`
  display: none;
  padding: 0 15px;
  margin: 10px 0;
  @media screen and (max-width: 1199px) {
    display: flex;
  }
`;

export const FreeMoneyBtn = styled.div`
  background-image: linear-gradient(283.27deg, #ffb800 0%, #fff173 138.82%);
  box-shadow: 0 5px 15px #ffd91366;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
  border-radius: 12px;
  position: relative;
  width: 100%;
  margin-right: 5px;
  text-align: start;
`;

export const FreeMoneyLink = styled.span`
  width: 117px;
  height: 43px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  position: absolute;
  right: 0;
  bottom: 0;
  border-bottom-right-radius: 8px;
  background-image: url(${FreeMonenyLinkImg});
`;

export const FreeMoneyCoin = styled.span`
  width: 26px;
  height: 29px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 2px;
  left: -10px;
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.21)) drop-shadow(-1px 0 6px rgba(251, 226, 147, 0.6));
  background-image: url(${FreeMoneyCoinImg});
`;

export const FreeMoneyHeader = styled.span`
  display: block;
  font-size: 13px;
  font-weight: 600;
  color: #090f1e;
  margin-bottom: 2px;
`;

export const FreeMoneySubHeader = styled.span`
  display: block;
  color: #090f1ecc;
  opacity: 0.9;
  font-weight: 600;
  font-size: 9px;
`;

export const PromotionBtn = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: linear-gradient(86.37deg, #d062ff 2.96%, #7bb0ff 99.68%), linear-gradient(90deg, #ed6ea0 0%, #ec8c69 100%);
  box-shadow: 0 5px 15px #c955ff80;
  border-radius: 12px;
  padding-left: 12px;
  width: 100%;
  img {
    position: absolute;
    height: 80px;
    right: -15px;
    top: -20px;
    pointer-events: none;
  }
`;

export const BonuseLinkCircleText = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align: start;
`;

export const BonuseLinkHeader = styled.span`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`;

export const BonuseLinkSubHeader = styled.span`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  white-space: nowrap;
`;

export const MobileHomeContainer = styled.div`
  display: none;
  /* padding: 0 15px; */
  @media screen and (max-width: 1199px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MobileHomeSectionContainer = styled.div`
  margin-bottom: 20px;
`;

export const HomeSectionHeader = styled.div`
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HomeSectionHeaderTitle = styled.div`
  font-size: 19px;
  font-weight: 700;
`;

export const HomeSectionHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  label {
    color: #0085ff;
    font-size: 13px;
    font-weight: 500;
  }
  span {
    color: #94a6cd;
    font-size: 11px;
    text-transform: lowercase;
  }
`;

export const HomeSectionGameList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: column;
  grid-auto-columns: 150px;
  grid-gap: 15px;
  overflow-x: auto;
  box-sizing: content-box;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  scroll-padding: 0 15px;
  @media screen and (max-width: 320px) {
    grid-template-columns: repeat(auto-fill, 180px);
    grid-auto-columns: 180px;
  }
`;

export const HomeSectionGames = styled.div`
  width: 100%;
  text-align: start;
  div {
    border-radius: 10px;
    background-color: #94a6cd;
    height: 120px;
    margin-bottom: 5px;
    @media screen and (max-width: 320px) {
      height: 180px;
    }
  }
  span {
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
  }
  img {
    cursor: pointer;
  }
`;
