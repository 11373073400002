import styled from "styled-components";

export const HeaderTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
`

export const HeaderLineLeft = styled.div`
    display: flex;
    gap: 10px;
`

export const LevelItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`

export const HeaderButton = styled.button`
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(78,93,134,.3);
    transition: 2s;
    border: none;
    border-radius: 7px;
    cursor: pointer;
`

export const FreeMoney = styled.div`
    border-radius: 50px;
    background-image: linear-gradient(285.39deg,#ffb800 -4.36%,#fff173 115.87%);
    font-weight: 500;
    font-size: 11px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding-right: 7px;
    cursor: pointer;
    img {
        display: block;
        width: 60px;
        height: 33px;
        background-size: 100%;
        background-repeat: no-repeat;
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: -6px;
    }

    span {
        color: #090f1e;
        padding-left: 60px;
    }
`

export const HeaderLineRight = styled.div`
    display: flex;
    gap: 10px;
`

export const BonusLink = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;
    background-color: #4e5d864d;
    padding-right: 5px;
    border-radius: 5px;
    cursor: pointer;
    span {
        font-weight: 600;
        font-size: 10px;
        line-height: 13px;
    }
    img {
        position: absolute;
        height: 50px;
        transform: rotate(-13deg);
        right: -17px;
        top: -17px;
        pointer-events: none;
    }
`

export const BonusLinkCircle = styled.div`
    background: linear-gradient(86.37deg,#d062ff 2.96%,#7bb0ff 99.68%),linear-gradient(90deg,#ed6ea0 0%,#ec8c69 100%);
    box-shadow: 0 5px 15px #c955ff66;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`

export const ApplicationBtns = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4e5d864d;
    padding: 3px 5px;
    border-radius: 7px;
    gap: 5px;
    cursor: pointer;
`