import React from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineLeft } from "react-icons/ai";

import Header from "../../layout/Header";
import { Flex } from "../../components/Modals/styled";
import { AddEventButton } from "./styled";

export default function Favourites() {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <Flex direction="column" style={{ padding: "15px", textAlign: "left" }}>
        <Flex
          direction="row"
          alignItems="center"
          style={{ gap: "6px", margin: "5px 0", cursor: "pointer" }}
          onClick={() => navigate("/profile")}
        >
          <AiOutlineLeft style={{ width: "13px", height: "13px" }} />
          <div style={{ fontSize: "13px" }}>Main</div>
        </Flex>
        <div style={{ fontSize: "24px", fontWeight: 700, margin: "15px 0" }}>Favourites</div>
        <Flex direction="column" alignItems="center" justifyContent="center" style={{ padding: "40px 0" }}>
          <div style={{ marginBottom: "35px" }}>
            <svg
              data-v-2d3fe494=""
              width="80"
              height="80"
              viewBox="0 0 80 80"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              className="icon icon-no-content-star sm square message-block-icon message-block-icon"
            >
              <defs>
                <linearGradient x1="0%" y1="100%" y2="0%" id="star_svg__a">
                  <stop stop-color="#0095FF" offset="0%"></stop>
                  <stop stop-color="#0854C3" offset="100%"></stop>
                </linearGradient>
              </defs>
              <g transform="translate(-3 9)" fill="url(#star_svg__a)">
                <path
                  d="M21.5 45.25l-9.05 4.758A2 2 0 019.548 47.9l1.728-10.078-7.322-7.137a2 2 0 011.109-3.412l10.118-1.47 4.526-9.169a2 2 0 013.586 0l4.526 9.17 10.118 1.47a2 2 0 011.109 3.41l-7.322 7.138L33.452 47.9a2 2 0 01-2.902 2.108L21.5 45.25zm43 0l-9.05 4.758a2 2 0 01-2.902-2.108l1.728-10.078-7.322-7.137a2 2 0 011.109-3.412l10.118-1.47 4.526-9.169a2 2 0 013.586 0l4.526 9.17 10.118 1.47a2 2 0 011.109 3.41l-7.322 7.138L76.452 47.9a2 2 0 01-2.902 2.108L64.5 45.25z"
                  opacity=".2"
                ></path>
                <path d="M43 49.5l-15.81 8.312a2 2 0 01-2.902-2.109l3.02-17.604L14.517 25.63a2 2 0 011.108-3.411l17.677-2.569 7.905-16.017a2 2 0 013.586 0l7.905 16.017 17.677 2.569a2 2 0 011.108 3.411L58.693 38.1l3.019 17.604a2 2 0 01-2.902 2.109L43 49.5z"></path>
              </g>
            </svg>
          </div>
          <div style={{ fontSize: "24px", fontWeight: 700 }}>No nothing</div>
          <div style={{ fontSize: "14px", color: "#dadada" }}>
            Add important events, tournaments and games to your favorites to have quick access to them
          </div>
          <AddEventButton>Add event</AddEventButton>
        </Flex>
      </Flex>
    </div>
  );
}
