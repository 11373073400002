import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CasinoHome,
  CasinoHomeSection,
  CasinoHomeSectionHeader,
  CasinoHomeSectionHeaderLeft,
  CasinoHomeSectionHeaderRight,
  CasinoHomeSectionSlider,
  CasinoHomeSectionSliderItem,
} from "./styled";
import {
  MobileHomeContainer,
  MobileHomeSectionContainer,
  HomeSectionHeader,
  HomeSectionHeaderTitle,
  HomeSectionHeaderContent,
  HomeSectionGameList,
  HomeSectionGames,
} from "../Landing/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { UserContext } from "../../Providers/UserContext";

import ogsGameList from "../../config/gameList.json";
import newGameList from "../../config/newGame.json";

interface Props {
  categories: any;
  category: any;
  setCategory: (category: string) => void;
}

const CategoryList: React.FC<Props> = ({ categories, category, setCategory }: any) => {
  const navigate = useNavigate();
  const { netentGame, playtechGame, amaticGame, novomaticGame, ainsworthGame, quickspinGame, aristocratGame, egtGame } =
    useContext(UserContext);

  const handleGamePlay = (games: any, provider: string, item?: string) => {
    if (item === "news") {
      // console.log(games);

      navigate(`/casino/play/${games.gameCode}`, { state: { gameItem: games, provider, api: item } });
    } else if (item === "ogs") {
      console.log(games);

      if (provider === "EGT2" || provider === "ARISTOCRAT2") {
        navigate(`/casino/play/${games.gameId}`, { state: { gameItem: games, provider, api: item } });
      } else {
        navigate(`/casino/play/${games.gameName}`, { state: { gameItem: games, provider, api: item } });
      }
    } else {
      navigate(`/casino/play/${games[1]}`, { state: { gameItem: games, provider } });
    }
  };

  return (
    <React.Fragment>
      <CasinoHome>
        {categories.map((item: any, key: number) => (
          <CasinoHomeSection key={key}>
            <CasinoHomeSectionHeader>
              <CasinoHomeSectionHeaderLeft>
                <span>{item.name}</span>
                <label>
                  {item.name === "New Games" && newGameList.length}
                  {item.name === "NETENT" && netentGame.length}
                  {item.name === "PLAYTECH" && playtechGame.length}
                  {item.name === "AMATIC" && amaticGame.length}
                  {item.name === "NOVOMATIC" && novomaticGame.length}
                  {item.name === "Ainsworth" && ainsworthGame.length}
                  {item.name === "Quickspin" && quickspinGame.length}
                  {item.name === "Aristocrat" && aristocratGame.length}
                  {item.name === "EGT" && egtGame.length}
                  {item.name === "NETENT2" && ogsGameList.filter((item) => item.provider === "NETENT2").length}
                  {item.name === "AMATIC2" && ogsGameList.filter((item) => item.provider === "AMATIC2").length}
                  {item.name === "NOVOMATIC2" && ogsGameList.filter((item) => item.provider === "NOVOMATIC2").length}
                  {item.name === "ARISTOCRAT2" && ogsGameList.filter((item) => item.provider === "ARISTOCRAT2").length}
                  {item.name === "EGT2" && ogsGameList.filter((item) => item.provider === "EGT2").length}
                  {item.name === "Merkur" && ogsGameList.filter((item) => item.provider === "Merkur").length}
                  {item.name === "PlayNGo" && ogsGameList.filter((item) => item.provider === "PlayNGo").length}
                  {item.name === "PragmaticPlay" && ogsGameList.filter((item) => item.provider === "PragmaticPlay").length}
                  {item.name === "River" && ogsGameList.filter((item) => item.provider === "River").length}
                  {item.name === "Scientific" && ogsGameList.filter((item) => item.provider === "Scientific").length}
                  {item.name === "Yggdrasil" && ogsGameList.filter((item) => item.provider === "Yggdrasil").length}
                  {item.name === "Zeppelin" && ogsGameList.filter((item) => item.provider === "Zeppelin").length}
                </label>
              </CasinoHomeSectionHeaderLeft>
              <CasinoHomeSectionHeaderRight>
                <span onClick={() => setCategory(item)}>All</span>
              </CasinoHomeSectionHeaderRight>
            </CasinoHomeSectionHeader>
            <CasinoHomeSectionSlider>
              <Swiper
                slidesPerView={4}
                spaceBetween={15}
                className="mySwiper"
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                  1630: {
                    slidesPerView: 7,
                    spaceBetween: 15,
                  },
                  1440: {
                    slidesPerView: 6,
                    spaceBetween: 15,
                  },
                  1250: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                  },
                }}
              >
                {item.name === "New Games" &&
                  newGameList.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name, "news")}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`/game-icon/${game.gameCode}.png`}
                              alt={game.gameName}
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "NETENT" &&
                  netentGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="netent icons"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "PLAYTECH" &&
                  playtechGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="playtech icons"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "AMATIC" &&
                  amaticGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="amatic icons"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "NOVOMATIC" &&
                  novomaticGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="novomatic icons"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "Ainsworth" &&
                  ainsworthGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="ainsworth"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "Quickspin" &&
                  quickspinGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="quickspin"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "Aristocrat" &&
                  aristocratGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="aristocrat"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {item.name === "EGT" &&
                  egtGame.map(
                    (game, index) =>
                      index < 12 && (
                        <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name)}>
                          <CasinoHomeSectionSliderItem>
                            <img
                              src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                              alt="egt"
                              width="100%"
                              height="100%"
                              style={{ borderRadius: "16px" }}
                            />
                          </CasinoHomeSectionSliderItem>
                        </SwiperSlide>
                      )
                  )}
                {(item.name === "NETENT2" ||
                  item.name === "AMATIC2" ||
                  item.name === "NOVOMATIC2" ||
                  item.name === "ARISTOCRAT2" ||
                  item.name === "EGT2" ||
                  item.name === "Merkur" ||
                  item.name === "PlayNGo" ||
                  item.name === "PragmaticPlay" ||
                  item.name === "River" ||
                  item.name === "Scientific" ||
                  item.name === "Yggdrasil" ||
                  item.name === "Zeppelin") &&
                  ogsGameList
                    .filter((category) => category.provider === item.name)
                    .map(
                      (game, index) =>
                        index < 12 && (
                          <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name, "ogs")}>
                            <CasinoHomeSectionSliderItem>
                              <img src={game.iconUrl} alt={item.name} width="100%" height="100%" style={{ borderRadius: "16px" }} />
                            </CasinoHomeSectionSliderItem>
                          </SwiperSlide>
                        )
                    )}
              </Swiper>
            </CasinoHomeSectionSlider>
          </CasinoHomeSection>
        ))}
      </CasinoHome>
      <MobileHomeContainer>
        {categories.map((item: any, key: number) => (
          <MobileHomeSectionContainer key={item.path}>
            <HomeSectionHeader>
              <HomeSectionHeaderTitle>{item.name}</HomeSectionHeaderTitle>
              <HomeSectionHeaderContent>
                <label onClick={() => setCategory(item)}>All</label>
                <span>
                  {item.name === "New Games" && newGameList.length}
                  {item.name === "NETENT" && netentGame.length}
                  {item.name === "PLAYTECH" && playtechGame.length}
                  {item.name === "AMATIC" && amaticGame.length}
                  {item.name === "NOVOMATIC" && novomaticGame.length}
                  {item.name === "Ainsworth" && ainsworthGame.length}
                  {item.name === "Quickspin" && quickspinGame.length}
                  {item.name === "Aristocrat" && aristocratGame.length}
                  {item.name === "EGT" && egtGame.length} games
                </span>
              </HomeSectionHeaderContent>
            </HomeSectionHeader>
            <HomeSectionGameList>
              {item.name === "New Games" &&
                newGameList.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={index} onClick={() => handleGamePlay(game, item.name, "news")}>
                        <div>
                          <img
                            src={`/game-icon/${game.gameCode}.png`}
                            alt="netent"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game.gameName}</span>
                      </HomeSectionGames>
                    )
                )}

              {item.name === "NETENT" &&
                netentGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="netent"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "PLAYTECH" &&
                playtechGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="playtech"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "AMATIC" &&
                amaticGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="amatic"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "NOVOMATIC" &&
                novomaticGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="novomatic"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "Ainsworth" &&
                ainsworthGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="ainsworth"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "Quickspin" &&
                quickspinGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="quickspin"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "Aristocrat" &&
                aristocratGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="aristorcrat"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {item.name === "EGT" &&
                egtGame.map(
                  (game, index) =>
                    index < 12 && (
                      <HomeSectionGames key={game[1]} onClick={() => handleGamePlay(game, item.name)}>
                        <div>
                          <img
                            src={`https://egt.depot888.com/images/${item.path}/${game[1]}.png`}
                            alt="egt"
                            width="100%"
                            height="100%"
                            style={{ borderRadius: "10px" }}
                          />
                        </div>
                        <span>{game[2]}</span>
                      </HomeSectionGames>
                    )
                )}
              {(item.name === "NETENT2" ||
                item.name === "AMATIC2" ||
                item.name === "NOVOMATIC2" ||
                item.name === "ARISTOCRAT2" ||
                item.name === "EGT2" ||
                item.name === "Merkur" ||
                item.name === "PlayNGo" ||
                item.name === "PragmaticPlay" ||
                item.name === "River" ||
                item.name === "Scientific" ||
                item.name === "Yggdrasil" ||
                item.name === "Zeppelin") &&
                ogsGameList
                  .filter((category) => category.provider === item.name)
                  .map(
                    (game, index) =>
                      index < 12 && (
                        <HomeSectionGames key={index} onClick={() => handleGamePlay(game, item.name, "ogs")}>
                          <div>
                            <img src={game.iconUrl} alt={item.name} width="100%" height="100%" style={{ borderRadius: "10px" }} />
                          </div>
                          <span>{game?.displayName}</span>
                        </HomeSectionGames>
                      )
                  )}
            </HomeSectionGameList>
          </MobileHomeSectionContainer>
        ))}
      </MobileHomeContainer>
    </React.Fragment>
  );
};

export default CategoryList;
