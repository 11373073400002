import React from 'react';

export const History: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-profile-history sm BoxButton_icon_T35Ci BoxButton_icon_T35Ci">
            <defs>
                <linearGradient x1="4.759%" y1="93.345%" x2="110.12%" y2="-2.774%" id="history_svg__a">
                    <stop stopColor="#0095FF" offset="0%"></stop>
                    <stop stopColor="#0854C3" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path d="M9.248 18.921c.161 0 .316.028.46.08v-.004a8.324 8.324 0 005.284.406 8.401 8.401 0 004.475-2.87 8.565 8.565 0 001.892-5.005 8.58 8.58 0 00-1.442-5.157 8.425 8.425 0 00-4.204-3.262 8.323 8.323 0 00-5.298-.073 8.418 8.418 0 00-4.29 3.145 8.57 8.57 0 00-1.563 4.384h1.399a.5.5 0 01.409.788l-2.567 3.65a.7.7 0 01-1.145 0l-2.566-3.65a.5.5 0 01.409-.788h1.447A11.23 11.23 0 014.006 4.64 11.029 11.029 0 019.627.52a10.903 10.903 0 016.941.096 11.038 11.038 0 015.508 4.274 11.242 11.242 0 011.89 6.756 11.22 11.22 0 01-2.48 6.558 11.007 11.007 0 01-5.862 3.759 10.905 10.905 0 01-6.859-.505 1.314 1.314 0 01-.858-1.225c0-.724.6-1.312 1.34-1.312z" fill="url(#history_svg__a)" transform="translate(0 1)"></path>
            <path d="M11.712 7.4a.99.99 0 011.98 0v5.608h-1.98V7.4z" fill="#FFF"></path>
            <path d="M16.144 14.64a.99.99 0 01-1.4 1.4l-3.032-3.032 1.4-1.4 3.032 3.033z" fill="#FFF"></path>
        </svg>
    );
};
