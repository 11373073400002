import React, { useEffect, useState } from "react";
import {
  Backdrop,
  BonusItem,
  CustomInput,
  FromContainer,
  LoginTabContainer,
  ModalAdditional,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  RegisterAdv,
  RegisterButton,
  RulesAcceptCheckBox,
  SocialBtnList,
  SocialLoginContainer,
  TabButton,
} from "./styled";
import { MdClose } from "react-icons/md";
import { IoMail } from "react-icons/io5";
import { FaComment } from "react-icons/fa";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { registerUser } from "../../API/auth";

import GoogleSvg from "../../assets/svg/social/google.svg";
import MailruSvg from "../../assets/svg/social/mailru.svg";
import YandexSvg from "../../assets/svg/social/yandex.svg";
import XSvg from "../../assets/svg/social/x.svg";
import TelegramSvg from "../../assets/svg/social/telegram.svg";
import BonusCasinoSvg from "../../assets/casino.png";
import CashBackSvg from "../../assets/cashback.svg";
import { Check } from "../../assets/svg/check";
import Spinner from "../Spinner";

interface RegisterProps {
  isOpen: boolean;
  handleClose: () => void;
  handleRegister: (provider: string) => void;
  handleLogin: () => void;
  userData: {
    email: string;
    password: string;
  };
  setUserData: (type: string, value: any) => void;
}

export const RegisterModal: React.FC<RegisterProps> = ({ isOpen, handleClose, handleRegister, handleLogin, userData, setUserData }) => {
  const dispatch = useTypedDispatch();
  const { loading } = useTypedSelector((state) => state.auth);
  const [visible, setVisible] = useState(true);
  const [tab, setTab] = useState(false);
  const [socialProvider, setSocialProvider] = useState("");
  const [agree, setAgress] = useState(false);

  const social_list = [
    {
      title: "google",
      url: GoogleSvg,
    },
    {
      title: "mailru",
      url: MailruSvg,
    },
    {
      title: "yandex",
      url: YandexSvg,
    },
    {
      title: "twitter",
      url: XSvg,
    },
    {
      title: "telegram",
      url: TelegramSvg,
    },
  ];

  const handleOk = () => {
    dispatch(registerUser(userData));
  };

  const login = () => {
    handleClose();
    handleLogin();
  };

  return isOpen ? (
    <React.Fragment>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer>
          <ModalHeader>
            <span>Registration</span>
            <button onClick={handleClose}>
              <MdClose size={15} color="#0006" />
            </button>
          </ModalHeader>
          <LoginTabContainer>
            <TabButton className={tab ? "tab-inactive" : "tab-active"} onClick={() => setTab(!tab)}>
              <FaComment size={20} color={tab ? "#99a2ad" : "#fff"} />
              <span>Social networks</span>
            </TabButton>
            <TabButton className={tab ? "tab-active" : "tab-inactive"} onClick={() => setTab(!tab)}>
              <IoMail size={20} color={tab ? "#fff" : "#99a2ad"} />
              <span>Quick</span>
            </TabButton>
          </LoginTabContainer>
          {!tab && (
            <SocialLoginContainer>
              <SocialBtnList>
                {social_list.map((item, key) => (
                  <li
                    key={key}
                    className={`${item.title} ${socialProvider === item.title ? "active" : "inactive"}`}
                    onClick={() => setSocialProvider(item.title)}
                    // flag={socialProvider === item.title ? true : false}
                  >
                    <img src={item.url} alt={item.title} />
                  </li>
                ))}
              </SocialBtnList>
            </SocialLoginContainer>
          )}
          <FromContainer>
            {tab && (
              <>
                {/* <PhoneInput
                                    placeholder="Enter phone number"
                                    value={value}
                                    onChange={handlePhone}
                                /> */}
                {/* <div>{formatPhoneNumberIntl(value)}</div> */}
                <CustomInput>
                  <input placeholder="E-mail" value={userData.email} onChange={(e) => setUserData("email", e.target.value)} />
                </CustomInput>
                <CustomInput>
                  <input
                    placeholder="Password"
                    type={visible ? "password" : "text"}
                    value={userData.password}
                    onChange={(e) => setUserData("password", e.target.value)}
                  />
                  {visible ? (
                    <AiOutlineEye onClick={() => setVisible(false)} />
                  ) : (
                    <AiOutlineEyeInvisible onClick={() => setVisible(true)} />
                  )}
                </CustomInput>
              </>
            )}
            <RulesAcceptCheckBox>
              <input type="checkbox" checked={agree} onClick={() => setAgress(!agree)} />
              <span>I agree with 1win website</span>
              <a>User Agreement</a>
            </RulesAcceptCheckBox>
            {/* <ForgotPasswordText>Forgot password?</ForgotPasswordText> */}
            {tab ? (
              loading ? (
                <RegisterButton>
                  <Spinner />
                </RegisterButton>
              ) : (
                <RegisterButton onClick={handleOk} disabled={!agree} flag={agree}>
                  Register
                </RegisterButton>
              )
            ) : (
              <RegisterButton onClick={() => handleRegister(socialProvider)} disabled={!agree} flag={agree}>
                Register
              </RegisterButton>
            )}
            <RegisterAdv>
              Already have an account?
              <span onClick={login}>Login</span>
            </RegisterAdv>
          </FromContainer>
        </ModalContainer>
        <ModalAdditional>
          <BonusItem>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={BonusCasinoSvg} width={18} height={18} />
              <span style={{ color: "#222735", paddingLeft: "10px" }}>500% on casino</span>
            </div>
            <Check />
          </BonusItem>
          <BonusItem>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={CashBackSvg} width={18} height={18} />
              <span style={{ color: "#222735", paddingLeft: "10px" }}>Cashback up to 30%</span>
            </div>
            <Check />
          </BonusItem>
        </ModalAdditional>
      </ModalWrapper>
    </React.Fragment>
  ) : (
    <></>
  );
};
