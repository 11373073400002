import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { ImageSlideContent, SlideBtns, SlideBtn } from "./styled";
import { GrNext, GrPrevious } from "react-icons/gr";
import poker_1win from "../../assets/image/slide/1winpoker.webp";
import i18_500 from "../../assets/image/slide/500_i18.webp";
import bonus from "../../assets/image/slide/bonus.webp";
import bonus_hover from "../../assets/image/slide/bonus_hover.webp";
import cash_back from "../../assets/image/slide/cash_back.png";
import free_spin from "../../assets/image/slide/free_spin.png";
import sport from "../../assets/image/slide/sport.png";

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const ImageSlider: React.FC = () => {
    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);
    const [_, setInit] = useState(false);

    const slide_img = [
        poker_1win,
        i18_500,
        bonus,
        bonus_hover,
        cash_back,
        free_spin,
        sport
    ]

    return (
        <React.Fragment>
            <Swiper
                loop={true}
                pagination={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Navigation, Pagination, Autoplay]}
                className="mySwiper"
                // navigation={{
                //     prevEl: navigationPrevRef.current,
                //     nextEl: navigationNextRef.current,
                // }}
                onInit={() => setInit(true)}
            >
                {
                    slide_img.map((item, index) => (
                        <SwiperSlide key={index}>
                            <ImageSlideContent imgurl={item}></ImageSlideContent>
                        </SwiperSlide>
                    ))
                }
                {/* <SlideBtns>
                    <SlideBtn ref={navigationPrevRef}><GrPrevious /></SlideBtn>
                    <SlideBtn ref={navigationNextRef}><GrNext /></SlideBtn>
                </SlideBtns> */}
            </Swiper>
        </React.Fragment>
    )
}

export default ImageSlider;