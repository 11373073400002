import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { ErrorType } from ".";

export const routes = {
  start: "/api/ogs/open",
  news: "/api/news/open",
};

type GameRequest = {
  game: string;
  sessionId: string;
  username: string;
  provider: string;
};

type NewGameRequet = {
  login: string;
  gameUuid: string;
  gameCode: string;
};

export type GameResponse = {
  url: string;
};
export const ogsGame = createAsyncThunk<
  GameResponse,
  GameRequest,
  {
    rejectValue: ErrorType;
  }
>("games/getaccount", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.start}`, data);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const newGame = createAsyncThunk<
  GameResponse,
  NewGameRequet,
  {
    rejectValue: ErrorType;
  }
>("games/news", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.news}`, data);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});
