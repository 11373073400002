import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../Providers/UserContext";
import { useNavigate, useLocation } from "react-router-dom";
import {
  BodyItemCount,
  BodyItemIcon,
  BodyItemName,
  BtnTextTitle,
  BtnTextValue,
  CasinoContainer,
  CasinoMain,
  CasinoNavigation,
  CasinoNavigationBody,
  CasinoNavigationBodyItem,
  CasinoNavigationBodyList,
  CasinoNavigationBodyTitle,
  CasinoNavigationSearch,
  CasinoWrapper,
  HotButton,
  HotPopularBtns,
  HotPopularButtonText,
  IframeBody,
  IframeHeader,
  IframeWrapper,
  // JackpotImageSliderContent,
  JackpotPanel,
  JackpotPanelContainer,
  JackpotPanelTitle,
  JackpotSlider,
  PopularButton,
} from "./styled";
// import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../../layout/Header";
import { FaSearch } from "react-icons/fa";
import { Heat } from "../../assets/svg/Heat";
import { Popular } from "../../assets/svg/Popular";
import ImageSlider from "../../components/ImageSlider";
import MobileNavFooter from "../../layout/MobileFooter/MobileNavFooter";
import MobileFooter from "../../layout/MobileFooter";
import { category_list, mobile_category_list } from "../../config/category";
import axios from "axios";
import CategoryList from "./categorylist";
import GameList from "./gamelist";
// import { BASE_ROUTE } from "../../config/api-routes";
import { smallScreens, smallWidthScreens } from "../../utils/intervals";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import ogsGameList from "../../config/gameList.json";
import newGameList from "../../config/newGame.json";
import { GameResponse, newGame, ogsGame } from "../../API/game";
import { FaExpand, FaCompress } from "react-icons/fa6";

import NetentSvg from "../../assets/svg/categories/netgame.svg";
import AmaticSvg from "../../assets/svg/categories/amatic.svg";
import NovomaticSvg from "../../assets/svg/categories/novomatic.svg";
import AinsworthSvg from "../../assets/svg/categories/agt.svg";
import QuickspinSvg from "../../assets/svg/categories/quickspin.svg";
import egtSvg from "../../assets/svg/categories/egt.svg";
import PlayngoSvg from "../../assets/svg/categories/playngo.svg";
import PlayTechSvg from "../../assets/svg/categories/playtech.svg";
import PragmaticSvg from "../../assets/svg/categories/pragmatic.svg";
import YggdrasilSvg from "../../assets/svg/categories/yggdrasil.svg";
import ZeplinSvg from "../../assets/svg/categories/zeplin.svg";
import RiverSvg from "../../assets/svg/categories/river.svg";
import SgSvg from "../../assets/svg/categories/sg.svg";
import AristocratSvg from "../../assets/svg/categories/Aristocrat.svg";
import { MdClose } from "react-icons/md";

const Casino: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();
  const {
    isMobile,
    // games,
    isOrder,
    setGames,
    netentGame,
    setNetentGame,
    playtechGame,
    setPlaytechGame,
    amaticGame,
    setAmaticGame,
    novomaticGame,
    setNovomaticGame,
    ainsworthGame,
    setAinsworthGame,
    quickspinGame,
    setQuickspinGame,
    aristocratGame,
    setAristocratGame,
    egtGame,
    setEgtGame,
    gameSession,
    setGameSession,
  } = useContext(UserContext);
  const { id, isLogin, email } = useTypedSelector((state) => state.auth);
  const { balance } = useTypedSelector((state) => state.account);
  const [categories, setCategories] = useState<Array<any>>([]);
  const [category, setCategory] = useState<any>({
    name: "",
    path: "",
  });
  const [isVisible, setVisible] = useState<Boolean>(false);
  const [isOpen, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [gameName, setGameName] = useState("");
  const [isExpand, setIsExpand] = useState(false);

  useEffect(() => {
    if (isOrder) {
      if (isMobile) {
        setCategories(mobile_category_list);
      } else {
        setCategories(category_list);
      }
      getData();
      // generateGameSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrder, isMobile]);

  useEffect(() => {
    if (location.pathname === "/casino") {
      setVisible(false);
      setCategory({
        name: "",
        path: "",
      });
    }

    if (location.pathname.includes("casino/play/")) {
      getGameData();
    } else {
      if (gameSession !== "") {
        closeGameSession();
      }
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getGameData = async () => {
    if (!isLogin) {
      navigate("/", { state: { modal: "login" } });
      return;
    }
    const state = location.state;
    let url = "";
    if (state.api === "news") {
      setGameName(state.gameItem.gameName);
      const data = {
        login: email,
        gameUuid: state.gameItem.gameUuid,
        gameCode: state.gameItem.gameCode,
      };

      await dispatch(newGame(data))
        .then((result) => {
          console.log("result: ", result);
          url = (result.payload as GameResponse).url;
        })
        .catch((err: any) => {
          console.log(err);
        });
      if (smallScreens.matches || smallWidthScreens.matches) {
        navigate("/play/mobile", { state: { url, gameName: state.gameItem.displayName } });
      } else {
        console.log("url: ", url);
        setUrl(url);
        setOpen(true);
      }
    } else if (state.api === "ogs") {
      console.log("ogs");
      setGameName(state.gameItem.displayName);
      let data: any = {};
      if (
        state.provider === "PlayNGo" ||
        state.provider === "NETENT2" ||
        state.provider === "PragmaticPlay" ||
        state.provider === "ARISTOCRAT2"
      ) {
        data = {
          username: email,
          sessionId: id,
          game: state.gameItem.gameId,
          provider: state.provider,
        };
      } else {
        data = {
          username: email,
          sessionId: id,
          game: state.gameItem.gameName,
          provider: state.provider,
        };
      }
      console.log(data);

      await dispatch(ogsGame(data))
        .then((result) => {
          console.log("result: ", result);
          url = (result.payload as GameResponse).url;
        })
        .catch((err: any) => {
          console.log(err);
        });
      if (smallScreens.matches || smallWidthScreens.matches) {
        navigate("/play/mobile", { state: { url, gameName: state.gameItem.displayName } });
      } else {
        console.log("url: ", url);
        setUrl(url);
        setOpen(true);
      }
    } else {
      console.log("rgs", balance);
      setGameName(state.gameItem[2]);
      let gameSessions = "";
      let user_id = id;
      const hash = "hash_" + Date.now();
      let close_url = "https://youslots.netlify.app/close.html";
      if (state.provider === "AMATIC" || state.provider === "Ainsworth") {
        close_url = "";
      }
      await axios
        .get(
          `https://apissl.b-cdn.net/api/open_sw_session?token=testagent21&secret_key=argentina&user_id=${user_id}&group_id=40994&server=https://slot-backend-c9f4948d8bc0.herokuapp.com/api/rgs/&opPassword=xc48Pjph&PlayerID=${user_id}&sessionID=${hash}&wallet_type=2`
        )
        .then((res) => {
          console.log(res.data);
          gameSessions = res.data.key;
          url = `https://egt.depot888.com/?key=${gameSessions}&language=en&exit_url=${close_url}&mobile=${isMobile}&game=${location.state.gameItem[1]}`;
          if (smallScreens.matches || smallWidthScreens.matches) {
            // window.open(url, "_self");
            navigate("/play/mobile", { state: { url, gameName: state.gameItem[2] } });
          } else {
            setUrl(url);
            setOpen(true);
          }
        });
    }
  };

  const closeGameSession = async () => {
    let user_id = id;
    const hash = "hash_" + Date.now();
    await axios
      .get(
        `https://apissl.b-cdn.net/api/withdraw_balance?token=testagent21&secret_key=argentina&user_id=${user_id}&group_id=40994&hash=${hash}`
      )
      .then((res) => {
        // setUserBalance(res.data.withdraw_amount);
        axios
          .get(`https://apissl.b-cdn.net/api/close_session?token=testagent21&secret_key=argentina&user_id=${user_id}&group_id=40994`)
          .then((res) => {
            setGameSession("");
          })
          .catch((err) => console.log("close error", err));
      })
      .catch((err) => console.log("withdraw error", err));
  };

  const getData = async () => {
    let data: any[] = [];
    let list = isMobile ? mobile_category_list : category_list;
    for (let idx = 0; idx < list.length; idx++) {
      const element = list[idx];
      if (
        element.name === "NETENT2" ||
        element.name === "AMATIC2" ||
        element.name === "NOVOMATIC2" ||
        element.name === "ARISTOCRAT2" ||
        element.name === "EGT2" ||
        element.name === "PlayNGo" ||
        element.name === "PragmaticPlay" ||
        element.name === "River" ||
        element.name === "Scientific" ||
        element.name === "Yggdrasil" ||
        element.name === "Zeppelin"
      ) {
        // return;
      } else {
        await axios
          .get(`https://report.b-cdn.net/reports/get_games?software=${element.path}`)
          // eslint-disable-next-line no-loop-func
          .then((res) => {
            data = [...data, ...res.data.games];
            if (element.name === "NETENT") {
              setNetentGame(res.data.games);
            } else if (element.name === "PLAYTECH") {
              setPlaytechGame(res.data.games);
            } else if (element.name === "AMATIC") {
              setAmaticGame(res.data.games);
            } else if (element.name === "NOVOMATIC") {
              setNovomaticGame(res.data.games);
            } else if (element.name === "Ainsworth") {
              setAinsworthGame(res.data.games);
            } else if (element.name === "Quickspin") {
              setQuickspinGame(res.data.games);
            } else if (element.name === "Aristocrat") {
              setAristocratGame(res.data.games);
            } else if (element.name === "EGT") {
              setEgtGame(res.data.games);
            }
          })
          .catch((err) => console.log(err));
      }
    }
    setGames(data);
  };

  const handleCategory = (item: any) => {
    setCategory(item);
    setVisible(true);
    setOpen(false);
    navigate(`/casino/${item.path}`);
  };

  return (
    <React.Fragment>
      <CasinoWrapper>
        <Header />
        <CasinoContainer>
          <CasinoNavigation>
            <CasinoNavigationSearch>
              <FaSearch color="#7388b6" />
              <input placeholder="Search..." />
            </CasinoNavigationSearch>
            <CasinoNavigationBody>
              <CasinoNavigationBodyTitle>Categories</CasinoNavigationBodyTitle>
              <HotPopularBtns>
                <HotButton>
                  <Heat />
                  <HotPopularButtonText>
                    <BtnTextTitle>Heated</BtnTextTitle>
                    <BtnTextValue>20</BtnTextValue>
                  </HotPopularButtonText>
                </HotButton>
                <PopularButton>
                  <Popular />
                  <HotPopularButtonText>
                    <BtnTextTitle>Popular</BtnTextTitle>
                    <BtnTextValue>218</BtnTextValue>
                  </HotPopularButtonText>
                </PopularButton>
              </HotPopularBtns>
              <CasinoNavigationBodyList>
                {categories.map((item, key) => (
                  <CasinoNavigationBodyItem
                    key={key}
                    onClick={() => handleCategory(item)}
                    flag={item.name === category.name ? true : false}
                  >
                    <BodyItemIcon>
                      {item.name === "NETENT" && <img src={NetentSvg} alt="netent" width={25} height={25} />}
                      {item.name === "PLAYTECH" && <img src={PlayTechSvg} alt="playtech" width={25} height={25} />}
                      {item.name === "AMATIC" && <img src={AmaticSvg} alt="amatic" width={25} height={25} />}
                      {item.name === "NOVOMATIC" && <img src={NovomaticSvg} alt="novomatic" width={25} height={25} />}
                      {item.name === "Ainsworth" && <img src={AinsworthSvg} alt="ainsworth" width={25} height={25} />}
                      {item.name === "Quickspin" && <img src={QuickspinSvg} alt="quickspin" width={25} height={25} />}
                      {item.name === "Aristocrat" && <img src={AristocratSvg} alt="aristocrat" width={25} height={25} />}
                      {item.name === "EGT" && <img src={egtSvg} alt="egt" width={25} height={25} />}
                      {item.name === "NETENT2" && <img src={NetentSvg} alt="netent2" width={25} height={25} />}
                      {item.name === "AMATIC2" && <img src={AmaticSvg} alt="amatic2" width={25} height={25} />}
                      {item.name === "NOVOMATIC2" && <img src={NovomaticSvg} alt="novomatic2" width={25} height={25} />}
                      {item.name === "ARISTOCRAT2" && <img src={AristocratSvg} alt="aristocrat2" width={25} height={25} />}
                      {item.name === "EGT2" && <img src={egtSvg} alt="egt2" width={25} height={25} />}
                      {item.name === "PlayNGo" && <img src={PlayngoSvg} alt="playngo" width={25} height={25} />}
                      {item.name === "PragmaticPlay" && <img src={PragmaticSvg} alt="pragmatic" width={25} height={25} />}
                      {item.name === "River" && <img src={RiverSvg} alt="river" width={25} height={25} />}
                      {item.name === "Scientific" && <img src={SgSvg} alt="scientific" width={25} height={25} />}
                      {item.name === "Yggdrasil" && <img src={YggdrasilSvg} alt="yggdrasil" width={25} height={25} />}
                      {item.name === "Zeppelin" && <img src={ZeplinSvg} alt="zeppelin" width={25} height={25} />}
                    </BodyItemIcon>
                    <BodyItemName>{item.name}</BodyItemName>
                    <BodyItemCount>
                      {item.name === "New Games" && newGameList.length}
                      {item.name === "NETENT" && netentGame.length}
                      {item.name === "PLAYTECH" && playtechGame.length}
                      {item.name === "AMATIC" && amaticGame.length}
                      {item.name === "NOVOMATIC" && novomaticGame.length}
                      {item.name === "Ainsworth" && ainsworthGame.length}
                      {item.name === "Quickspin" && quickspinGame.length}
                      {item.name === "Aristocrat" && aristocratGame.length}
                      {item.name === "EGT" && egtGame.length}
                      {item.name === "NETENT2" && ogsGameList.filter((item) => item.provider === "NETENT2").length}
                      {item.name === "AMATIC2" && ogsGameList.filter((item) => item.provider === "AMATIC2").length}
                      {item.name === "NOVOMATIC2" && ogsGameList.filter((item) => item.provider === "NOVOMATIC2").length}
                      {item.name === "ARISTOCRAT2" && ogsGameList.filter((item) => item.provider === "ARISTOCRAT2").length}
                      {item.name === "EGT2" && ogsGameList.filter((item) => item.provider === "EGT2").length}
                      {item.name === "PlayNGo" && ogsGameList.filter((item) => item.provider === "PlayNGo").length}
                      {item.name === "PragmaticPlay" && ogsGameList.filter((item) => item.provider === "PragmaticPlay").length}
                      {item.name === "River" && ogsGameList.filter((item) => item.provider === "River").length}
                      {item.name === "Scientific" && ogsGameList.filter((item) => item.provider === "Scientific").length}
                      {item.name === "Yggdrasil" && ogsGameList.filter((item) => item.provider === "Yggdrasil").length}
                      {item.name === "Zeppelin" && ogsGameList.filter((item) => item.provider === "Zeppelin").length}
                    </BodyItemCount>
                  </CasinoNavigationBodyItem>
                ))}
              </CasinoNavigationBodyList>
            </CasinoNavigationBody>
          </CasinoNavigation>
          {isOpen ? (
            <IframeWrapper
              style={isExpand ? { position: "fixed", top: 0, left: 0, zIndex: 9999, height: "100vh" } : { position: "relative" }}
            >
              <IframeHeader>
                <p>{gameName}</p>
                <MdClose size={30} style={{ cursor: "pointer" }} onClick={() => navigate("/casino")} />
              </IframeHeader>
              <IframeBody>
                <div
                  style={{
                    position: "absolute",
                    margin: "30px",
                    backgroundColor: "#2b3843",
                    padding: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => setIsExpand(!isExpand)}
                >
                  {!isExpand ? <FaExpand /> : <FaCompress />}
                </div>
                <iframe
                  style={{ width: "100%", height: "100%" }}
                  scrolling="no"
                  frameBorder="no"
                  title="gameIfram"
                  id="gameIFrame"
                  src={url}
                />
              </IframeBody>
            </IframeWrapper>
          ) : (
            <CasinoMain>
              <JackpotPanelContainer>
                <JackpotPanel>
                  <JackpotPanelTitle>
                    <span>Jackpot</span>
                  </JackpotPanelTitle>
                </JackpotPanel>
                <JackpotSlider>
                  <ImageSlider />
                </JackpotSlider>
              </JackpotPanelContainer>
              {isVisible ? (
                <GameList category={category} />
              ) : (
                <CategoryList categories={categories} category={category} setCategory={handleCategory} />
              )}
            </CasinoMain>
          )}
        </CasinoContainer>
        <MobileFooter />
        <MobileNavFooter />
      </CasinoWrapper>
    </React.Fragment>
  );
};

export default Casino;
