import React, { useState } from "react";
import {
  FooterLogoContainer,
  FooterLogoSeparator,
  FooterMobileAppContainer,
  FooterMobileAppContent,
  FooterMobileAppText,
  FooterSeparator,
} from "../Footer/styled";
import LogoImg from "../../assets/logo.svg";
import {
  ApplicationSectionContainer,
  FooterNavigationSectionBtn,
  FooterNavigationSectionContainer,
  FooterNavigationSectionContent,
  FooterNavigationSectionItems,
  FooterNavigationSectionTitle,
  MobileFacebookBtn,
  MobileFooterContainer,
  MobileFooterSeparator,
  MobileInstagramBtn,
  MobileSocialSecitonBtns,
  MobileSocialSectionContainer,
  MobileSocialSectionTitle,
  MobileSportPromotionSectionContainer,
  MobileSupportSectionBtn,
  MobileSupportSectionBtns,
  MobileSupportSectionContactBtn,
  MobileSupportSectionContactKip,
  MobileSupportSectionContactNames,
  MobileSupportSectionContactValues,
  MobileSupportSectionContainer,
  MobileSupportSectionContent,
  MobileSupportSectionHeader,
  MobileSupportSectionIcons,
  MobileSupportSectionMain,
  MobileSupportSectionSubTitle,
  MobileSupportSectionTitle,
  MobileTelegramBtn,
  MobileXBtn,
} from "./styled";
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdKeyboardArrowRight } from "react-icons/md";
import { FaApple } from "react-icons/fa";
import { Android } from "../../assets/svg/android";
import { MdPhone } from "react-icons/md";
import { BiLogoTelegram, BiLogoInstagram, BiLogoFacebookCircle } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import uefaImg from "../../assets/image/footer/promotion/uefa.svg";
import ufcImg from "../../assets/image/footer/promotion/ufc.svg";
import wtaImg from "../../assets/image/footer/promotion/wta.svg";
import fibaImg from "../../assets/image/footer/promotion/fiba.svg";
import nhlImg from "../../assets/image/footer/promotion/nhl.svg";
import atpImg from "../../assets/image/footer/promotion/atp.svg";

const MobileFooter: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [contactVisible, setContactVisible] = useState(false);
  const sport_promotions = [uefaImg, ufcImg, wtaImg, fibaImg, nhlImg, atpImg];
  return (
    <React.Fragment>
      <MobileFooterContainer>
        <FooterLogoContainer>
          <img src={LogoImg} alt="logo" width="48px" />
          <FooterLogoSeparator />
        </FooterLogoContainer>
        <FooterNavigationSectionContainer>
          <FooterNavigationSectionContent>
            <FooterNavigationSectionTitle>Information</FooterNavigationSectionTitle>
            <FooterNavigationSectionItems>
              <div>
                <span>Rules</span>
                <span>Promotions and bonuses</span>
              </div>
              <div>
                <span>Affiliate Program</span>
              </div>
            </FooterNavigationSectionItems>
            {visible && (
              <>
                <FooterNavigationSectionTitle>Categories</FooterNavigationSectionTitle>
                <FooterNavigationSectionItems>
                  <div>
                    <span>Live</span>
                    <span>Sports</span>
                    <span>Sports</span>
                  </div>
                  <div>
                    <span>Live-games</span>
                    <span>Poker</span>
                    <span>Casino</span>
                  </div>
                </FooterNavigationSectionItems>
              </>
            )}
          </FooterNavigationSectionContent>
          <FooterNavigationSectionBtn onClick={() => setVisible(!visible)}>
            {visible ? <MdKeyboardArrowUp color="#94a6cd80" /> : <MdKeyboardArrowDown color="#94a6cd80" />}
          </FooterNavigationSectionBtn>
        </FooterNavigationSectionContainer>
        <MobileFooterSeparator />
        <ApplicationSectionContainer>
          <FooterMobileAppContainer width="100%">
            <FooterMobileAppContent>
              <FaApple size={25} />
              <FooterMobileAppText>
                <span>Application</span>
                <p>for iOS</p>
              </FooterMobileAppText>
            </FooterMobileAppContent>
            <MdKeyboardArrowRight color="gray" />
          </FooterMobileAppContainer>
          <FooterMobileAppContainer width="100%">
            <FooterMobileAppContent>
              <Android />
              <FooterMobileAppText>
                <span>Application</span>
                <p>for Android</p>
              </FooterMobileAppText>
            </FooterMobileAppContent>
            <MdKeyboardArrowRight color="gray" />
          </FooterMobileAppContainer>
        </ApplicationSectionContainer>
        <MobileSupportSectionContainer>
          <MobileSupportSectionMain>
            <MobileSupportSectionHeader>
              <MobileSupportSectionTitle>24/7 support</MobileSupportSectionTitle>
              <MobileSupportSectionSubTitle>Contact us if you still have any questions</MobileSupportSectionSubTitle>
            </MobileSupportSectionHeader>
            <MobileSupportSectionContent>
              <MobileSupportSectionBtns>
                <MobileSupportSectionIcons>
                  <MdPhone />
                </MobileSupportSectionIcons>
              </MobileSupportSectionBtns>
              <MobileSupportSectionContactBtn onClick={() => setContactVisible(!contactVisible)}>
                {contactVisible ? <MdKeyboardArrowUp color="white" /> : <MdKeyboardArrowDown color="white" />}
              </MobileSupportSectionContactBtn>
            </MobileSupportSectionContent>
          </MobileSupportSectionMain>
          {contactVisible && (
            <MobileSupportSectionContactKip>
              <MobileSupportSectionContactNames>
                <span>Technical support</span>
                <span>Security service</span>
                <span>Commercial offers</span>
                <span>Affiliate program</span>
              </MobileSupportSectionContactNames>
              <MobileSupportSectionContactValues>
                <span>support@1win.xyz</span>
                <span>security@1win.xyz</span>
                <span>business@1win.xyz</span>
                <span>partner@1win.xyz</span>
              </MobileSupportSectionContactValues>
            </MobileSupportSectionContactKip>
          )}
        </MobileSupportSectionContainer>
        <MobileSportPromotionSectionContainer>
          {sport_promotions.map((item, index) => (
            <img src={item} key={index} alt="sport" height="15px" />
          ))}
        </MobileSportPromotionSectionContainer>
        <MobileFooterSeparator />
        <MobileSocialSectionContainer>
          <MobileSocialSectionTitle>Social networks</MobileSocialSectionTitle>
          <MobileSocialSecitonBtns>
            <MobileTelegramBtn>
              <BiLogoTelegram size={20} color="white" />
              <span>Telegram</span>
            </MobileTelegramBtn>
            <MobileInstagramBtn>
              <BiLogoInstagram size={20} color="white" />
              <span>Instagram</span>
            </MobileInstagramBtn>
            <MobileFacebookBtn>
              <BiLogoFacebookCircle size={20} color="white" />
              <span>Facebook</span>
            </MobileFacebookBtn>
            <MobileXBtn>
              <BsTwitterX size={20} />
              <span>X</span>
            </MobileXBtn>
          </MobileSocialSecitonBtns>
        </MobileSocialSectionContainer>
      </MobileFooterContainer>
    </React.Fragment>
  );
};

export default MobileFooter;
