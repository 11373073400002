import styled from "styled-components";
import BgSupport from '../../assets/image/bg-support.png';

export const RulesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 1000px;
    margin: 15px auto;
`

export const RulesNavigation = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 270px;
    min-width: 270px;
`

export const RulesBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const RuleNavigationSupportContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    background-image: url(${BgSupport});
    background-size: cover;
    border-radius: 12px;
    text-align: left;
    position: relative;
`

export const SupportContainerTitle = styled.text`
    font-size: 20px;
    font-weight: 700;
`

export const SupportContainerSubTitle = styled.text`
    font-size: 12px;
    max-width: 164px;
    /* div {
        font-size: 12px;
        max-width: 164px;
    } */
`

export const SupportContainerBtns = styled.div`
    display: flex;
    gap: 10px;
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4e5d864d;
        padding: 10px;
        border: none;
        border-radius: 100px;
        cursor: pointer;
    }
`

export const SupportImage = styled.img`
    position: absolute;
    width: 108px;
    height: 88px;
    bottom: 3px;
    right: 0;
`

export const NavigationBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 12px;
    position: relative;
    height: calc(100vh - 330px);
    background-image: linear-gradient(to bottom,#1e283f,rgba(20,27,46,.6));
    padding: 20px 10px;
    gap: 20px;
`

export const RulesNavigationList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: left;
`

export const RulesTitle = styled.text`
    font-size: 11px;
    line-height: 13.13px;
    text-transform: uppercase;
    letter-spacing: .13px;
    color: #7388b64d;
    font-weight: 700;
`

export const RulesItems = styled.div`
    display: flex;
    flex-direction: column;
    div {
        align-items: center;
        display: flex;
        padding: 15px 10px;
        position: relative;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.32px;
        letter-spacing: .28px;
        border-radius: 12px;
        cursor: pointer;
        &:not(:last-child)::before {
            background-color: #262e4880;
            bottom: 0;
            content: "";
            height: 1px;
            left: 0;
            right: 0;
            position: absolute;
        }
    }
`

export const RulesBodyTitle = styled.div`
    display: flex;
    font-weight: 700;
    font-size: 27px;
    line-height: 32px;
`

export const RuleBodyText = styled.div`
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .03%;
    ol {
        padding: 0 15px;
        margin: 0;
    }
    p {
        margin: 0;
    }
`