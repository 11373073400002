import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BonusLink,
  BonusLinkCircle,
  FreeMoney,
  HeaderButton,
  HeaderLineLeft,
  HeaderLineRight,
  HeaderTopContainer,
  LevelItem,
  ApplicationBtns,
} from "./styled";
import { TbLock } from "react-icons/tb";
import { PiDeviceMobileSpeaker } from "react-icons/pi";
import { Divider } from "@react-md/divider";
import { BiRightArrowAlt } from "react-icons/bi";
import { PromotionIcon } from "../../assets/svg/promotion";
import { FcAndroidOs } from "react-icons/fc";
import { FaApple } from "react-icons/fa";
// import { Tooltip } from 'react-tooltip';

import freeMoney from "../../assets/image/free-money-link-image.1ada0c9e1-120.png";
import presentLight from "../../assets/image/present-with-light.bd57fb068-151.png";

const HeaderTop: React.FC = () => {
  const navigate = useNavigate();

  return (
    <HeaderTopContainer>
      <HeaderLineLeft>
        <LevelItem>
          <HeaderButton>
            <TbLock color="white" size={15} />
          </HeaderButton>
          <HeaderButton>
            <PiDeviceMobileSpeaker color="white" size={15} />
          </HeaderButton>
        </LevelItem>
        <Divider />
        <FreeMoney onClick={() => navigate("/free-money")}>
          <img src={freeMoney} alt="free-money" />
          <span>Free Money!</span>
          <BiRightArrowAlt color="#0000004d" size={15} />
        </FreeMoney>
      </HeaderLineLeft>
      <HeaderLineRight>
        <BonusLink onClick={() => navigate("/bonuses")}>
          <BonusLinkCircle>
            <PromotionIcon width="18px" height="18px" />
          </BonusLinkCircle>
          <span>Promotions and bonuses</span>
          <img src={presentLight} alt="bonus-image" />
        </BonusLink>
        <Divider />
        <ApplicationBtns id="my-tooltip">
          <FcAndroidOs size={15} />
          <FaApple size={15} />
        </ApplicationBtns>
        {/* <Tooltip anchorSelect="#my-tooltip" place="bottom" style={{ backgroundColor: "white", color: "black", opacity: 1 }}>
                    hello world
                </Tooltip> */}
      </HeaderLineRight>
    </HeaderTopContainer>
  );
};

export default HeaderTop;
