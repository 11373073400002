import axios from "axios";
import { setLogout } from "../store/slices/auth";
import store from "../store";

export type ErrorType = {
  message: "Wrong username or password!";
};

export const urlApi = {
  prod: process.env.REACT_APP_BACKEND_DOMAIN,
  // prod: 'http://localhost:5000'
};

const api = axios.create({
  baseURL: urlApi.prod,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
