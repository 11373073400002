import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import { AiOutlineLeft } from "react-icons/ai";

import Header from "../../layout/Header";
import { ChangePasswordButton, Flex, Input, SaveButton } from "../../components/Modals/styled";
import { useNavigate } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { updateUser } from "../../API/account";

const Setting: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { id } = useTypedSelector((state) => state.auth);
  const { email, name, birthday } = useTypedSelector((state) => state.account);
  const [userinfo, setUserinfo] = useState({
    email: "",
    name: "",
    birthday: "",
  });
  const [changePasswordFlag, setChangePasswordFlag] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  useEffect(() => {
    if (name || email || birthday) {
      setUserinfo({
        email,
        name,
        birthday,
      });
    }
  }, [name, email, birthday]);

  const handleSave = () => {
    if (changePasswordFlag) {
      if (!currentPassword) {
        toast.warn("Input current password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      if (newPassword.length < 4) {
        toast.warn("Password must be at least 4 letters", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      if (newPassword !== repeatPassword) {
        toast.warn("Password different", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      const data = {
        id,
        name: userinfo.name,
        birthday: userinfo.birthday,
        currentPassword,
        newPassword,
      };
      dispatch(updateUser(data));
    } else {
      const data = {
        id,
        name: userinfo.name,
        birthday: userinfo.birthday,
      };
      dispatch(updateUser(data));
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Flex direction="column" style={{ padding: "15px", textAlign: "left" }}>
        <Flex
          direction="row"
          alignItems="center"
          style={{ gap: "6px", margin: "5px 0", cursor: "pointer" }}
          onClick={() => navigate("/profile")}
        >
          <AiOutlineLeft style={{ width: "13px", height: "13px" }} />
          <div style={{ fontSize: "13px" }}>Profile</div>
        </Flex>
        <div style={{ fontSize: "24px", fontWeight: 700, margin: "15px 0" }}>Edit profile</div>
        <Flex direction="column" style={{ gap: "8px" }}>
          <div style={{ backgroundColor: "#121829", padding: "0 15px", borderRadius: "12px" }}>
            <Input
              type="text"
              placeholder="Name"
              value={userinfo.name}
              onChange={(e) => setUserinfo({ ...userinfo, name: e.target.value })}
              style={{ width: "100%", height: "36px", color: "white" }}
            />
          </div>
          <div style={{ backgroundColor: "#121829", padding: "0 15px", borderRadius: "12px" }}>
            <Input
              type="date"
              placeholder="Birthday"
              value={userinfo.birthday}
              onChange={(e) => setUserinfo({ ...userinfo, birthday: e.target.value })}
              style={{ width: "100%", height: "36px", color: "white" }}
            />
          </div>
          <div style={{ backgroundColor: "#121829", padding: "0 15px", borderRadius: "12px" }}>
            <Input
              type="email"
              readOnly
              placeholder="Email"
              value={userinfo.email}
              onChange={(e) => setUserinfo({ ...userinfo, email: e.target.value })}
              style={{ width: "100%", height: "36px", color: "white" }}
            />
          </div>
          <ChangePasswordButton onClick={() => setChangePasswordFlag(!changePasswordFlag)}>
            {changePasswordFlag ? "Cancel" : "Change password"}
          </ChangePasswordButton>
          {changePasswordFlag && (
            <>
              <div style={{ backgroundColor: "#121829", padding: "0 15px", borderRadius: "12px" }}>
                <Input
                  type="password"
                  placeholder="Current password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  style={{ width: "100%", height: "36px", color: "white" }}
                />
              </div>
              <div style={{ backgroundColor: "#121829", padding: "0 15px", borderRadius: "12px" }}>
                <Input
                  type="password"
                  placeholder="New password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={{ width: "100%", height: "36px", color: "white" }}
                />
              </div>
              <div style={{ backgroundColor: "#121829", padding: "0 15px", borderRadius: "12px" }}>
                <Input
                  type="password"
                  placeholder="Repeat new password"
                  value={repeatPassword}
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  style={{ width: "100%", height: "36px", color: "white" }}
                />
              </div>
            </>
          )}
        </Flex>
        <SaveButton onClick={handleSave}>Save</SaveButton>
      </Flex>
    </React.Fragment>
  );
};

export default Setting;
