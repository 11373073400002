import React from 'react';

interface Props {
  width: string;
  height: string
}

export const PromotionIcon: React.FC<Props> = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-present-bonus sm BonusLink_circle-icon BonusLink_circle-icon">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.462 1.662c-.035-.503.341-.916.909-.887C5.9.8 6.705 1.232 7.462 2.62H7.146c-.47.026-1.173-.006-1.754-.177-.611-.18-.908-.449-.93-.782zm3.522.37C7.188.686 6.257.072 5.408.03 4.402-.02 3.652.758 3.719 1.714c.059.842.805 1.252 1.464 1.445.684.2 1.472.234 1.994.207H8.798c.528.027 1.327-.006 2.02-.207.664-.192 1.421-.6 1.481-1.445.068-.961-.695-1.733-1.707-1.684-.856.043-1.8.654-2.608 2.002zm3.572-.37c.035-.497-.343-.916-.927-.887-.541.026-1.358.46-2.125 1.846H8.829c.478.026 1.192-.006 1.782-.177.623-.18.922-.452.946-.782z" fill="#E8B2FF"></path>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.322 4.178A2.484 2.484 0 001.84 6.662v5.747a2.484 2.484 0 002.483 2.483h7.355a2.484 2.484 0 002.483-2.483V6.662a2.484 2.484 0 00-2.483-2.484H4.322zm5.328 6.137l.864-.875a.573.573 0 00.137-.58.542.542 0 00-.437-.382L9.02 8.297 8.486 7.17a.538.538 0 00-.485-.314H8a.54.54 0 00-.486.314L6.98 8.297l-1.192.18a.546.546 0 00-.44.383.576.576 0 00.138.58l.864.875-.204 1.238a.572.572 0 00.216.552.527.527 0 00.318.109.523.523 0 00.251-.067L8 11.563l1.066.584a.517.517 0 00.571-.042.569.569 0 00.217-.552l-.204-1.238z" fill="#fff"></path>
    </svg>
  );
};
