import React from 'react';

export const Detail: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon icon-profile-detalisation sm BoxButton_icon_T35Ci BoxButton_icon_T35Ci">
            <defs>
                <linearGradient x1="0%" y1="100%" y2="0%" id="detalisation_svg__a">
                    <stop stopColor="#0095FF" offset="0%"></stop>
                    <stop stopColor="#0854C3" offset="100%"></stop>
                </linearGradient>
            </defs>
            <path d="M7 1.75h10c2.9 0 5.25 2.35 5.25 5.25v10c0 2.9-2.35 5.25-5.25 5.25H7A5.25 5.25 0 011.75 17V7C1.75 4.1 4.1 1.75 7 1.75z" stroke="url(#detalisation_svg__a)" strokeWidth="2.5" fill="none"></path>
            <path d="M6.5 15.5a1 1 0 110 2 1 1 0 010-2zm3.5 0h7.5a1 1 0 010 2H10a1 1 0 010-2zm-3.5-4a1 1 0 110 2 1 1 0 010-2zm3.5 0h7.5a1 1 0 010 2H10a1 1 0 010-2zm-3.25-5a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm3.5 0h2.5a1.25 1.25 0 010 2.5h-2.5a1.25 1.25 0 110-2.5z" fill="#FFF"></path>
        </svg>
    );
};
